@charset "UTF-8";
/* You can add global styles to this file, and also import other style files */
@import url("https://cdn.medifit.si/fonts/lato/v16/");
@import url("https://cdn.medifit.si/fonts/sourcesanspro/v13/");
/* based on angular-toastr css https://github.com/Foxandxss/angular-toastr/blob/cb508fe6801d6b288d3afc525bb40fee1b101650/dist/angular-toastr.css */
/* position */
.toast-center-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.toast-top-center {
  top: 0;
  right: 0;
  width: 100%;
}

.toast-bottom-center {
  bottom: 0;
  right: 0;
  width: 100%;
}

.toast-top-full-width {
  top: 0;
  right: 0;
  width: 100%;
}

.toast-bottom-full-width {
  bottom: 0;
  right: 0;
  width: 100%;
}

.toast-top-left {
  top: 12px;
  left: 12px;
}

.toast-top-right {
  top: 12px;
  right: 12px;
}

.toast-bottom-right {
  right: 12px;
  bottom: 12px;
}

.toast-bottom-left {
  bottom: 12px;
  left: 12px;
}

/* toast styles */
.toast-title {
  font-weight: bold;
}

.toast-message {
  word-wrap: break-word;
}

.toast-message a,
.toast-message label {
  color: #FFFFFF;
}

.toast-message a:hover {
  color: #CCCCCC;
  text-decoration: none;
}

.toast-close-button {
  position: relative;
  right: -0.3em;
  top: -0.3em;
  float: right;
  font-size: 20px;
  font-weight: bold;
  color: #FFFFFF;
  text-shadow: 0 1px 0 #ffffff;
  /* opacity: 0.8; */
}

.toast-close-button:hover,
.toast-close-button:focus {
  color: #000000;
  text-decoration: none;
  cursor: pointer;
  opacity: 0.4;
}

/*Additional properties for button version
 iOS requires the button element instead of an anchor tag.
 If you want the anchor version, it requires `href="#"`.*/
button.toast-close-button {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
}

.toast-container {
  pointer-events: none;
  position: fixed;
  z-index: 999999;
}

.toast-container * {
  box-sizing: border-box;
}

.toast-container .ngx-toastr {
  position: relative;
  overflow: hidden;
  margin: 0 0 6px;
  padding: 15px 15px 15px 50px;
  width: 300px;
  border-radius: 3px 3px 3px 3px;
  background-position: 15px center;
  background-repeat: no-repeat;
  background-size: 24px;
  box-shadow: 0 0 12px #999999;
  color: #FFFFFF;
}

.toast-container .ngx-toastr:hover {
  box-shadow: 0 0 12px #000000;
  opacity: 1;
  cursor: pointer;
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/info-circle.svg */
.toast-info {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='512' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z'/%3E%3C/svg%3E");
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/times-circle.svg */
.toast-error {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='512' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z'/%3E%3C/svg%3E");
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/check.svg */
.toast-success {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='512' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z'/%3E%3C/svg%3E");
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/exclamation-triangle.svg */
.toast-warning {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 576 512' width='576' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M569.517 440.013C587.975 472.007 564.806 512 527.94 512H48.054c-36.937 0-59.999-40.055-41.577-71.987L246.423 23.985c18.467-32.009 64.72-31.951 83.154 0l239.94 416.028zM288 354c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z'/%3E%3C/svg%3E");
}

.toast-container.toast-top-center .ngx-toastr,
.toast-container.toast-bottom-center .ngx-toastr {
  width: 300px;
  margin-left: auto;
  margin-right: auto;
}

.toast-container.toast-top-full-width .ngx-toastr,
.toast-container.toast-bottom-full-width .ngx-toastr {
  width: 96%;
  margin-left: auto;
  margin-right: auto;
}

.ngx-toastr {
  background-color: #030303;
  pointer-events: auto;
}

.toast-success {
  background-color: #51A351;
}

.toast-error {
  background-color: #BD362F;
}

.toast-info {
  background-color: #2F96B4;
}

.toast-warning {
  background-color: #F89406;
}

.toast-progress {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 4px;
  background-color: #000000;
  opacity: 0.4;
}

/* Responsive Design */
@media all and (max-width: 240px) {
  .toast-container .ngx-toastr.div {
    padding: 8px 8px 8px 50px;
    width: 11em;
  }

  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}
@media all and (min-width: 241px) and (max-width: 480px) {
  .toast-container .ngx-toastr.div {
    padding: 8px 8px 8px 50px;
    width: 18em;
  }

  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}
@media all and (min-width: 481px) and (max-width: 768px) {
  .toast-container .ngx-toastr.div {
    padding: 15px 15px 15px 50px;
    width: 25em;
  }
}
/*!
 * Bootstrap v4.4.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #00234b;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --gray-custom: #f2f3f7;
  --tertiary: #3b4752;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: "Source Sans Pro", sans-serif;
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #00234b;
  text-decoration: none;
  background-color: transparent;
}
a:hover {
  color: black;
  text-decoration: underline;
}

a:not([href]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

select {
  word-wrap: normal;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type=radio],
input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}

input[type=date],
input[type=time],
input[type=datetime-local],
input[type=month] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1, .h1 {
  font-size: 2.5rem;
}

h2, .h2 {
  font-size: 2rem;
}

h3, .h3 {
  font-size: 1.75rem;
}

h4, .h4 {
  font-size: 1.5rem;
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
  font-size: 80%;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #6c757d;
}

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
}
kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}

pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

.container-fluid, .container-xl, .container-lg, .container-md, .container-sm {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.col-xl,
.col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg,
.col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md,
.col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm,
.col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col,
.col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%;
}

.col-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.col-5 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%;
}

.col-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%;
}

.col-11 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.3333333333%;
}

.offset-2 {
  margin-left: 16.6666666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.3333333333%;
}

.offset-5 {
  margin-left: 41.6666666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.3333333333%;
}

.offset-8 {
  margin-left: 66.6666666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.3333333333%;
}

.offset-11 {
  margin-left: 91.6666666667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-sm-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-sm-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-sm-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-sm-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-sm-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-sm-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-sm-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-sm-first {
    order: -1;
  }

  .order-sm-last {
    order: 13;
  }

  .order-sm-0 {
    order: 0;
  }

  .order-sm-1 {
    order: 1;
  }

  .order-sm-2 {
    order: 2;
  }

  .order-sm-3 {
    order: 3;
  }

  .order-sm-4 {
    order: 4;
  }

  .order-sm-5 {
    order: 5;
  }

  .order-sm-6 {
    order: 6;
  }

  .order-sm-7 {
    order: 7;
  }

  .order-sm-8 {
    order: 8;
  }

  .order-sm-9 {
    order: 9;
  }

  .order-sm-10 {
    order: 10;
  }

  .order-sm-11 {
    order: 11;
  }

  .order-sm-12 {
    order: 12;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.3333333333%;
  }

  .offset-sm-2 {
    margin-left: 16.6666666667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.3333333333%;
  }

  .offset-sm-5 {
    margin-left: 41.6666666667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.3333333333%;
  }

  .offset-sm-8 {
    margin-left: 66.6666666667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.3333333333%;
  }

  .offset-sm-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-md-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-md-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-md-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-md-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-md-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-md-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-md-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-md-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-md-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-md-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-md-first {
    order: -1;
  }

  .order-md-last {
    order: 13;
  }

  .order-md-0 {
    order: 0;
  }

  .order-md-1 {
    order: 1;
  }

  .order-md-2 {
    order: 2;
  }

  .order-md-3 {
    order: 3;
  }

  .order-md-4 {
    order: 4;
  }

  .order-md-5 {
    order: 5;
  }

  .order-md-6 {
    order: 6;
  }

  .order-md-7 {
    order: 7;
  }

  .order-md-8 {
    order: 8;
  }

  .order-md-9 {
    order: 9;
  }

  .order-md-10 {
    order: 10;
  }

  .order-md-11 {
    order: 11;
  }

  .order-md-12 {
    order: 12;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.3333333333%;
  }

  .offset-md-2 {
    margin-left: 16.6666666667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.3333333333%;
  }

  .offset-md-5 {
    margin-left: 41.6666666667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.3333333333%;
  }

  .offset-md-8 {
    margin-left: 66.6666666667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.3333333333%;
  }

  .offset-md-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-lg-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-lg-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-lg-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-lg-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-lg-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-lg-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-lg-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-lg-first {
    order: -1;
  }

  .order-lg-last {
    order: 13;
  }

  .order-lg-0 {
    order: 0;
  }

  .order-lg-1 {
    order: 1;
  }

  .order-lg-2 {
    order: 2;
  }

  .order-lg-3 {
    order: 3;
  }

  .order-lg-4 {
    order: 4;
  }

  .order-lg-5 {
    order: 5;
  }

  .order-lg-6 {
    order: 6;
  }

  .order-lg-7 {
    order: 7;
  }

  .order-lg-8 {
    order: 8;
  }

  .order-lg-9 {
    order: 9;
  }

  .order-lg-10 {
    order: 10;
  }

  .order-lg-11 {
    order: 11;
  }

  .order-lg-12 {
    order: 12;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.3333333333%;
  }

  .offset-lg-2 {
    margin-left: 16.6666666667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.3333333333%;
  }

  .offset-lg-5 {
    margin-left: 41.6666666667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.3333333333%;
  }

  .offset-lg-8 {
    margin-left: 66.6666666667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.3333333333%;
  }

  .offset-lg-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-xl-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-xl-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-xl-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-xl-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-xl-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-xl-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-xl-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-xl-first {
    order: -1;
  }

  .order-xl-last {
    order: 13;
  }

  .order-xl-0 {
    order: 0;
  }

  .order-xl-1 {
    order: 1;
  }

  .order-xl-2 {
    order: 2;
  }

  .order-xl-3 {
    order: 3;
  }

  .order-xl-4 {
    order: 4;
  }

  .order-xl-5 {
    order: 5;
  }

  .order-xl-6 {
    order: 6;
  }

  .order-xl-7 {
    order: 7;
  }

  .order-xl-8 {
    order: 8;
  }

  .order-xl-9 {
    order: 9;
  }

  .order-xl-10 {
    order: 10;
  }

  .order-xl-11 {
    order: 11;
  }

  .order-xl-12 {
    order: 12;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.3333333333%;
  }

  .offset-xl-2 {
    margin-left: 16.6666666667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.3333333333%;
  }

  .offset-xl-5 {
    margin-left: 41.6666666667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.3333333333%;
  }

  .offset-xl-8 {
    margin-left: 66.6666666667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.3333333333%;
  }

  .offset-xl-11 {
    margin-left: 91.6666666667%;
  }
}
.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
}
.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}
.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}
.table tbody + tbody {
  border-top: 2px solid #dee2e6;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid #dee2e6;
}
.table-bordered th,
.table-bordered td {
  border: 1px solid #dee2e6;
}
.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b8c1cd;
}
.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #7a8da1;
}

.table-hover .table-primary:hover {
  background-color: #a9b4c2;
}
.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #a9b4c2;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6d8db;
}
.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #b3b7bb;
}

.table-hover .table-secondary:hover {
  background-color: #c8cbcf;
}
.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #c8cbcf;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb;
}
.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #8fd19e;
}

.table-hover .table-success:hover {
  background-color: #b1dfbb;
}
.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #b1dfbb;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb;
}
.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #86cfda;
}

.table-hover .table-info:hover {
  background-color: #abdde5;
}
.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #abdde5;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba;
}
.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffdf7e;
}

.table-hover .table-warning:hover {
  background-color: #ffe8a1;
}
.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #ffe8a1;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb;
}
.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #ed969e;
}

.table-hover .table-danger:hover {
  background-color: #f1b0b7;
}
.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #f1b0b7;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe;
}
.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc;
}

.table-hover .table-light:hover {
  background-color: #ececf6;
}
.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #ececf6;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca;
}
.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #95999c;
}

.table-hover .table-dark:hover {
  background-color: #b9bbbe;
}
.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #b9bbbe;
}

.table-gray-custom,
.table-gray-custom > th,
.table-gray-custom > td {
  background-color: #fbfcfd;
}
.table-gray-custom th,
.table-gray-custom td,
.table-gray-custom thead th,
.table-gray-custom tbody + tbody {
  border-color: #f8f9fb;
}

.table-hover .table-gray-custom:hover {
  background-color: #eaeff5;
}
.table-hover .table-gray-custom:hover > td,
.table-hover .table-gray-custom:hover > th {
  background-color: #eaeff5;
}

.table-tertiary,
.table-tertiary > th,
.table-tertiary > td {
  background-color: #c8cbcf;
}
.table-tertiary th,
.table-tertiary td,
.table-tertiary thead th,
.table-tertiary tbody + tbody {
  border-color: #999fa5;
}

.table-hover .table-tertiary:hover {
  background-color: #babec3;
}
.table-hover .table-tertiary:hover > td,
.table-hover .table-tertiary:hover > th {
  background-color: #babec3;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}
.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55;
}
.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.table-dark {
  color: #fff;
  background-color: #343a40;
}
.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #454d55;
}
.table-dark.table-bordered {
  border: 0;
}
.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}
.table-dark.table-hover tbody tr:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.table-responsive > .table-bordered {
  border: 0;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}
.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}
.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #005fcb;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 35, 75, 0.25);
}
.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

select.form-control[size], select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
.form-row > .col,
.form-row > [class*=col-] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  color: #6c757d;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}
.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.25rem;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #28a745;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #28a745;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745;
}
.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745;
}
.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #28a745;
}
.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57;
}
.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #28a745;
}

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745;
}
.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #dc3545;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545;
}
.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545;
}
.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #dc3545;
}
.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d;
}
.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #dc3545;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545;
}
.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
.form-inline .form-check {
  width: 100%;
}
@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
.form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: #212529;
  text-decoration: none;
}
.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 35, 75, 0.25);
}
.btn.disabled, .btn:disabled {
  opacity: 0.65;
}
a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  background-color: #00234b;
  border-color: #00234b;
}
.btn-primary:hover {
  color: #fff;
  background-color: #001125;
  border-color: #000b18;
}
.btn-primary:focus, .btn-primary.focus {
  color: #fff;
  background-color: #001125;
  border-color: #000b18;
  box-shadow: 0 0 0 0.2rem rgba(38, 68, 102, 0.5);
}
.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #00234b;
  border-color: #00234b;
}
.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #000b18;
  border-color: #00050b;
}
.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 68, 102, 0.5);
}

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-secondary:hover {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
}
.btn-secondary:focus, .btn-secondary.focus {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}
.btn-secondary.disabled, .btn-secondary:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #545b62;
  border-color: #4e555b;
}
.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-success:hover {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
}
.btn-success:focus, .btn-success.focus {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}
.btn-success.disabled, .btn-success:disabled {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active, .show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #1e7e34;
  border-color: #1c7430;
}
.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus, .show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-info:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
}
.btn-info:focus, .btn-info.focus {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}
.btn-info.disabled, .btn-info:disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active, .show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f;
}
.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus, .show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-warning:hover {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
}
.btn-warning:focus, .btn-warning.focus {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}
.btn-warning.disabled, .btn-warning:disabled {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active, .show > .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #d39e00;
  border-color: #c69500;
}
.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-danger:hover {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
}
.btn-danger:focus, .btn-danger.focus {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}
.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active, .show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #bd2130;
  border-color: #b21f2d;
}
.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}
.btn-light:focus, .btn-light.focus {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}
.btn-light.disabled, .btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active, .show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}
.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus, .show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}
.btn-dark:focus, .btn-dark.focus {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}
.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active, .show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
}
.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}

.btn-gray-custom {
  color: #212529;
  background-color: #f2f3f7;
  border-color: #f2f3f7;
}
.btn-gray-custom:hover {
  color: #212529;
  background-color: #dadde8;
  border-color: #d2d6e4;
}
.btn-gray-custom:focus, .btn-gray-custom.focus {
  color: #212529;
  background-color: #dadde8;
  border-color: #d2d6e4;
  box-shadow: 0 0 0 0.2rem rgba(211, 212, 216, 0.5);
}
.btn-gray-custom.disabled, .btn-gray-custom:disabled {
  color: #212529;
  background-color: #f2f3f7;
  border-color: #f2f3f7;
}
.btn-gray-custom:not(:disabled):not(.disabled):active, .btn-gray-custom:not(:disabled):not(.disabled).active, .show > .btn-gray-custom.dropdown-toggle {
  color: #212529;
  background-color: #d2d6e4;
  border-color: #cbcfdf;
}
.btn-gray-custom:not(:disabled):not(.disabled):active:focus, .btn-gray-custom:not(:disabled):not(.disabled).active:focus, .show > .btn-gray-custom.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(211, 212, 216, 0.5);
}

.btn-tertiary {
  color: #fff;
  background-color: #3b4752;
  border-color: #3b4752;
}
.btn-tertiary:hover {
  color: #fff;
  background-color: #2b343c;
  border-color: #262d34;
}
.btn-tertiary:focus, .btn-tertiary.focus {
  color: #fff;
  background-color: #2b343c;
  border-color: #262d34;
  box-shadow: 0 0 0 0.2rem rgba(88, 99, 108, 0.5);
}
.btn-tertiary.disabled, .btn-tertiary:disabled {
  color: #fff;
  background-color: #3b4752;
  border-color: #3b4752;
}
.btn-tertiary:not(:disabled):not(.disabled):active, .btn-tertiary:not(:disabled):not(.disabled).active, .show > .btn-tertiary.dropdown-toggle {
  color: #fff;
  background-color: #262d34;
  border-color: #20272d;
}
.btn-tertiary:not(:disabled):not(.disabled):active:focus, .btn-tertiary:not(:disabled):not(.disabled).active:focus, .show > .btn-tertiary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(88, 99, 108, 0.5);
}

.btn-outline-primary {
  color: #00234b;
  border-color: #00234b;
}
.btn-outline-primary:hover {
  color: #fff;
  background-color: #00234b;
  border-color: #00234b;
}
.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 35, 75, 0.5);
}
.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #00234b;
  background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #00234b;
  border-color: #00234b;
}
.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 35, 75, 0.5);
}

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}
.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #6c757d;
  background-color: transparent;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active, .show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-outline-success {
  color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:hover {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}
.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #28a745;
  background-color: transparent;
}
.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active, .show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}
.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #17a2b8;
  background-color: transparent;
}
.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active, .show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:hover {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}
.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #ffc107;
  background-color: transparent;
}
.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active, .show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}
.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #dc3545;
  background-color: transparent;
}
.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active, .show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:focus, .btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}
.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}
.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active, .show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:focus, .btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}
.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent;
}
.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active, .show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-outline-gray-custom {
  color: #f2f3f7;
  border-color: #f2f3f7;
}
.btn-outline-gray-custom:hover {
  color: #212529;
  background-color: #f2f3f7;
  border-color: #f2f3f7;
}
.btn-outline-gray-custom:focus, .btn-outline-gray-custom.focus {
  box-shadow: 0 0 0 0.2rem rgba(242, 243, 247, 0.5);
}
.btn-outline-gray-custom.disabled, .btn-outline-gray-custom:disabled {
  color: #f2f3f7;
  background-color: transparent;
}
.btn-outline-gray-custom:not(:disabled):not(.disabled):active, .btn-outline-gray-custom:not(:disabled):not(.disabled).active, .show > .btn-outline-gray-custom.dropdown-toggle {
  color: #212529;
  background-color: #f2f3f7;
  border-color: #f2f3f7;
}
.btn-outline-gray-custom:not(:disabled):not(.disabled):active:focus, .btn-outline-gray-custom:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-gray-custom.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(242, 243, 247, 0.5);
}

.btn-outline-tertiary {
  color: #3b4752;
  border-color: #3b4752;
}
.btn-outline-tertiary:hover {
  color: #fff;
  background-color: #3b4752;
  border-color: #3b4752;
}
.btn-outline-tertiary:focus, .btn-outline-tertiary.focus {
  box-shadow: 0 0 0 0.2rem rgba(59, 71, 82, 0.5);
}
.btn-outline-tertiary.disabled, .btn-outline-tertiary:disabled {
  color: #3b4752;
  background-color: transparent;
}
.btn-outline-tertiary:not(:disabled):not(.disabled):active, .btn-outline-tertiary:not(:disabled):not(.disabled).active, .show > .btn-outline-tertiary.dropdown-toggle {
  color: #fff;
  background-color: #3b4752;
  border-color: #3b4752;
}
.btn-outline-tertiary:not(:disabled):not(.disabled):active:focus, .btn-outline-tertiary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-tertiary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(59, 71, 82, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #00234b;
  text-decoration: none;
}
.btn-link:hover {
  color: black;
  text-decoration: underline;
}
.btn-link:focus, .btn-link.focus {
  text-decoration: underline;
  box-shadow: none;
}
.btn-link:disabled, .btn-link.disabled {
  color: #6c757d;
  pointer-events: none;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.btn-block {
  display: block;
  width: 100%;
}
.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type=submit].btn-block,
input[type=reset].btn-block,
input[type=button].btn-block {
  width: 100%;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}
.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}
.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropleft .dropdown-toggle::after {
  display: none;
}
.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^=top], .dropdown-menu[x-placement^=right], .dropdown-menu[x-placement^=bottom], .dropdown-menu[x-placement^=left] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  background-color: #f8f9fa;
}
.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #00234b;
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 1;
}
.btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropright .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}
.btn-group-toggle > .btn input[type=radio],
.btn-group-toggle > .btn input[type=checkbox],
.btn-group-toggle > .btn-group > .btn input[type=radio],
.btn-group-toggle > .btn-group > .btn input[type=checkbox] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-control-plaintext,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  flex: 1 1 0%;
  min-width: 0;
  margin-bottom: 0;
}
.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .form-control-plaintext + .form-control,
.input-group > .form-control-plaintext + .custom-select,
.input-group > .form-control-plaintext + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -1px;
}
.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}
.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}
.input-group > .form-control:not(:last-child),
.input-group > .custom-select:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .custom-file {
  display: flex;
  align-items: center;
}
.input-group > .custom-file:not(:last-child) .custom-file-label, .input-group > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group-prepend,
.input-group-append {
  display: flex;
}
.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}
.input-group-prepend .btn:focus,
.input-group-append .btn:focus {
  z-index: 3;
}
.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.input-group-text input[type=radio],
.input-group-text input[type=checkbox] {
  margin-top: 0;
}

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px);
}

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px);
}

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
}

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0;
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #00234b;
  background-color: #00234b;
}
.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(0, 35, 75, 0.25);
}
.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #005fcb;
}
.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: #0076fe;
  border-color: #0076fe;
}
.custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
  color: #6c757d;
}
.custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e9ecef;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}
.custom-control-label::before {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: #adb5bd solid 1px;
}
.custom-control-label::after {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: no-repeat 50%/50% 50%;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #00234b;
  background-color: #00234b;
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 35, 75, 0.5);
}
.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(0, 35, 75, 0.5);
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 35, 75, 0.5);
}

.custom-switch {
  padding-left: 2.25rem;
}
.custom-switch .custom-control-label::before {
  left: -2.25rem;
  width: 1.75rem;
  pointer-events: all;
  border-radius: 0.5rem;
}
.custom-switch .custom-control-label::after {
  top: calc(0.25rem + 2px);
  left: calc(-2.25rem + 2px);
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  background-color: #adb5bd;
  border-radius: 0.5rem;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    transition: none;
  }
}
.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #fff;
  transform: translateX(0.75rem);
}
.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 35, 75, 0.5);
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  appearance: none;
}
.custom-select:focus {
  border-color: #005fcb;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 35, 75, 0.25);
}
.custom-select:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}
.custom-select[multiple], .custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}
.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}
.custom-select::-ms-expand {
  display: none;
}
.custom-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
}

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  opacity: 0;
}
.custom-file-input:focus ~ .custom-file-label {
  border-color: #005fcb;
  box-shadow: 0 0 0 0.2rem rgba(0, 35, 75, 0.25);
}
.custom-file-input[disabled] ~ .custom-file-label, .custom-file-input:disabled ~ .custom-file-label {
  background-color: #e9ecef;
}
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}
.custom-file-input ~ .custom-file-label[data-browse]::after {
  content: attr(data-browse);
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.5em + 0.75rem);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  content: "Browse";
  background-color: #e9ecef;
  border-left: inherit;
  border-radius: 0 0.25rem 0.25rem 0;
}

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}
.custom-range:focus {
  outline: none;
}
.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 35, 75, 0.25);
}
.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 35, 75, 0.25);
}
.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 35, 75, 0.25);
}
.custom-range::-moz-focus-outer {
  border: 0;
}
.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #00234b;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    transition: none;
  }
}
.custom-range::-webkit-slider-thumb:active {
  background-color: #0076fe;
}
.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #00234b;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    transition: none;
  }
}
.custom-range::-moz-range-thumb:active {
  background-color: #0076fe;
}
.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  background-color: #00234b;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    transition: none;
  }
}
.custom-range::-ms-thumb:active {
  background-color: #0076fe;
}
.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}
.custom-range::-ms-fill-lower {
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}
.custom-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-moz-range-track {
  cursor: default;
}
.custom-range:disabled::-ms-thumb {
  background-color: #adb5bd;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
.custom-file-label,
.custom-select {
    transition: none;
  }
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}
.nav-link:hover, .nav-link:focus {
  text-decoration: none;
}
.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}
.nav-tabs .nav-item {
  margin-bottom: -1px;
}
.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef #dee2e6;
}
.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  border-radius: 0.25rem;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #00234b;
}

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}
.navbar .container,
.navbar .container-fluid,
.navbar .container-sm,
.navbar .container-md,
.navbar .container-lg,
.navbar .container-xl {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}
.navbar-brand:hover, .navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
.navbar-toggler:hover, .navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
.navbar-expand-sm > .container-fluid,
.navbar-expand-sm > .container-sm,
.navbar-expand-sm > .container-md,
.navbar-expand-sm > .container-lg,
.navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm > .container,
.navbar-expand-sm > .container-fluid,
.navbar-expand-sm > .container-sm,
.navbar-expand-sm > .container-md,
.navbar-expand-sm > .container-lg,
.navbar-expand-sm > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}
@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
.navbar-expand-md > .container-fluid,
.navbar-expand-md > .container-sm,
.navbar-expand-md > .container-md,
.navbar-expand-md > .container-lg,
.navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md > .container,
.navbar-expand-md > .container-fluid,
.navbar-expand-md > .container-sm,
.navbar-expand-md > .container-md,
.navbar-expand-md > .container-lg,
.navbar-expand-md > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}
@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
.navbar-expand-lg > .container-fluid,
.navbar-expand-lg > .container-sm,
.navbar-expand-lg > .container-md,
.navbar-expand-lg > .container-lg,
.navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg > .container,
.navbar-expand-lg > .container-fluid,
.navbar-expand-lg > .container-sm,
.navbar-expand-lg > .container-md,
.navbar-expand-lg > .container-lg,
.navbar-expand-lg > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}
@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
.navbar-expand-xl > .container-fluid,
.navbar-expand-xl > .container-sm,
.navbar-expand-xl > .container-md,
.navbar-expand-xl > .container-lg,
.navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl > .container,
.navbar-expand-xl > .container-fluid,
.navbar-expand-xl > .container-sm,
.navbar-expand-xl > .container-md,
.navbar-expand-xl > .container-lg,
.navbar-expand-xl > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}
.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl {
  padding-right: 0;
  padding-left: 0;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl {
  flex-wrap: nowrap;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}
.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}
.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(0, 0, 0, 0.5)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #fff;
}
.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #fff;
}
.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}
.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}
.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}
.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}
.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(255, 255, 255, 0.5)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-text a {
  color: #fff;
}
.navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
  color: #fff;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group:first-child .list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.card > .list-group:last-child .list-group-item:last-child {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}
.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}
.card-header + .list-group .list-group-item:first-child {
  border-top: 0;
}

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}
.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
}

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-deck .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .card-deck .card {
    flex: 1 0 0%;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}

.card-group > .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
.card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
.card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
.card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
.card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 0.75rem;
}
@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion > .card {
  overflow: hidden;
}
.accordion > .card:not(:last-of-type) {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.accordion > .card:not(:first-of-type) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.accordion > .card > .card-header {
  border-radius: 0;
  margin-bottom: -1px;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}
.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  color: #6c757d;
  content: "/";
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}
.breadcrumb-item.active {
  color: #6c757d;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #00234b;
  background-color: #fff;
  border: 1px solid #dee2e6;
}
.page-link:hover {
  z-index: 2;
  color: black;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}
.page-link:focus {
  z-index: 3;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 35, 75, 0.25);
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #00234b;
  border-color: #00234b;
}
.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}
.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}
.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}
.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}
.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .badge {
    transition: none;
  }
}
a.badge:hover, a.badge:focus {
  text-decoration: none;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.badge-primary {
  color: #fff;
  background-color: #00234b;
}
a.badge-primary:hover, a.badge-primary:focus {
  color: #fff;
  background-color: #000b18;
}
a.badge-primary:focus, a.badge-primary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 35, 75, 0.5);
}

.badge-secondary {
  color: #fff;
  background-color: #6c757d;
}
a.badge-secondary:hover, a.badge-secondary:focus {
  color: #fff;
  background-color: #545b62;
}
a.badge-secondary:focus, a.badge-secondary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.badge-success {
  color: #fff;
  background-color: #28a745;
}
a.badge-success:hover, a.badge-success:focus {
  color: #fff;
  background-color: #1e7e34;
}
a.badge-success:focus, a.badge-success.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.badge-info {
  color: #fff;
  background-color: #17a2b8;
}
a.badge-info:hover, a.badge-info:focus {
  color: #fff;
  background-color: #117a8b;
}
a.badge-info:focus, a.badge-info.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.badge-warning {
  color: #212529;
  background-color: #ffc107;
}
a.badge-warning:hover, a.badge-warning:focus {
  color: #212529;
  background-color: #d39e00;
}
a.badge-warning:focus, a.badge-warning.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.badge-danger {
  color: #fff;
  background-color: #dc3545;
}
a.badge-danger:hover, a.badge-danger:focus {
  color: #fff;
  background-color: #bd2130;
}
a.badge-danger:focus, a.badge-danger.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.badge-light {
  color: #212529;
  background-color: #f8f9fa;
}
a.badge-light:hover, a.badge-light:focus {
  color: #212529;
  background-color: #dae0e5;
}
a.badge-light:focus, a.badge-light.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.badge-dark {
  color: #fff;
  background-color: #343a40;
}
a.badge-dark:hover, a.badge-dark:focus {
  color: #fff;
  background-color: #1d2124;
}
a.badge-dark:focus, a.badge-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.badge-gray-custom {
  color: #212529;
  background-color: #f2f3f7;
}
a.badge-gray-custom:hover, a.badge-gray-custom:focus {
  color: #212529;
  background-color: #d2d6e4;
}
a.badge-gray-custom:focus, a.badge-gray-custom.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(242, 243, 247, 0.5);
}

.badge-tertiary {
  color: #fff;
  background-color: #3b4752;
}
a.badge-tertiary:hover, a.badge-tertiary:focus {
  color: #fff;
  background-color: #262d34;
}
a.badge-tertiary:focus, a.badge-tertiary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(59, 71, 82, 0.5);
}

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem;
}
@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 4rem;
}
.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.alert-primary {
  color: #001227;
  background-color: #ccd3db;
  border-color: #b8c1cd;
}
.alert-primary hr {
  border-top-color: #a9b4c2;
}
.alert-primary .alert-link {
  color: black;
}

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db;
}
.alert-secondary hr {
  border-top-color: #c8cbcf;
}
.alert-secondary .alert-link {
  color: #202326;
}

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}
.alert-success hr {
  border-top-color: #b1dfbb;
}
.alert-success .alert-link {
  color: #0b2e13;
}

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
}
.alert-info hr {
  border-top-color: #abdde5;
}
.alert-info .alert-link {
  color: #062c33;
}

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}
.alert-warning hr {
  border-top-color: #ffe8a1;
}
.alert-warning .alert-link {
  color: #533f03;
}

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
.alert-danger hr {
  border-top-color: #f1b0b7;
}
.alert-danger .alert-link {
  color: #491217;
}

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe;
}
.alert-light hr {
  border-top-color: #ececf6;
}
.alert-light .alert-link {
  color: #686868;
}

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca;
}
.alert-dark hr {
  border-top-color: #b9bbbe;
}
.alert-dark .alert-link {
  color: #040505;
}

.alert-gray-custom {
  color: #7e7e80;
  background-color: #fcfdfd;
  border-color: #fbfcfd;
}
.alert-gray-custom hr {
  border-top-color: #eaeff5;
}
.alert-gray-custom .alert-link {
  color: #656566;
}

.alert-tertiary {
  color: #1f252b;
  background-color: #d8dadc;
  border-color: #c8cbcf;
}
.alert-tertiary hr {
  border-top-color: #babec3;
}
.alert-tertiary .alert-link {
  color: #0a0c0d;
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}
.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #00234b;
  transition: width 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1;
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}
.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.list-group-item:last-child {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
}
.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #00234b;
  border-color: #00234b;
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal .list-group-item:first-child {
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
}
.list-group-horizontal .list-group-item:last-child {
  border-top-right-radius: 0.25rem;
  border-bottom-left-radius: 0;
}
.list-group-horizontal .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}
.list-group-horizontal .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-sm .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-md .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-lg .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xl .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
.list-group-flush .list-group-item {
  border-right-width: 0;
  border-left-width: 0;
  border-radius: 0;
}
.list-group-flush .list-group-item:first-child {
  border-top-width: 0;
}
.list-group-flush:last-child .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #001227;
  background-color: #b8c1cd;
}
.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #001227;
  background-color: #a9b4c2;
}
.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #001227;
  border-color: #001227;
}

.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db;
}
.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #383d41;
  background-color: #c8cbcf;
}
.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #383d41;
  border-color: #383d41;
}

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb;
}
.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #155724;
  background-color: #b1dfbb;
}
.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #155724;
  border-color: #155724;
}

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb;
}
.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #0c5460;
  background-color: #abdde5;
}
.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #0c5460;
  border-color: #0c5460;
}

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba;
}
.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #856404;
  background-color: #ffe8a1;
}
.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #856404;
  border-color: #856404;
}

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb;
}
.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #721c24;
  background-color: #f1b0b7;
}
.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #721c24;
  border-color: #721c24;
}

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe;
}
.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #818182;
  background-color: #ececf6;
}
.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #818182;
  border-color: #818182;
}

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca;
}
.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #1b1e21;
  background-color: #b9bbbe;
}
.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #1b1e21;
  border-color: #1b1e21;
}

.list-group-item-gray-custom {
  color: #7e7e80;
  background-color: #fbfcfd;
}
.list-group-item-gray-custom.list-group-item-action:hover, .list-group-item-gray-custom.list-group-item-action:focus {
  color: #7e7e80;
  background-color: #eaeff5;
}
.list-group-item-gray-custom.list-group-item-action.active {
  color: #fff;
  background-color: #7e7e80;
  border-color: #7e7e80;
}

.list-group-item-tertiary {
  color: #1f252b;
  background-color: #c8cbcf;
}
.list-group-item-tertiary.list-group-item-action:hover, .list-group-item-tertiary.list-group-item-action:focus {
  color: #1f252b;
  background-color: #babec3;
}
.list-group-item-tertiary.list-group-item-action.active {
  color: #fff;
  background-color: #1f252b;
  border-color: #1f252b;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}
.close:hover {
  color: #000;
  text-decoration: none;
}
.close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
  opacity: 0.75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  appearance: none;
}

a.close.disabled {
  pointer-events: none;
}

.toast {
  max-width: 350px;
  overflow: hidden;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  opacity: 0;
  border-radius: 0.25rem;
}
.toast:not(:last-child) {
  margin-bottom: 0.75rem;
}
.toast.showing {
  opacity: 1;
}
.toast.show {
  display: block;
  opacity: 1;
}
.toast.hide {
  display: none;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.toast-body {
  padding: 0.75rem;
}

.modal-open {
  overflow: hidden;
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}
.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}
.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  content: "";
}
.modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}
.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}
.modal-footer > * {
  margin: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }

  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }

  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
  }

  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
.modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}
.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: "Source Sans Pro", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: 0.9;
}
.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[x-placement^=top] {
  padding: 0.4rem 0;
}
.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^=top] .arrow {
  bottom: 0;
}
.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^=top] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-right, .bs-tooltip-auto[x-placement^=right] {
  padding: 0 0.4rem;
}
.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^=right] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^=right] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^=bottom] {
  padding: 0.4rem 0;
}
.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^=bottom] .arrow {
  top: 0;
}
.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-left, .bs-tooltip-auto[x-placement^=left] {
  padding: 0 0.4rem;
}
.bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^=left] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^=left] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: "Source Sans Pro", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}
.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3rem;
}
.popover .arrow::before, .popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top, .bs-popover-auto[x-placement^=top] {
  margin-bottom: 0.5rem;
}
.bs-popover-top > .arrow, .bs-popover-auto[x-placement^=top] > .arrow {
  bottom: calc(-0.5rem - 1px);
}
.bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^=top] > .arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^=top] > .arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

.bs-popover-right, .bs-popover-auto[x-placement^=right] {
  margin-left: 0.5rem;
}
.bs-popover-right > .arrow, .bs-popover-auto[x-placement^=right] > .arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^=right] > .arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^=right] > .arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}

.bs-popover-bottom, .bs-popover-auto[x-placement^=bottom] {
  margin-top: 0.5rem;
}
.bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^=bottom] > .arrow {
  top: calc(-0.5rem - 1px);
}
.bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^=bottom] > .arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^=bottom] > .arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}

.bs-popover-left, .bs-popover-auto[x-placement^=left] {
  margin-right: 0.5rem;
}
.bs-popover-left > .arrow, .bs-popover-auto[x-placement^=left] > .arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^=left] > .arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^=left] > .arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
.carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50%/100% 100%;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}
.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border 0.75s linear infinite;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
  }
}
.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: spinner-grow 0.75s linear infinite;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #00234b !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #000b18 !important;
}

.bg-secondary {
  background-color: #6c757d !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important;
}

.bg-success {
  background-color: #28a745 !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important;
}

.bg-info {
  background-color: #17a2b8 !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important;
}

.bg-danger {
  background-color: #dc3545 !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important;
}

.bg-dark {
  background-color: #343a40 !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important;
}

.bg-gray-custom {
  background-color: #f2f3f7 !important;
}

a.bg-gray-custom:hover, a.bg-gray-custom:focus,
button.bg-gray-custom:hover,
button.bg-gray-custom:focus {
  background-color: #d2d6e4 !important;
}

.bg-tertiary {
  background-color: #3b4752 !important;
}

a.bg-tertiary:hover, a.bg-tertiary:focus,
button.bg-tertiary:hover,
button.bg-tertiary:focus {
  background-color: #262d34 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #00234b !important;
}

.border-secondary {
  border-color: #6c757d !important;
}

.border-success {
  border-color: #28a745 !important;
}

.border-info {
  border-color: #17a2b8 !important;
}

.border-warning {
  border-color: #ffc107 !important;
}

.border-danger {
  border-color: #dc3545 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #343a40 !important;
}

.border-gray-custom {
  border-color: #f2f3f7 !important;
}

.border-tertiary {
  border-color: #3b4752 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded-sm {
  border-radius: 0.2rem !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-lg {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}
@media print {
  .d-print-none {
    display: none !important;
  }

  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }
}
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.embed-responsive::before {
  display: block;
  content: "";
}
.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.8571428571%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-sm-fill {
    flex: 1 1 auto !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-md-fill {
    flex: 1 1 auto !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-lg-fill {
    flex: 1 1 auto !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-xl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}
.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }

  .float-sm-right {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }
}
@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }

  .float-md-right {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }
}
@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }

  .float-lg-right {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }

  .float-xl-right {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }
}
.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }

  .mt-sm-0,
.my-sm-0 {
    margin-top: 0 !important;
  }

  .mr-sm-0,
.mx-sm-0 {
    margin-right: 0 !important;
  }

  .mb-sm-0,
.my-sm-0 {
    margin-bottom: 0 !important;
  }

  .ml-sm-0,
.mx-sm-0 {
    margin-left: 0 !important;
  }

  .m-sm-1 {
    margin: 0.25rem !important;
  }

  .mt-sm-1,
.my-sm-1 {
    margin-top: 0.25rem !important;
  }

  .mr-sm-1,
.mx-sm-1 {
    margin-right: 0.25rem !important;
  }

  .mb-sm-1,
.my-sm-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-sm-1,
.mx-sm-1 {
    margin-left: 0.25rem !important;
  }

  .m-sm-2 {
    margin: 0.5rem !important;
  }

  .mt-sm-2,
.my-sm-2 {
    margin-top: 0.5rem !important;
  }

  .mr-sm-2,
.mx-sm-2 {
    margin-right: 0.5rem !important;
  }

  .mb-sm-2,
.my-sm-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-sm-2,
.mx-sm-2 {
    margin-left: 0.5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .mt-sm-3,
.my-sm-3 {
    margin-top: 1rem !important;
  }

  .mr-sm-3,
.mx-sm-3 {
    margin-right: 1rem !important;
  }

  .mb-sm-3,
.my-sm-3 {
    margin-bottom: 1rem !important;
  }

  .ml-sm-3,
.mx-sm-3 {
    margin-left: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .mt-sm-4,
.my-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mr-sm-4,
.mx-sm-4 {
    margin-right: 1.5rem !important;
  }

  .mb-sm-4,
.my-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-sm-4,
.mx-sm-4 {
    margin-left: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 3rem !important;
  }

  .mt-sm-5,
.my-sm-5 {
    margin-top: 3rem !important;
  }

  .mr-sm-5,
.mx-sm-5 {
    margin-right: 3rem !important;
  }

  .mb-sm-5,
.my-sm-5 {
    margin-bottom: 3rem !important;
  }

  .ml-sm-5,
.mx-sm-5 {
    margin-left: 3rem !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .pt-sm-0,
.py-sm-0 {
    padding-top: 0 !important;
  }

  .pr-sm-0,
.px-sm-0 {
    padding-right: 0 !important;
  }

  .pb-sm-0,
.py-sm-0 {
    padding-bottom: 0 !important;
  }

  .pl-sm-0,
.px-sm-0 {
    padding-left: 0 !important;
  }

  .p-sm-1 {
    padding: 0.25rem !important;
  }

  .pt-sm-1,
.py-sm-1 {
    padding-top: 0.25rem !important;
  }

  .pr-sm-1,
.px-sm-1 {
    padding-right: 0.25rem !important;
  }

  .pb-sm-1,
.py-sm-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-sm-1,
.px-sm-1 {
    padding-left: 0.25rem !important;
  }

  .p-sm-2 {
    padding: 0.5rem !important;
  }

  .pt-sm-2,
.py-sm-2 {
    padding-top: 0.5rem !important;
  }

  .pr-sm-2,
.px-sm-2 {
    padding-right: 0.5rem !important;
  }

  .pb-sm-2,
.py-sm-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-sm-2,
.px-sm-2 {
    padding-left: 0.5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .pt-sm-3,
.py-sm-3 {
    padding-top: 1rem !important;
  }

  .pr-sm-3,
.px-sm-3 {
    padding-right: 1rem !important;
  }

  .pb-sm-3,
.py-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pl-sm-3,
.px-sm-3 {
    padding-left: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .pt-sm-4,
.py-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pr-sm-4,
.px-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pb-sm-4,
.py-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-sm-4,
.px-sm-4 {
    padding-left: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 3rem !important;
  }

  .pt-sm-5,
.py-sm-5 {
    padding-top: 3rem !important;
  }

  .pr-sm-5,
.px-sm-5 {
    padding-right: 3rem !important;
  }

  .pb-sm-5,
.py-sm-5 {
    padding-bottom: 3rem !important;
  }

  .pl-sm-5,
.px-sm-5 {
    padding-left: 3rem !important;
  }

  .m-sm-n1 {
    margin: -0.25rem !important;
  }

  .mt-sm-n1,
.my-sm-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-sm-n1,
.mx-sm-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-sm-n1,
.my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-sm-n1,
.mx-sm-n1 {
    margin-left: -0.25rem !important;
  }

  .m-sm-n2 {
    margin: -0.5rem !important;
  }

  .mt-sm-n2,
.my-sm-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-sm-n2,
.mx-sm-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-sm-n2,
.my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-sm-n2,
.mx-sm-n2 {
    margin-left: -0.5rem !important;
  }

  .m-sm-n3 {
    margin: -1rem !important;
  }

  .mt-sm-n3,
.my-sm-n3 {
    margin-top: -1rem !important;
  }

  .mr-sm-n3,
.mx-sm-n3 {
    margin-right: -1rem !important;
  }

  .mb-sm-n3,
.my-sm-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-sm-n3,
.mx-sm-n3 {
    margin-left: -1rem !important;
  }

  .m-sm-n4 {
    margin: -1.5rem !important;
  }

  .mt-sm-n4,
.my-sm-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-sm-n4,
.mx-sm-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-sm-n4,
.my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-sm-n4,
.mx-sm-n4 {
    margin-left: -1.5rem !important;
  }

  .m-sm-n5 {
    margin: -3rem !important;
  }

  .mt-sm-n5,
.my-sm-n5 {
    margin-top: -3rem !important;
  }

  .mr-sm-n5,
.mx-sm-n5 {
    margin-right: -3rem !important;
  }

  .mb-sm-n5,
.my-sm-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-sm-n5,
.mx-sm-n5 {
    margin-left: -3rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mt-sm-auto,
.my-sm-auto {
    margin-top: auto !important;
  }

  .mr-sm-auto,
.mx-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-auto,
.my-sm-auto {
    margin-bottom: auto !important;
  }

  .ml-sm-auto,
.mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }

  .mt-md-0,
.my-md-0 {
    margin-top: 0 !important;
  }

  .mr-md-0,
.mx-md-0 {
    margin-right: 0 !important;
  }

  .mb-md-0,
.my-md-0 {
    margin-bottom: 0 !important;
  }

  .ml-md-0,
.mx-md-0 {
    margin-left: 0 !important;
  }

  .m-md-1 {
    margin: 0.25rem !important;
  }

  .mt-md-1,
.my-md-1 {
    margin-top: 0.25rem !important;
  }

  .mr-md-1,
.mx-md-1 {
    margin-right: 0.25rem !important;
  }

  .mb-md-1,
.my-md-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-md-1,
.mx-md-1 {
    margin-left: 0.25rem !important;
  }

  .m-md-2 {
    margin: 0.5rem !important;
  }

  .mt-md-2,
.my-md-2 {
    margin-top: 0.5rem !important;
  }

  .mr-md-2,
.mx-md-2 {
    margin-right: 0.5rem !important;
  }

  .mb-md-2,
.my-md-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-md-2,
.mx-md-2 {
    margin-left: 0.5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .mt-md-3,
.my-md-3 {
    margin-top: 1rem !important;
  }

  .mr-md-3,
.mx-md-3 {
    margin-right: 1rem !important;
  }

  .mb-md-3,
.my-md-3 {
    margin-bottom: 1rem !important;
  }

  .ml-md-3,
.mx-md-3 {
    margin-left: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .mt-md-4,
.my-md-4 {
    margin-top: 1.5rem !important;
  }

  .mr-md-4,
.mx-md-4 {
    margin-right: 1.5rem !important;
  }

  .mb-md-4,
.my-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-md-4,
.mx-md-4 {
    margin-left: 1.5rem !important;
  }

  .m-md-5 {
    margin: 3rem !important;
  }

  .mt-md-5,
.my-md-5 {
    margin-top: 3rem !important;
  }

  .mr-md-5,
.mx-md-5 {
    margin-right: 3rem !important;
  }

  .mb-md-5,
.my-md-5 {
    margin-bottom: 3rem !important;
  }

  .ml-md-5,
.mx-md-5 {
    margin-left: 3rem !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .pt-md-0,
.py-md-0 {
    padding-top: 0 !important;
  }

  .pr-md-0,
.px-md-0 {
    padding-right: 0 !important;
  }

  .pb-md-0,
.py-md-0 {
    padding-bottom: 0 !important;
  }

  .pl-md-0,
.px-md-0 {
    padding-left: 0 !important;
  }

  .p-md-1 {
    padding: 0.25rem !important;
  }

  .pt-md-1,
.py-md-1 {
    padding-top: 0.25rem !important;
  }

  .pr-md-1,
.px-md-1 {
    padding-right: 0.25rem !important;
  }

  .pb-md-1,
.py-md-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-md-1,
.px-md-1 {
    padding-left: 0.25rem !important;
  }

  .p-md-2 {
    padding: 0.5rem !important;
  }

  .pt-md-2,
.py-md-2 {
    padding-top: 0.5rem !important;
  }

  .pr-md-2,
.px-md-2 {
    padding-right: 0.5rem !important;
  }

  .pb-md-2,
.py-md-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-md-2,
.px-md-2 {
    padding-left: 0.5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .pt-md-3,
.py-md-3 {
    padding-top: 1rem !important;
  }

  .pr-md-3,
.px-md-3 {
    padding-right: 1rem !important;
  }

  .pb-md-3,
.py-md-3 {
    padding-bottom: 1rem !important;
  }

  .pl-md-3,
.px-md-3 {
    padding-left: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .pt-md-4,
.py-md-4 {
    padding-top: 1.5rem !important;
  }

  .pr-md-4,
.px-md-4 {
    padding-right: 1.5rem !important;
  }

  .pb-md-4,
.py-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-md-4,
.px-md-4 {
    padding-left: 1.5rem !important;
  }

  .p-md-5 {
    padding: 3rem !important;
  }

  .pt-md-5,
.py-md-5 {
    padding-top: 3rem !important;
  }

  .pr-md-5,
.px-md-5 {
    padding-right: 3rem !important;
  }

  .pb-md-5,
.py-md-5 {
    padding-bottom: 3rem !important;
  }

  .pl-md-5,
.px-md-5 {
    padding-left: 3rem !important;
  }

  .m-md-n1 {
    margin: -0.25rem !important;
  }

  .mt-md-n1,
.my-md-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-md-n1,
.mx-md-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-md-n1,
.my-md-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-md-n1,
.mx-md-n1 {
    margin-left: -0.25rem !important;
  }

  .m-md-n2 {
    margin: -0.5rem !important;
  }

  .mt-md-n2,
.my-md-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-md-n2,
.mx-md-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-md-n2,
.my-md-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-md-n2,
.mx-md-n2 {
    margin-left: -0.5rem !important;
  }

  .m-md-n3 {
    margin: -1rem !important;
  }

  .mt-md-n3,
.my-md-n3 {
    margin-top: -1rem !important;
  }

  .mr-md-n3,
.mx-md-n3 {
    margin-right: -1rem !important;
  }

  .mb-md-n3,
.my-md-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-md-n3,
.mx-md-n3 {
    margin-left: -1rem !important;
  }

  .m-md-n4 {
    margin: -1.5rem !important;
  }

  .mt-md-n4,
.my-md-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-md-n4,
.mx-md-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-md-n4,
.my-md-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-md-n4,
.mx-md-n4 {
    margin-left: -1.5rem !important;
  }

  .m-md-n5 {
    margin: -3rem !important;
  }

  .mt-md-n5,
.my-md-n5 {
    margin-top: -3rem !important;
  }

  .mr-md-n5,
.mx-md-n5 {
    margin-right: -3rem !important;
  }

  .mb-md-n5,
.my-md-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-md-n5,
.mx-md-n5 {
    margin-left: -3rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mt-md-auto,
.my-md-auto {
    margin-top: auto !important;
  }

  .mr-md-auto,
.mx-md-auto {
    margin-right: auto !important;
  }

  .mb-md-auto,
.my-md-auto {
    margin-bottom: auto !important;
  }

  .ml-md-auto,
.mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }

  .mt-lg-0,
.my-lg-0 {
    margin-top: 0 !important;
  }

  .mr-lg-0,
.mx-lg-0 {
    margin-right: 0 !important;
  }

  .mb-lg-0,
.my-lg-0 {
    margin-bottom: 0 !important;
  }

  .ml-lg-0,
.mx-lg-0 {
    margin-left: 0 !important;
  }

  .m-lg-1 {
    margin: 0.25rem !important;
  }

  .mt-lg-1,
.my-lg-1 {
    margin-top: 0.25rem !important;
  }

  .mr-lg-1,
.mx-lg-1 {
    margin-right: 0.25rem !important;
  }

  .mb-lg-1,
.my-lg-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-lg-1,
.mx-lg-1 {
    margin-left: 0.25rem !important;
  }

  .m-lg-2 {
    margin: 0.5rem !important;
  }

  .mt-lg-2,
.my-lg-2 {
    margin-top: 0.5rem !important;
  }

  .mr-lg-2,
.mx-lg-2 {
    margin-right: 0.5rem !important;
  }

  .mb-lg-2,
.my-lg-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-lg-2,
.mx-lg-2 {
    margin-left: 0.5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .mt-lg-3,
.my-lg-3 {
    margin-top: 1rem !important;
  }

  .mr-lg-3,
.mx-lg-3 {
    margin-right: 1rem !important;
  }

  .mb-lg-3,
.my-lg-3 {
    margin-bottom: 1rem !important;
  }

  .ml-lg-3,
.mx-lg-3 {
    margin-left: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .mt-lg-4,
.my-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mr-lg-4,
.mx-lg-4 {
    margin-right: 1.5rem !important;
  }

  .mb-lg-4,
.my-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-lg-4,
.mx-lg-4 {
    margin-left: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 3rem !important;
  }

  .mt-lg-5,
.my-lg-5 {
    margin-top: 3rem !important;
  }

  .mr-lg-5,
.mx-lg-5 {
    margin-right: 3rem !important;
  }

  .mb-lg-5,
.my-lg-5 {
    margin-bottom: 3rem !important;
  }

  .ml-lg-5,
.mx-lg-5 {
    margin-left: 3rem !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .pt-lg-0,
.py-lg-0 {
    padding-top: 0 !important;
  }

  .pr-lg-0,
.px-lg-0 {
    padding-right: 0 !important;
  }

  .pb-lg-0,
.py-lg-0 {
    padding-bottom: 0 !important;
  }

  .pl-lg-0,
.px-lg-0 {
    padding-left: 0 !important;
  }

  .p-lg-1 {
    padding: 0.25rem !important;
  }

  .pt-lg-1,
.py-lg-1 {
    padding-top: 0.25rem !important;
  }

  .pr-lg-1,
.px-lg-1 {
    padding-right: 0.25rem !important;
  }

  .pb-lg-1,
.py-lg-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-lg-1,
.px-lg-1 {
    padding-left: 0.25rem !important;
  }

  .p-lg-2 {
    padding: 0.5rem !important;
  }

  .pt-lg-2,
.py-lg-2 {
    padding-top: 0.5rem !important;
  }

  .pr-lg-2,
.px-lg-2 {
    padding-right: 0.5rem !important;
  }

  .pb-lg-2,
.py-lg-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-lg-2,
.px-lg-2 {
    padding-left: 0.5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .pt-lg-3,
.py-lg-3 {
    padding-top: 1rem !important;
  }

  .pr-lg-3,
.px-lg-3 {
    padding-right: 1rem !important;
  }

  .pb-lg-3,
.py-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pl-lg-3,
.px-lg-3 {
    padding-left: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .pt-lg-4,
.py-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pr-lg-4,
.px-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pb-lg-4,
.py-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-lg-4,
.px-lg-4 {
    padding-left: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 3rem !important;
  }

  .pt-lg-5,
.py-lg-5 {
    padding-top: 3rem !important;
  }

  .pr-lg-5,
.px-lg-5 {
    padding-right: 3rem !important;
  }

  .pb-lg-5,
.py-lg-5 {
    padding-bottom: 3rem !important;
  }

  .pl-lg-5,
.px-lg-5 {
    padding-left: 3rem !important;
  }

  .m-lg-n1 {
    margin: -0.25rem !important;
  }

  .mt-lg-n1,
.my-lg-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-lg-n1,
.mx-lg-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-lg-n1,
.my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-lg-n1,
.mx-lg-n1 {
    margin-left: -0.25rem !important;
  }

  .m-lg-n2 {
    margin: -0.5rem !important;
  }

  .mt-lg-n2,
.my-lg-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-lg-n2,
.mx-lg-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-lg-n2,
.my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-lg-n2,
.mx-lg-n2 {
    margin-left: -0.5rem !important;
  }

  .m-lg-n3 {
    margin: -1rem !important;
  }

  .mt-lg-n3,
.my-lg-n3 {
    margin-top: -1rem !important;
  }

  .mr-lg-n3,
.mx-lg-n3 {
    margin-right: -1rem !important;
  }

  .mb-lg-n3,
.my-lg-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-lg-n3,
.mx-lg-n3 {
    margin-left: -1rem !important;
  }

  .m-lg-n4 {
    margin: -1.5rem !important;
  }

  .mt-lg-n4,
.my-lg-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-lg-n4,
.mx-lg-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-lg-n4,
.my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-lg-n4,
.mx-lg-n4 {
    margin-left: -1.5rem !important;
  }

  .m-lg-n5 {
    margin: -3rem !important;
  }

  .mt-lg-n5,
.my-lg-n5 {
    margin-top: -3rem !important;
  }

  .mr-lg-n5,
.mx-lg-n5 {
    margin-right: -3rem !important;
  }

  .mb-lg-n5,
.my-lg-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-lg-n5,
.mx-lg-n5 {
    margin-left: -3rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mt-lg-auto,
.my-lg-auto {
    margin-top: auto !important;
  }

  .mr-lg-auto,
.mx-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-auto,
.my-lg-auto {
    margin-bottom: auto !important;
  }

  .ml-lg-auto,
.mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }

  .mt-xl-0,
.my-xl-0 {
    margin-top: 0 !important;
  }

  .mr-xl-0,
.mx-xl-0 {
    margin-right: 0 !important;
  }

  .mb-xl-0,
.my-xl-0 {
    margin-bottom: 0 !important;
  }

  .ml-xl-0,
.mx-xl-0 {
    margin-left: 0 !important;
  }

  .m-xl-1 {
    margin: 0.25rem !important;
  }

  .mt-xl-1,
.my-xl-1 {
    margin-top: 0.25rem !important;
  }

  .mr-xl-1,
.mx-xl-1 {
    margin-right: 0.25rem !important;
  }

  .mb-xl-1,
.my-xl-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-xl-1,
.mx-xl-1 {
    margin-left: 0.25rem !important;
  }

  .m-xl-2 {
    margin: 0.5rem !important;
  }

  .mt-xl-2,
.my-xl-2 {
    margin-top: 0.5rem !important;
  }

  .mr-xl-2,
.mx-xl-2 {
    margin-right: 0.5rem !important;
  }

  .mb-xl-2,
.my-xl-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-xl-2,
.mx-xl-2 {
    margin-left: 0.5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .mt-xl-3,
.my-xl-3 {
    margin-top: 1rem !important;
  }

  .mr-xl-3,
.mx-xl-3 {
    margin-right: 1rem !important;
  }

  .mb-xl-3,
.my-xl-3 {
    margin-bottom: 1rem !important;
  }

  .ml-xl-3,
.mx-xl-3 {
    margin-left: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .mt-xl-4,
.my-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mr-xl-4,
.mx-xl-4 {
    margin-right: 1.5rem !important;
  }

  .mb-xl-4,
.my-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-xl-4,
.mx-xl-4 {
    margin-left: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 3rem !important;
  }

  .mt-xl-5,
.my-xl-5 {
    margin-top: 3rem !important;
  }

  .mr-xl-5,
.mx-xl-5 {
    margin-right: 3rem !important;
  }

  .mb-xl-5,
.my-xl-5 {
    margin-bottom: 3rem !important;
  }

  .ml-xl-5,
.mx-xl-5 {
    margin-left: 3rem !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .pt-xl-0,
.py-xl-0 {
    padding-top: 0 !important;
  }

  .pr-xl-0,
.px-xl-0 {
    padding-right: 0 !important;
  }

  .pb-xl-0,
.py-xl-0 {
    padding-bottom: 0 !important;
  }

  .pl-xl-0,
.px-xl-0 {
    padding-left: 0 !important;
  }

  .p-xl-1 {
    padding: 0.25rem !important;
  }

  .pt-xl-1,
.py-xl-1 {
    padding-top: 0.25rem !important;
  }

  .pr-xl-1,
.px-xl-1 {
    padding-right: 0.25rem !important;
  }

  .pb-xl-1,
.py-xl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-xl-1,
.px-xl-1 {
    padding-left: 0.25rem !important;
  }

  .p-xl-2 {
    padding: 0.5rem !important;
  }

  .pt-xl-2,
.py-xl-2 {
    padding-top: 0.5rem !important;
  }

  .pr-xl-2,
.px-xl-2 {
    padding-right: 0.5rem !important;
  }

  .pb-xl-2,
.py-xl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-xl-2,
.px-xl-2 {
    padding-left: 0.5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .pt-xl-3,
.py-xl-3 {
    padding-top: 1rem !important;
  }

  .pr-xl-3,
.px-xl-3 {
    padding-right: 1rem !important;
  }

  .pb-xl-3,
.py-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pl-xl-3,
.px-xl-3 {
    padding-left: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .pt-xl-4,
.py-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pr-xl-4,
.px-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pb-xl-4,
.py-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-xl-4,
.px-xl-4 {
    padding-left: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 3rem !important;
  }

  .pt-xl-5,
.py-xl-5 {
    padding-top: 3rem !important;
  }

  .pr-xl-5,
.px-xl-5 {
    padding-right: 3rem !important;
  }

  .pb-xl-5,
.py-xl-5 {
    padding-bottom: 3rem !important;
  }

  .pl-xl-5,
.px-xl-5 {
    padding-left: 3rem !important;
  }

  .m-xl-n1 {
    margin: -0.25rem !important;
  }

  .mt-xl-n1,
.my-xl-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-xl-n1,
.mx-xl-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-xl-n1,
.my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-xl-n1,
.mx-xl-n1 {
    margin-left: -0.25rem !important;
  }

  .m-xl-n2 {
    margin: -0.5rem !important;
  }

  .mt-xl-n2,
.my-xl-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-xl-n2,
.mx-xl-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-xl-n2,
.my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-xl-n2,
.mx-xl-n2 {
    margin-left: -0.5rem !important;
  }

  .m-xl-n3 {
    margin: -1rem !important;
  }

  .mt-xl-n3,
.my-xl-n3 {
    margin-top: -1rem !important;
  }

  .mr-xl-n3,
.mx-xl-n3 {
    margin-right: -1rem !important;
  }

  .mb-xl-n3,
.my-xl-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-xl-n3,
.mx-xl-n3 {
    margin-left: -1rem !important;
  }

  .m-xl-n4 {
    margin: -1.5rem !important;
  }

  .mt-xl-n4,
.my-xl-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-xl-n4,
.mx-xl-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-xl-n4,
.my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-xl-n4,
.mx-xl-n4 {
    margin-left: -1.5rem !important;
  }

  .m-xl-n5 {
    margin: -3rem !important;
  }

  .mt-xl-n5,
.my-xl-n5 {
    margin-top: -3rem !important;
  }

  .mr-xl-n5,
.mx-xl-n5 {
    margin-right: -3rem !important;
  }

  .mb-xl-n5,
.my-xl-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-xl-n5,
.mx-xl-n5 {
    margin-left: -3rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mt-xl-auto,
.my-xl-auto {
    margin-top: auto !important;
  }

  .mr-xl-auto,
.mx-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-auto,
.my-xl-auto {
    margin-bottom: auto !important;
  }

  .ml-xl-auto,
.mx-xl-auto {
    margin-left: auto !important;
  }
}
.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }

  .text-sm-right {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }

  .text-md-right {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }

  .text-lg-right {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }

  .text-xl-right {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}
.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #00234b !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: black !important;
}

.text-secondary {
  color: #6c757d !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important;
}

.text-success {
  color: #28a745 !important;
}

a.text-success:hover, a.text-success:focus {
  color: #19692c !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important;
}

.text-warning {
  color: #ffc107 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important;
}

.text-danger {
  color: #dc3545 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important;
}

.text-gray-custom {
  color: #f2f3f7 !important;
}

a.text-gray-custom:hover, a.text-gray-custom:focus {
  color: #c3c7da !important;
}

.text-tertiary {
  color: #3b4752 !important;
}

a.text-tertiary:hover, a.text-tertiary:focus {
  color: #1b2026 !important;
}

.text-body {
  color: #212529 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *,
*::before,
*::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }

  a:not(.btn) {
    text-decoration: underline;
  }

  abbr[title]::after {
    content: " (" attr(title) ")";
  }

  pre {
    white-space: pre-wrap !important;
  }

  pre,
blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }

  thead {
    display: table-header-group;
  }

  tr,
img {
    page-break-inside: avoid;
  }

  p,
h2,
h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
h3 {
    page-break-after: avoid;
  }

  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }

  .container {
    min-width: 992px !important;
  }

  .navbar {
    display: none;
  }

  .badge {
    border: 1px solid #000;
  }

  .table {
    border-collapse: collapse !important;
  }
  .table td,
.table th {
    background-color: #fff !important;
  }

  .table-bordered th,
.table-bordered td {
    border: 1px solid #dee2e6 !important;
  }

  .table-dark {
    color: inherit;
  }
  .table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
    border-color: #dee2e6;
  }

  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6;
  }
}
html,
body {
  font-size: 14px;
}

body {
  color: #333;
  letter-spacing: -0.3px;
  overflow-y: scroll;
  overflow-x: hidden;
}

body .m-page-loader {
  background: white !important;
}

.m-page--loading-non-block .m-page-loader.m-page-loader--base.m-page-loader--non-block {
  display: flex !important;
}

.m-page-loader.m-page-loader--base.m-page-loader--non-block {
  align-items: center !important;
  height: 100% !important;
  width: 100% !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0 auto !important;
}
.m-page-loader.m-page-loader--base.m-page-loader--non-block .m-blockui {
  margin: 0 auto !important;
}

.small-content-wrapper {
  max-width: 700px;
  margin: 0 auto;
  width: 100%;
}

ul {
  padding-inline-start: 20px;
}

.ucfirst:first-letter {
  text-transform: uppercase;
}

.btn {
  font-family: "Source Sans Pro", sans-serif;
  font-weight: bold;
}

.btn.disabled,
.btn:disabled {
  opacity: 0.15;
}

.btn-lg, .btn-group-lg > .btn {
  font-weight: 300;
}
.btn-lg strong, .btn-group-lg > .btn strong {
  font-weight: 900;
}

.btn-shadowed {
  box-shadow: 0 2px 4px 0 rgba(0, 35, 75, 0.3) !important;
}

.btn.btn-outline-primary {
  color: #3b4752;
  border-color: #a6b0b6;
}

.btn-end-icon {
  font-size: 2rem;
  float: right;
  position: relative;
  line-height: 2rem !important;
  width: 0px;
  right: 1rem;
}

.btn-start-icon {
  font-size: 2rem;
  float: left;
  position: relative;
  line-height: 2rem !important;
  width: 0px;
}

.m-checkbox {
  color: #00234b;
}

.lead-xl {
  font-size: 1.5rem;
  font-weight: normal;
}

.m-portlet {
  box-shadow: 0 2px 4px 0 rgba(0, 35, 75, 0.3);
}
.m-portlet.m-portlet--rounded {
  border-radius: 1.2rem;
}
.m-portlet.m-portlet--rounded .m-portlet__head {
  border-radius: 0px;
}
.m-portlet.m-portlet--rounded .m-portlet__head:first-child {
  border-top-right-radius: 1.2rem;
  border-top-left-radius: 1.2rem;
}
.m-portlet.m-portlet--rounded .m-portlet__head:last-child {
  border-bottom-right-radius: 1.2rem;
  border-bottom-left-radius: 1.2rem;
}
.m-portlet .m-portlet__head {
  border-bottom-color: #d2d8df;
  height: auto;
  padding: 1.2rem 15px;
}
.m-portlet .m-portlet__head h6 {
  margin-bottom: 0 !important;
}
.m-portlet .m-portlet__head h5 {
  margin-bottom: 0 !important;
}
.m-portlet .m-portlet__body {
  padding: 2rem 15px;
}
.m-portlet .m-portlet__head-caption {
  font-size: 1.25rem;
}
.m-portlet .m-portlet__head-caption h6 {
  font-weight: 300;
  margin-bottom: 0 !important;
  font-size: 1.15rem;
}
.m-portlet .m-portlet__head-caption h5 {
  font-weight: normal;
  margin-bottom: 0 !important;
}
.m-portlet .m-portlet__head-caption .text-danger {
  color: #a16065 !important;
}
.m-portlet .m-portlet__head-caption .m-portlet__head-text {
  font-family: inherit !important;
}
.m-portlet.m-portlet--primary {
  background: #00234b;
}
.m-portlet.m-portlet--primary .m-portlet__body {
  color: #fff;
}

.time-slots.time-slots-daily {
  display: block;
}
.time-slots.time-slots-weekly {
  display: none;
}
@media (min-width: 768px) {
  .time-slots.time-slots-daily {
    display: none;
  }
  .time-slots.time-slots-weekly {
    display: block;
  }
}
.time-slots table {
  width: 100%;
}
.time-slots table thead th,
.time-slots table thead td,
.time-slots table tbody th,
.time-slots table tbody td {
  /*
  &:nth-child(5), &:nth-child(6) {
    display: none;
  }
  */
}
.time-slots table thead th.days-offset--1, .time-slots table thead th.days-offset--2, .time-slots table thead th.days-offset--3, .time-slots table thead th.days-offset--4, .time-slots table thead th.days-offset--5, .time-slots table thead th.days-offset--6, .time-slots table thead th.days-offset--7, .time-slots table thead th.days-offset-3, .time-slots table thead th.days-offset-4, .time-slots table thead th.days-offset-5, .time-slots table thead th.days-offset-6, .time-slots table thead th.days-offset-7,
.time-slots table thead td.days-offset--1,
.time-slots table thead td.days-offset--2,
.time-slots table thead td.days-offset--3,
.time-slots table thead td.days-offset--4,
.time-slots table thead td.days-offset--5,
.time-slots table thead td.days-offset--6,
.time-slots table thead td.days-offset--7,
.time-slots table thead td.days-offset-3,
.time-slots table thead td.days-offset-4,
.time-slots table thead td.days-offset-5,
.time-slots table thead td.days-offset-6,
.time-slots table thead td.days-offset-7,
.time-slots table tbody th.days-offset--1,
.time-slots table tbody th.days-offset--2,
.time-slots table tbody th.days-offset--3,
.time-slots table tbody th.days-offset--4,
.time-slots table tbody th.days-offset--5,
.time-slots table tbody th.days-offset--6,
.time-slots table tbody th.days-offset--7,
.time-slots table tbody th.days-offset-3,
.time-slots table tbody th.days-offset-4,
.time-slots table tbody th.days-offset-5,
.time-slots table tbody th.days-offset-6,
.time-slots table tbody th.days-offset-7,
.time-slots table tbody td.days-offset--1,
.time-slots table tbody td.days-offset--2,
.time-slots table tbody td.days-offset--3,
.time-slots table tbody td.days-offset--4,
.time-slots table tbody td.days-offset--5,
.time-slots table tbody td.days-offset--6,
.time-slots table tbody td.days-offset--7,
.time-slots table tbody td.days-offset-3,
.time-slots table tbody td.days-offset-4,
.time-slots table tbody td.days-offset-5,
.time-slots table tbody td.days-offset-6,
.time-slots table tbody td.days-offset-7 {
  display: none;
}
.time-slots table thead th {
  color: #3b4752;
  text-align: center;
  padding: 0.5rem 0;
  width: 32%;
}
@media (min-width: 768px) {
  .time-slots table thead th {
    width: 14.3%;
  }
}
.time-slots table thead th .arrows.disabled {
  opacity: 0.3;
}
.time-slots table thead th:first-child, .time-slots table thead th:last-child {
  width: 1%;
}
.time-slots table thead th:not(.wo-border) {
  border-top: 1px solid #efeff0;
  border-bottom: 1px solid #efeff0;
  padding: 0.5rem 0.25rem;
}
.time-slots table thead th .day {
  text-transform: uppercase;
  font-size: 16px;
  font-weight: bold;
  opacity: 0.6;
}
.time-slots table thead th .date {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 300;
}
.time-slots table thead th .la {
  color: #d2d8df;
}
.time-slots table tbody td {
  text-align: center;
  padding: 0.5rem 0.5rem;
  vertical-align: top;
}
.time-slots table tbody td .m-btn--pill {
  min-width: 80%;
  margin-bottom: 0.5rem;
  font-size: 1.15rem;
}
.time-slots table tbody td .no-slots {
  text-transform: uppercase;
  color: #82939c;
}

.mini-footer {
  font-size: 12px;
  line-height: 19px;
}
.mini-footer img {
  vertical-align: top;
}

/*
.n-sticky-footer {
  position: fixed;
  bottom: 5px;
  width: 100%;
  background: #fff;
  border-bottom-left-radius: 1.2rem;
  border-bottom-right-radius: 1.2rem;
  box-shadow: 0 2px 4px 0 rgba(0, 35, 75, 0.3);
  &:before{
    content: "";
    background: #07bad4;
    display: block;
    top: 0;
    bottom: -5px;
    left: 0;
    right: 0;
    position: absolute;
  }
}*/
.btn-circle {
  width: 45px;
  height: 45px;
  line-height: 45px;
  text-align: center;
  padding: 0;
  border-radius: 50%;
}

.btn-circle i {
  position: relative;
  top: -1px;
}

.btn-circle-sm {
  width: 35px;
  height: 35px;
  line-height: 35px;
  font-size: 0.9rem;
}

.btn-circle-lg {
  width: 55px;
  height: 55px;
  line-height: 55px;
  font-size: 1.1rem;
}

.btn-circle-xl {
  width: 70px;
  height: 70px;
  line-height: 70px;
  font-size: 1.3rem;
}

.btn-metal {
  color: #212529;
  background-color: #c4c5d5;
  border-color: #c4c5d5;
}

.btn-metal:hover {
  color: #212529;
  background-color: #aeafc5;
  border-color: #a6a8c0;
}

.btn-metal:focus,
.btn-metal.focus {
  box-shadow: 0 0 0 0.2rem rgba(196, 197, 213, 0.5);
}

.btn-metal.disabled,
.btn-metal:disabled {
  color: #212529;
  background-color: #c4c5d5;
  border-color: #c4c5d5;
}

.btn-metal:not(:disabled):not(.disabled):active,
.btn-metal:not(:disabled):not(.disabled).active,
.show > .btn-metal.dropdown-toggle {
  color: #212529;
  background-color: #a6a8c0;
  border-color: #9fa0ba;
}

.btn-metal:not(:disabled):not(.disabled):active:focus,
.btn-metal:not(:disabled):not(.disabled).active:focus,
.show > .btn-metal.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(196, 197, 213, 0.5);
}

.btn-outline-metal {
  color: #c4c5d5;
  background-color: transparent;
  background-image: none;
  border-color: #c4c5d5;
}

.btn-outline-metal:hover {
  color: #ffffff;
  background-color: #c4c5d5;
  border-color: #c4c5d5;
}

.btn-outline-metal:focus,
.btn-outline-metal.focus {
  box-shadow: 0 0 0 0.2rem rgba(196, 197, 213, 0.5);
}

.btn-outline-metal.disabled,
.btn-outline-metal:disabled {
  color: #c4c5d5;
  background-color: transparent;
}

.btn-outline-metal:not(:disabled):not(.disabled):active,
.btn-outline-metal:not(:disabled):not(.disabled).active,
.show > .btn-outline-metal.dropdown-toggle {
  color: #212529;
  background-color: #c4c5d5;
  border-color: #c4c5d5;
}

.btn-outline-metal:not(:disabled):not(.disabled):active:focus,
.btn-outline-metal:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-metal.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(196, 197, 213, 0.5);
}

.hidden {
  display: none !important;
}

.tooltip .tooltip-inner,
.bs-tooltip-right .tooltip-inner,
.bs-tooltip-auto[x-placement^=right] .tooltip-inner,
.bs-tooltip-left .tooltip-inner,
.bs-tooltip-auto[x-placement^=left] .tooltip-inner {
  background: black;
}
.tooltip .arrow::before,
.bs-tooltip-right .arrow::before,
.bs-tooltip-auto[x-placement^=right] .arrow::before,
.bs-tooltip-left .arrow::before,
.bs-tooltip-auto[x-placement^=left] .arrow::before {
  border-top-color: black;
}

/* VENDORS */
.v-c-infonet body {
  background-image: linear-gradient(to bottom, #00BCD4 0%, #0c4f75 100%);
  background-repeat: repeat-x;
}
.v-c-infonet h1 {
  text-align: center;
}
.v-c-infonet .telemedicine-large-modal h1 {
  text-transform: inherit !important;
}
.v-c-infonet .btn.m-btn--pill,
.v-c-infonet a {
  color: #0c4f75;
  text-decoration: none;
  background-color: transparent;
}
.v-c-infonet .btn-primary {
  color: #ffffff;
  background-color: #0c4f75;
  border-color: #0c4f75;
}
.v-c-infonet .btn-primary:hover {
  color: #ffffff;
  background-color: #073047;
  border-color: #073047;
}
.v-c-infonet .btn-danger {
  color: #ffffff;
  background-color: #c41f1f;
  border-color: #c41f1f;
}
.v-c-infonet .btn-danger:hover {
  color: #ffffff;
  background-color: #981818;
  border-color: #981818;
}
.v-c-infonet .btn-primary:focus,
.v-c-infonet .btn-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(196, 197, 213, 0.5);
}
.v-c-infonet .btn-primary.disabled,
.v-c-infonet .btn-primary:disabled {
  color: #212529;
  background-color: #c4c5d5;
  border-color: #c4c5d5;
}
.v-c-infonet .btn-primary:not(:disabled):not(.disabled):active,
.v-c-infonet .btn-primary:not(:disabled):not(.disabled).active,
.v-c-infonet .show > .btn-primary.dropdown-toggle {
  color: #212529;
  background-color: #a6a8c0;
  border-color: #9fa0ba;
}
.v-c-infonet .btn-primary:not(:disabled):not(.disabled):active:focus,
.v-c-infonet .btn-primary:not(:disabled):not(.disabled).active:focus,
.v-c-infonet .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(196, 197, 213, 0.5);
}
.v-c-infonet .btn-outline-primary {
  color: #ffffff;
  background-color: #0c4f75;
  background-image: none;
  border-color: #c4c5d5;
}
.v-c-infonet .btn-outline-primary:hover {
  color: #ffffff;
  background-color: #0c4f75;
  border-color: #c4c5d5;
}
.v-c-infonet .btn-outline-primary:focus,
.v-c-infonet .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(196, 197, 213, 0.5);
}
.v-c-infonet .btn-outline-primary.disabled,
.v-c-infonet .btn-outline-primary:disabled {
  color: #c4c5d5;
  background-color: transparent;
}
.v-c-infonet .btn-outline-primary:not(:disabled):not(.disabled):active,
.v-c-infonet .btn-outline-primary:not(:disabled):not(.disabled).active,
.v-c-infonet .show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #414241;
  border-color: #fff;
}
.v-c-infonet .btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.v-c-infonet .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.v-c-infonet .show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(196, 197, 213, 0.5);
}
.v-c-infonet .btn-outline-primary.dropdown-toggle,
.v-c-infonet .dropdown-menu {
  color: #414241;
  background-color: #dee9ed;
  border-color: #fff;
  border-width: 2px;
  border-radius: 0;
}
.v-c-infonet .btn-outline-primary.dropdown-toggle:hover,
.v-c-infonet .dropdown-menu:hover {
  color: #414241;
}
.v-c-infonet .form-control {
  text-align: center;
}
.v-c-infonet .m-widget1__item .pt-4 {
  padding-top: 0 !important;
}
.v-c-infonet .call,
.v-c-infonet #agora-local-video,
.v-c-infonet #agora-remote-video,
.v-c-infonet .m-portlet {
  background-color: #c2d7e1 !important;
}
.v-c-infonet #agora-local-video > div:not(.alert) > video,
.v-c-infonet #agora-remote-video > div:not(.alert) > video {
  background: #c2d7e1 !important;
}
.v-c-infonet #agora-local-video ::ng-deep video,
.v-c-infonet #agora-remote-video ::ng-deep video {
  background: #c2d7e1 !important;
}
.v-c-infonet .shadow,
.v-c-infonet .m-portlet {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.v-c-infonet .call,
.v-c-infonet .local,
.v-c-infonet .video-container,
.v-c-infonet .local-video-container,
.v-c-infonet .alert,
.v-c-infonet .m-portlet {
  border-radius: 0 !important;
}
.v-c-infonet .call-started.local-video-container {
  background: transparent !important;
  padding: 0 !important;
}
.v-c-infonet .local-video-container {
  background: #dee9ed !important;
  padding: 1.5rem !important;
}
.v-c-infonet #agora-remote-video {
  height: 50vh;
}
.v-c-infonet .device-settings > div .la {
  color: #0c4f75 !important;
  font-size: 25px !important;
}
.v-c-infonet .video-buttons .btn.m-btn--pill i {
  display: none;
}
.v-c-infonet .call-started.call.col {
  border: 8px solid #c2d7e1;
  background: #fff !important;
}
.v-c-infonet .col.call-init, .v-c-infonet .col.call-ended {
  padding: 0 1rem 2rem !important;
}
.v-c-infonet .col.call-started, .v-c-infonet .col.call-starting {
  padding: 1rem !important;
}
@media (max-width: 767.98px) {
  .v-c-infonet h1 {
    font-size: 1.5rem !important;
  }
  .v-c-infonet #agora-remote-video {
    height: 60vh;
  }
}

.v-c-medifit body {
  background-image: linear-gradient(to bottom, #00d5a1 0%, #07bad4 100%);
  background-repeat: repeat-x;
  color: #00234b;
}

.v-c-vizija body {
  background-image: linear-gradient(to bottom, #84b2bc 0%, #84b2bc 100%);
  background-repeat: repeat-x;
}
.v-c-vizija .title {
  color: #032837 !important;
}
.v-c-vizija h1 {
  text-align: center;
}
.v-c-vizija .telemedicine-large-modal h1 {
  text-transform: inherit !important;
}
.v-c-vizija .btn.m-btn--pill,
.v-c-vizija a {
  color: #032837;
  text-decoration: none;
  background-color: transparent;
}
.v-c-vizija .btn-primary {
  color: #ffffff;
  background-color: #032837;
  border-color: #032837;
}
.v-c-vizija .btn-primary:hover {
  color: #ffffff;
  background-color: #000507;
  border-color: #000507;
}
.v-c-vizija .btn-danger {
  color: #ffffff;
  background-color: #c41f1f;
  border-color: #c41f1f;
}
.v-c-vizija .btn-danger:hover {
  color: #ffffff;
  background-color: #981818;
  border-color: #981818;
}
.v-c-vizija .btn-primary:focus,
.v-c-vizija .btn-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(196, 197, 213, 0.5);
}
.v-c-vizija .btn-primary.disabled,
.v-c-vizija .btn-primary:disabled {
  color: #212529;
  background-color: #c4c5d5;
  border-color: #c4c5d5;
}
.v-c-vizija .btn-primary:not(:disabled):not(.disabled):active,
.v-c-vizija .btn-primary:not(:disabled):not(.disabled).active,
.v-c-vizija .show > .btn-primary.dropdown-toggle {
  color: #212529;
  background-color: #a6a8c0;
  border-color: #9fa0ba;
}
.v-c-vizija .btn-primary:not(:disabled):not(.disabled):active:focus,
.v-c-vizija .btn-primary:not(:disabled):not(.disabled).active:focus,
.v-c-vizija .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(196, 197, 213, 0.5);
}
.v-c-vizija .btn-outline-primary {
  color: #ffffff;
  background-color: #032837;
  background-image: none;
  border-color: #c4c5d5;
}
.v-c-vizija .btn-outline-primary:hover {
  color: #ffffff;
  background-color: #032837;
  border-color: #c4c5d5;
}
.v-c-vizija .btn-outline-primary:focus,
.v-c-vizija .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(196, 197, 213, 0.5);
}
.v-c-vizija .btn-outline-primary.disabled,
.v-c-vizija .btn-outline-primary:disabled {
  color: #c4c5d5;
  background-color: transparent;
}
.v-c-vizija .btn-outline-primary:not(:disabled):not(.disabled):active,
.v-c-vizija .btn-outline-primary:not(:disabled):not(.disabled).active,
.v-c-vizija .show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #414241;
  border-color: #fff;
}
.v-c-vizija .btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.v-c-vizija .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.v-c-vizija .show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(196, 197, 213, 0.5);
}
.v-c-vizija .btn-outline-primary.dropdown-toggle,
.v-c-vizija .dropdown-menu {
  color: #414241;
  background-color: #dee9ed;
  border-color: #fff;
  border-width: 2px;
  border-radius: 0;
}
.v-c-vizija .btn-outline-primary.dropdown-toggle:hover,
.v-c-vizija .dropdown-menu:hover {
  color: #414241;
}
.v-c-vizija .form-control {
  text-align: center;
}
.v-c-vizija .m-widget1__item .pt-4 {
  padding-top: 0 !important;
}
@media (max-width: 991.98px) {
  .v-c-vizija .mini-footer img {
    width: 120px;
  }
}
.v-c-vizija .call,
.v-c-vizija #agora-local-video,
.v-c-vizija #agora-remote-video,
.v-c-vizija .m-portlet {
  background-color: #aec3cb !important;
}
.v-c-vizija #agora-local-video > div:not(.alert) > video,
.v-c-vizija #agora-remote-video > div:not(.alert) > video {
  background: #aec3cb !important;
}
.v-c-vizija #agora-local-video ::ng-deep video,
.v-c-vizija #agora-remote-video ::ng-deep video {
  background: #aec3cb !important;
}
.v-c-vizija .shadow,
.v-c-vizija .m-portlet {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.v-c-vizija .call,
.v-c-vizija .local,
.v-c-vizija .video-container,
.v-c-vizija .local-video-container,
.v-c-vizija .alert,
.v-c-vizija .m-portlet {
  border-radius: 0 !important;
}
.v-c-vizija .call-started.local-video-container {
  background: transparent !important;
  padding: 0 !important;
}
.v-c-vizija .local-video-container {
  background: #dee9ed !important;
  padding: 1.5rem !important;
}
.v-c-vizija #agora-remote-video {
  height: 50vh;
}
.v-c-vizija .mini-footer {
  background-color: white;
  height: 100%;
}
.v-c-vizija .mini-footer::before {
  content: "";
  display: block;
  width: 100%;
  margin-bottom: 30px;
  border-top: 3px solid #032837;
  margin-left: auto;
  margin-right: auto;
}
.v-c-vizija .mini-footer p {
  color: #032837 !important;
}
.v-c-vizija .device-settings > div .la {
  color: #032837 !important;
  font-size: 25px !important;
}
.v-c-vizija .video-buttons .btn.m-btn--pill i {
  display: none;
}
.v-c-vizija .call-started.call.col {
  border: 8px solid #c2d7e1;
  background: #fff !important;
}
.v-c-vizija .col.call-init, .v-c-vizija .col.call-ended {
  padding: 0 1rem 2rem !important;
}
.v-c-vizija .col.call-started, .v-c-vizija .col.call-starting {
  padding: 1rem !important;
}
@media (max-width: 1024px) {
  .v-c-vizija .m-footer {
    padding: 0rem 0;
  }
}
@media (max-width: 767.98px) {
  .v-c-vizija h1 {
    font-size: 1.5rem !important;
  }
  .v-c-vizija #agora-remote-video {
    height: 60vh;
  }
  .v-c-vizija .mini-footer::before {
    margin-bottom: 20px;
  }
}

.v-c-audax body {
  background-image: linear-gradient(to bottom, white 0%, white 100%);
  background-repeat: repeat-x;
}
.v-c-audax .title {
  color: #102751 !important;
}
.v-c-audax h1 {
  text-align: center;
}
.v-c-audax .telemedicine-large-modal h1 {
  text-transform: inherit !important;
}
.v-c-audax .btn.m-btn--pill,
.v-c-audax a {
  color: #102751;
  text-decoration: none;
  background-color: transparent;
}
.v-c-audax .btn-primary {
  color: #ffffff;
  background-color: #102751;
  border-color: #102751;
}
.v-c-audax .btn-primary:hover {
  color: #ffffff;
  background-color: #081226;
  border-color: #081226;
}
.v-c-audax .btn-danger {
  color: #ffffff;
  background-color: #c41f1f;
  border-color: #c41f1f;
}
.v-c-audax .btn-danger:hover {
  color: #ffffff;
  background-color: #981818;
  border-color: #981818;
}
.v-c-audax .btn-primary:focus,
.v-c-audax .btn-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(196, 197, 213, 0.5);
}
.v-c-audax .btn-primary.disabled,
.v-c-audax .btn-primary:disabled {
  color: #212529;
  background-color: #c4c5d5;
  border-color: #c4c5d5;
}
.v-c-audax .btn-primary:not(:disabled):not(.disabled):active,
.v-c-audax .btn-primary:not(:disabled):not(.disabled).active,
.v-c-audax .show > .btn-primary.dropdown-toggle {
  color: #212529;
  background-color: #a6a8c0;
  border-color: #9fa0ba;
}
.v-c-audax .btn-primary:not(:disabled):not(.disabled):active:focus,
.v-c-audax .btn-primary:not(:disabled):not(.disabled).active:focus,
.v-c-audax .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(196, 197, 213, 0.5);
}
.v-c-audax .btn-outline-primary {
  color: #ffffff;
  background-color: #102751;
  background-image: none;
  border-color: #102751;
}
.v-c-audax .btn-outline-primary:hover {
  color: #ffffff;
  background-color: #102751;
  border-color: #c4c5d5;
}
.v-c-audax .btn-outline-primary:focus,
.v-c-audax .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(196, 197, 213, 0.5);
}
.v-c-audax .btn-outline-primary.disabled,
.v-c-audax .btn-outline-primary:disabled {
  color: #c4c5d5;
  background-color: transparent;
}
.v-c-audax .btn-outline-primary:not(:disabled):not(.disabled):active,
.v-c-audax .btn-outline-primary:not(:disabled):not(.disabled).active,
.v-c-audax .show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #414241;
  border-color: #fff;
}
.v-c-audax .btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.v-c-audax .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.v-c-audax .show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(196, 197, 213, 0.5);
}
.v-c-audax .btn-outline-primary.dropdown-toggle,
.v-c-audax .dropdown-menu {
  color: #414241;
  background-color: #dee9ed;
  border-color: #fff;
  border-width: 2px;
  border-radius: 0;
}
.v-c-audax .btn-outline-primary.dropdown-toggle:hover,
.v-c-audax .dropdown-menu:hover {
  color: #414241;
}
.v-c-audax .form-control {
  text-align: center;
}
.v-c-audax .m-widget1__item .pt-4 {
  padding-top: 0 !important;
}
.v-c-audax .call,
.v-c-audax #agora-local-video,
.v-c-audax #agora-remote-video,
.v-c-audax .m-portlet {
  background-color: #dfdfdf !important;
}
.v-c-audax #agora-local-video > div:not(.alert) > video,
.v-c-audax #agora-remote-video > div:not(.alert) > video {
  background: #dfdfdf !important;
}
.v-c-audax #agora-local-video ::ng-deep video,
.v-c-audax #agora-remote-video ::ng-deep video {
  background: #dfdfdf !important;
}
.v-c-audax .shadow,
.v-c-audax .m-portlet {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.v-c-audax .call,
.v-c-audax .local,
.v-c-audax .video-container,
.v-c-audax .local-video-container,
.v-c-audax .alert,
.v-c-audax .m-portlet {
  border-radius: 0 !important;
}
.v-c-audax .call-started.local-video-container {
  background: transparent !important;
  padding: 0 !important;
}
.v-c-audax .local-video-container {
  background: #dee9ed !important;
  padding: 1.5rem !important;
}
.v-c-audax #agora-remote-video {
  height: 50vh;
}
.v-c-audax .mini-footer {
  background-color: #102751;
  height: 100%;
}
.v-c-audax .mini-footer::before {
  content: "";
  display: block;
  width: 100%;
  margin-bottom: 30px;
  border-top: 3px solid #102751;
  margin-left: auto;
  margin-right: auto;
}
.v-c-audax .device-settings > div .la {
  color: #102751 !important;
  font-size: 25px !important;
}
.v-c-audax .video-buttons .btn.m-btn--pill i {
  display: none;
}
.v-c-audax .call-started.call.col {
  border: 8px solid #dfdfdf;
  background: #fff !important;
}
.v-c-audax .col.call-init, .v-c-audax .col.call-ended {
  padding: 0 1rem 2rem !important;
}
.v-c-audax .col.call-started, .v-c-audax .col.call-starting {
  padding: 1rem !important;
}
@media (max-width: 1024px) {
  .v-c-audax .m-footer {
    padding: 0rem 0;
  }
}
@media (max-width: 767.98px) {
  .v-c-audax h1 {
    font-size: 1.5rem !important;
  }
  .v-c-audax #agora-remote-video {
    height: 60vh;
  }
}

.c-c-8fHbW-vr6JvlAWiOwvZIX9k body {
  background-image: linear-gradient(to bottom, white 0%, white 100%);
  background-repeat: repeat-x;
}
.c-c-8fHbW-vr6JvlAWiOwvZIX9k .title {
  color: #9e2656 !important;
}
.c-c-8fHbW-vr6JvlAWiOwvZIX9k h1 {
  text-align: center;
}
.c-c-8fHbW-vr6JvlAWiOwvZIX9k .m--font-light {
  color: #9e2656 !important;
}
.c-c-8fHbW-vr6JvlAWiOwvZIX9k .telemedicine-large-modal h1 {
  text-transform: inherit !important;
}
.c-c-8fHbW-vr6JvlAWiOwvZIX9k .btn.m-btn--pill,
.c-c-8fHbW-vr6JvlAWiOwvZIX9k a {
  color: #9e2656;
  text-decoration: none;
  background-color: transparent;
}
.c-c-8fHbW-vr6JvlAWiOwvZIX9k .btn-primary {
  color: #ffffff;
  background-color: #9e2656;
  border-color: #9e2656;
}
.c-c-8fHbW-vr6JvlAWiOwvZIX9k .btn-primary:hover {
  color: #ffffff;
  background-color: #751c40;
  border-color: #751c40;
}
.c-c-8fHbW-vr6JvlAWiOwvZIX9k .btn-danger {
  color: #ffffff;
  background-color: #c41f1f;
  border-color: #c41f1f;
}
.c-c-8fHbW-vr6JvlAWiOwvZIX9k .btn-danger:hover {
  color: #ffffff;
  background-color: #981818;
  border-color: #981818;
}
.c-c-8fHbW-vr6JvlAWiOwvZIX9k .btn-primary:focus,
.c-c-8fHbW-vr6JvlAWiOwvZIX9k .btn-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(196, 197, 213, 0.5);
}
.c-c-8fHbW-vr6JvlAWiOwvZIX9k .btn-primary.disabled,
.c-c-8fHbW-vr6JvlAWiOwvZIX9k .btn-primary:disabled {
  color: #212529;
  background-color: #c4c5d5;
  border-color: #c4c5d5;
}
.c-c-8fHbW-vr6JvlAWiOwvZIX9k .btn-primary:not(:disabled):not(.disabled):active,
.c-c-8fHbW-vr6JvlAWiOwvZIX9k .btn-primary:not(:disabled):not(.disabled).active,
.c-c-8fHbW-vr6JvlAWiOwvZIX9k .show > .btn-primary.dropdown-toggle {
  color: #212529;
  background-color: #a6a8c0;
  border-color: #9fa0ba;
}
.c-c-8fHbW-vr6JvlAWiOwvZIX9k .btn-primary:not(:disabled):not(.disabled):active:focus,
.c-c-8fHbW-vr6JvlAWiOwvZIX9k .btn-primary:not(:disabled):not(.disabled).active:focus,
.c-c-8fHbW-vr6JvlAWiOwvZIX9k .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(196, 197, 213, 0.5);
}
.c-c-8fHbW-vr6JvlAWiOwvZIX9k .btn-outline-primary {
  color: #ffffff;
  background-color: #9e2656;
  background-image: none;
  border-color: #9e2656;
}
.c-c-8fHbW-vr6JvlAWiOwvZIX9k .btn-outline-primary:hover {
  color: #ffffff;
  background-color: #9e2656;
  border-color: #c4c5d5;
}
.c-c-8fHbW-vr6JvlAWiOwvZIX9k .btn-outline-primary:focus,
.c-c-8fHbW-vr6JvlAWiOwvZIX9k .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(196, 197, 213, 0.5);
}
.c-c-8fHbW-vr6JvlAWiOwvZIX9k .btn-outline-primary.disabled,
.c-c-8fHbW-vr6JvlAWiOwvZIX9k .btn-outline-primary:disabled {
  color: #c4c5d5;
  background-color: transparent;
}
.c-c-8fHbW-vr6JvlAWiOwvZIX9k .btn-outline-primary:not(:disabled):not(.disabled):active,
.c-c-8fHbW-vr6JvlAWiOwvZIX9k .btn-outline-primary:not(:disabled):not(.disabled).active,
.c-c-8fHbW-vr6JvlAWiOwvZIX9k .show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #414241;
  border-color: #fff;
}
.c-c-8fHbW-vr6JvlAWiOwvZIX9k .btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.c-c-8fHbW-vr6JvlAWiOwvZIX9k .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.c-c-8fHbW-vr6JvlAWiOwvZIX9k .show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(196, 197, 213, 0.5);
}
.c-c-8fHbW-vr6JvlAWiOwvZIX9k .btn-outline-primary.dropdown-toggle,
.c-c-8fHbW-vr6JvlAWiOwvZIX9k .dropdown-menu {
  color: #414241;
  background-color: #dee9ed;
  border-color: #fff;
  border-width: 2px;
  border-radius: 0;
}
.c-c-8fHbW-vr6JvlAWiOwvZIX9k .btn-outline-primary.dropdown-toggle:hover,
.c-c-8fHbW-vr6JvlAWiOwvZIX9k .dropdown-menu:hover {
  color: #414241;
}
.c-c-8fHbW-vr6JvlAWiOwvZIX9k .form-control {
  text-align: center;
}
.c-c-8fHbW-vr6JvlAWiOwvZIX9k .m-widget1__item .pt-4 {
  padding-top: 0 !important;
}
.c-c-8fHbW-vr6JvlAWiOwvZIX9k .call,
.c-c-8fHbW-vr6JvlAWiOwvZIX9k #agora-local-video,
.c-c-8fHbW-vr6JvlAWiOwvZIX9k #agora-remote-video,
.c-c-8fHbW-vr6JvlAWiOwvZIX9k .m-portlet {
  background-color: #dfdfdf !important;
}
.c-c-8fHbW-vr6JvlAWiOwvZIX9k #agora-local-video > div:not(.alert) > video,
.c-c-8fHbW-vr6JvlAWiOwvZIX9k #agora-remote-video > div:not(.alert) > video {
  background: #dfdfdf !important;
}
.c-c-8fHbW-vr6JvlAWiOwvZIX9k #agora-local-video ::ng-deep video,
.c-c-8fHbW-vr6JvlAWiOwvZIX9k #agora-remote-video ::ng-deep video {
  background: #dfdfdf !important;
}
.c-c-8fHbW-vr6JvlAWiOwvZIX9k .shadow,
.c-c-8fHbW-vr6JvlAWiOwvZIX9k .m-portlet {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.c-c-8fHbW-vr6JvlAWiOwvZIX9k .call,
.c-c-8fHbW-vr6JvlAWiOwvZIX9k .local,
.c-c-8fHbW-vr6JvlAWiOwvZIX9k .video-container,
.c-c-8fHbW-vr6JvlAWiOwvZIX9k .local-video-container,
.c-c-8fHbW-vr6JvlAWiOwvZIX9k .alert,
.c-c-8fHbW-vr6JvlAWiOwvZIX9k .m-portlet {
  border-radius: 0 !important;
}
.c-c-8fHbW-vr6JvlAWiOwvZIX9k .call-started.local-video-container {
  background: transparent !important;
  padding: 0 !important;
}
.c-c-8fHbW-vr6JvlAWiOwvZIX9k .local-video-container {
  background: #dee9ed !important;
  padding: 1.5rem !important;
}
.c-c-8fHbW-vr6JvlAWiOwvZIX9k #agora-remote-video {
  height: 50vh;
}
.c-c-8fHbW-vr6JvlAWiOwvZIX9k .mini-footer {
  background-color: #9e2656;
  height: 100%;
}
.c-c-8fHbW-vr6JvlAWiOwvZIX9k .mini-footer::before {
  content: "";
  display: block;
  width: 100%;
  margin-bottom: 30px;
  border-top: 3px solid #9e2656;
  margin-left: auto;
  margin-right: auto;
}
.c-c-8fHbW-vr6JvlAWiOwvZIX9k .device-settings > div .la {
  color: #9e2656 !important;
  font-size: 25px !important;
}
.c-c-8fHbW-vr6JvlAWiOwvZIX9k .video-buttons .btn.m-btn--pill i {
  display: none;
}
.c-c-8fHbW-vr6JvlAWiOwvZIX9k .call-started.call.col {
  border: 8px solid #dfdfdf;
  background: #fff !important;
}
.c-c-8fHbW-vr6JvlAWiOwvZIX9k .col.call-init, .c-c-8fHbW-vr6JvlAWiOwvZIX9k .col.call-ended {
  padding: 0 1rem 2rem !important;
}
.c-c-8fHbW-vr6JvlAWiOwvZIX9k .col.call-started, .c-c-8fHbW-vr6JvlAWiOwvZIX9k .col.call-starting {
  padding: 1rem !important;
}
@media (max-width: 1024px) {
  .c-c-8fHbW-vr6JvlAWiOwvZIX9k .m-footer {
    padding: 0rem 0;
  }
}
@media (max-width: 767.98px) {
  .c-c-8fHbW-vr6JvlAWiOwvZIX9k h1 {
    font-size: 1.5rem !important;
  }
  .c-c-8fHbW-vr6JvlAWiOwvZIX9k #agora-remote-video {
    height: 60vh;
  }
}

.c-c-zs6R3juIpo_JKyBhxhBLvz0 body {
  background-image: linear-gradient(to bottom, white 0%, white 100%);
  background-repeat: repeat-x;
}
.c-c-zs6R3juIpo_JKyBhxhBLvz0 .title {
  color: #0b4d4f !important;
}
.c-c-zs6R3juIpo_JKyBhxhBLvz0 .m--font-light {
  color: #0b4d4f !important;
}
.c-c-zs6R3juIpo_JKyBhxhBLvz0 h1 {
  color: #18797f !important;
  text-align: center;
}
.c-c-zs6R3juIpo_JKyBhxhBLvz0 .mf-chat-time {
  color: #0b4d4f !important;
}
.c-c-zs6R3juIpo_JKyBhxhBLvz0 .mf-chat-client {
  background-color: #ddb157 !important;
  color: #0b4d4f !important;
}
.c-c-zs6R3juIpo_JKyBhxhBLvz0 .mf-chat-client.mf-chat-bubble--hover:hover {
  background-color: #e6c581 !important;
}
.c-c-zs6R3juIpo_JKyBhxhBLvz0 .telemedicine-large-modal h1 {
  text-transform: inherit !important;
}
.c-c-zs6R3juIpo_JKyBhxhBLvz0 .text-gray-custom {
  color: #0b4d4f !important;
}
.c-c-zs6R3juIpo_JKyBhxhBLvz0 .btn.m-btn--pill {
  border-radius: 5px !important;
}
.c-c-zs6R3juIpo_JKyBhxhBLvz0 .btn-primary {
  color: #ffffff;
  background-color: #ddb157;
  border-color: #ddb157;
}
.c-c-zs6R3juIpo_JKyBhxhBLvz0 .btn-primary:hover {
  color: #ffffff;
  background-color: #052122;
  border-color: #052122;
}
.c-c-zs6R3juIpo_JKyBhxhBLvz0 .btn-danger {
  color: #ffffff;
  background-color: #c41f1f;
  border-color: #c41f1f;
}
.c-c-zs6R3juIpo_JKyBhxhBLvz0 .btn-danger:hover {
  color: #ffffff;
  background-color: #981818;
  border-color: #981818;
}
.c-c-zs6R3juIpo_JKyBhxhBLvz0 .btn-primary:focus,
.c-c-zs6R3juIpo_JKyBhxhBLvz0 .btn-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(196, 197, 213, 0.5);
}
.c-c-zs6R3juIpo_JKyBhxhBLvz0 .btn-primary.disabled,
.c-c-zs6R3juIpo_JKyBhxhBLvz0 .btn-primary:disabled {
  color: #212529;
  background-color: #c4c5d5;
  border-color: #c4c5d5;
}
.c-c-zs6R3juIpo_JKyBhxhBLvz0 .btn-primary:not(:disabled):not(.disabled):active,
.c-c-zs6R3juIpo_JKyBhxhBLvz0 .btn-primary:not(:disabled):not(.disabled).active,
.c-c-zs6R3juIpo_JKyBhxhBLvz0 .show > .btn-primary.dropdown-toggle {
  color: #212529;
  background-color: #a6a8c0;
  border-color: #9fa0ba;
}
.c-c-zs6R3juIpo_JKyBhxhBLvz0 .btn-primary:not(:disabled):not(.disabled):active:focus,
.c-c-zs6R3juIpo_JKyBhxhBLvz0 .btn-primary:not(:disabled):not(.disabled).active:focus,
.c-c-zs6R3juIpo_JKyBhxhBLvz0 .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(196, 197, 213, 0.5);
}
.c-c-zs6R3juIpo_JKyBhxhBLvz0 .btn-outline-primary {
  color: #ffffff;
  background-color: #ddb157;
  border-color: #ddb157;
}
.c-c-zs6R3juIpo_JKyBhxhBLvz0 .btn-outline-primary:hover {
  color: #ffffff;
  background-color: #0b4d4f;
  border-color: #0b4d4f;
}
.c-c-zs6R3juIpo_JKyBhxhBLvz0 .btn-outline-primary:focus,
.c-c-zs6R3juIpo_JKyBhxhBLvz0 .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(196, 197, 213, 0.5);
}
.c-c-zs6R3juIpo_JKyBhxhBLvz0 .btn-outline-primary.disabled,
.c-c-zs6R3juIpo_JKyBhxhBLvz0 .btn-outline-primary:disabled {
  color: #c4c5d5;
  background-color: transparent;
}
.c-c-zs6R3juIpo_JKyBhxhBLvz0 .btn-outline-primary:not(:disabled):not(.disabled):active,
.c-c-zs6R3juIpo_JKyBhxhBLvz0 .btn-outline-primary:not(:disabled):not(.disabled).active,
.c-c-zs6R3juIpo_JKyBhxhBLvz0 .show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #414241;
  border-color: #fff;
}
.c-c-zs6R3juIpo_JKyBhxhBLvz0 .btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.c-c-zs6R3juIpo_JKyBhxhBLvz0 .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.c-c-zs6R3juIpo_JKyBhxhBLvz0 .show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(196, 197, 213, 0.5);
  color: #fff !important;
}
.c-c-zs6R3juIpo_JKyBhxhBLvz0 .btn-outline-primary.dropdown-toggle,
.c-c-zs6R3juIpo_JKyBhxhBLvz0 .dropdown-menu {
  color: #414241;
  background-color: #dee9ed;
  border-color: #fff;
  border-width: 2px;
  border-radius: 0;
}
.c-c-zs6R3juIpo_JKyBhxhBLvz0 .btn-outline-primary.dropdown-toggle:hover,
.c-c-zs6R3juIpo_JKyBhxhBLvz0 .dropdown-menu:hover {
  color: #414241;
}
.c-c-zs6R3juIpo_JKyBhxhBLvz0 .form-control {
  text-align: center;
}
.c-c-zs6R3juIpo_JKyBhxhBLvz0 .m-widget1__item .pt-4 {
  padding-top: 0 !important;
}
.c-c-zs6R3juIpo_JKyBhxhBLvz0 .call,
.c-c-zs6R3juIpo_JKyBhxhBLvz0 #agora-local-video,
.c-c-zs6R3juIpo_JKyBhxhBLvz0 #agora-remote-video,
.c-c-zs6R3juIpo_JKyBhxhBLvz0 .m-portlet {
  background-color: #1d777d63 !important;
}
.c-c-zs6R3juIpo_JKyBhxhBLvz0 #agora-local-video > div:not(.alert) > video,
.c-c-zs6R3juIpo_JKyBhxhBLvz0 #agora-remote-video > div:not(.alert) > video {
  background: #1d777d63 !important;
}
.c-c-zs6R3juIpo_JKyBhxhBLvz0 #agora-local-video ::ng-deep video,
.c-c-zs6R3juIpo_JKyBhxhBLvz0 #agora-remote-video ::ng-deep video {
  background: #1d777d63 !important;
}
.c-c-zs6R3juIpo_JKyBhxhBLvz0 .shadow,
.c-c-zs6R3juIpo_JKyBhxhBLvz0 .m-portlet {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.c-c-zs6R3juIpo_JKyBhxhBLvz0 .call,
.c-c-zs6R3juIpo_JKyBhxhBLvz0 .local,
.c-c-zs6R3juIpo_JKyBhxhBLvz0 .video-container,
.c-c-zs6R3juIpo_JKyBhxhBLvz0 .local-video-container,
.c-c-zs6R3juIpo_JKyBhxhBLvz0 .alert,
.c-c-zs6R3juIpo_JKyBhxhBLvz0 .m-portlet {
  border-radius: 0 !important;
}
.c-c-zs6R3juIpo_JKyBhxhBLvz0 .call-started.local-video-container {
  background: transparent !important;
  padding: 0 !important;
}
.c-c-zs6R3juIpo_JKyBhxhBLvz0 .local-video-container {
  background: #dee9ed !important;
  padding: 1.5rem !important;
}
.c-c-zs6R3juIpo_JKyBhxhBLvz0 #agora-remote-video {
  height: 50vh;
}
.c-c-zs6R3juIpo_JKyBhxhBLvz0 .mini-footer {
  background-color: #dbaf5663;
  height: 100%;
}
.c-c-zs6R3juIpo_JKyBhxhBLvz0 .mini-footer::before {
  content: "";
  display: block;
  width: 100%;
  margin-bottom: 30px;
  border-top: 3px solid #dbaf5663;
  margin-left: auto;
  margin-right: auto;
}
.c-c-zs6R3juIpo_JKyBhxhBLvz0 .device-settings > div .la {
  color: #0b4d4f !important;
  font-size: 25px !important;
}
.c-c-zs6R3juIpo_JKyBhxhBLvz0 .dropdown-toggle.btn-outline-primary:hover {
  color: #0b4d4f !important;
}
.c-c-zs6R3juIpo_JKyBhxhBLvz0 .video-buttons .btn.m-btn--pill i {
  display: none;
}
.c-c-zs6R3juIpo_JKyBhxhBLvz0 .call-started.call.col {
  border: 8px solid #1d777d63;
  background: #fff !important;
}
.c-c-zs6R3juIpo_JKyBhxhBLvz0 .col.call-init, .c-c-zs6R3juIpo_JKyBhxhBLvz0 .col.call-ended {
  padding: 0 1rem 2rem !important;
}
.c-c-zs6R3juIpo_JKyBhxhBLvz0 .col.call-started, .c-c-zs6R3juIpo_JKyBhxhBLvz0 .col.call-starting {
  padding: 1rem !important;
}
@media (max-width: 1024px) {
  .c-c-zs6R3juIpo_JKyBhxhBLvz0 .m-footer {
    padding: 0rem 0;
  }
}
@media (max-width: 767.98px) {
  .c-c-zs6R3juIpo_JKyBhxhBLvz0 h1 {
    font-size: 1.5rem !important;
  }
  .c-c-zs6R3juIpo_JKyBhxhBLvz0 #agora-remote-video {
    height: 60vh;
  }
}

.b-c7 body {
  background-image: linear-gradient(to bottom, white 0%, white 100%);
  background-repeat: repeat-x;
}
.b-c7 .title {
  color: #93C9F6 !important;
}
.b-c7 h1 {
  text-align: center;
  color: #93C9F6 !important;
  font-weight: bold !important;
}
.b-c7 h5 {
  color: #575962 !important;
}
.b-c7 .telemedicine-large-modal h1 {
  text-transform: inherit !important;
}
.b-c7 .btn.m-btn--pill,
.b-c7 a {
  color: #93C9F6;
  text-decoration: none;
  background-color: transparent;
}
.b-c7 .btn-primary {
  color: #ffffff;
  background-color: #93C9F6;
  border-color: #93C9F6;
}
.b-c7 .btn-primary:hover,
.b-c7 .btn.m-btn--pill:focus {
  color: #ffffff;
  background-color: #64b1f2;
  border-color: #64b1f2;
}
.b-c7 .btn-danger {
  color: #ffffff;
  background-color: #c41f1f;
  border-color: #c41f1f;
}
.b-c7 .btn-danger:hover {
  color: #ffffff;
  background-color: #981818;
  border-color: #981818;
}
.b-c7 .btn-primary:focus,
.b-c7 .btn-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(196, 197, 213, 0.5);
}
.b-c7 .btn-primary.disabled,
.b-c7 .btn-primary:disabled {
  color: #212529;
  background-color: #c4c5d5;
  border-color: #c4c5d5;
}
.b-c7 .btn-primary:not(:disabled):not(.disabled):active,
.b-c7 .btn-primary:not(:disabled):not(.disabled).active,
.b-c7 .show > .btn-primary.dropdown-toggle {
  color: #212529;
  background-color: #a6a8c0;
  border-color: #9fa0ba;
}
.b-c7 .btn-primary:not(:disabled):not(.disabled):active:focus,
.b-c7 .btn-primary:not(:disabled):not(.disabled).active:focus,
.b-c7 .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(196, 197, 213, 0.5);
}
.b-c7 .btn-outline-primary {
  color: #ffffff;
  background-color: #93C9F6;
  background-image: none;
  border-color: #93C9F6;
}
.b-c7 .btn-outline-primary:hover {
  color: #ffffff;
  background-color: #93C9F6;
  border-color: #c4c5d5;
}
.b-c7 .btn-outline-primary:focus,
.b-c7 .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(196, 197, 213, 0.5);
}
.b-c7 .btn-outline-primary.disabled,
.b-c7 .btn-outline-primary:disabled {
  color: #c4c5d5;
  background-color: transparent;
}
.b-c7 .btn-outline-primary:not(:disabled):not(.disabled):active,
.b-c7 .btn-outline-primary:not(:disabled):not(.disabled).active,
.b-c7 .show > .btn-outline-primary.dropdown-toggle {
  color: #93C9F6;
  background-color: #414241;
  border-color: #93C9F6;
}
.b-c7 .btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.b-c7 .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.b-c7 .show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(196, 197, 213, 0.5);
}
.b-c7 .btn-outline-primary.dropdown-toggle,
.b-c7 .dropdown-menu {
  color: #414241;
  background-color: #93C9F6;
  border-color: #93C9F6;
  border-width: 2px;
  border-radius: 0;
}
.b-c7 .btn-outline-primary.dropdown-toggle:hover,
.b-c7 .dropdown-menu:hover {
  color: #414241;
}
.b-c7 .form-control {
  text-align: center;
}
.b-c7 .m-widget1__item .pt-4 {
  padding-top: 0 !important;
}
.b-c7 .call,
.b-c7 #agora-local-video,
.b-c7 #agora-remote-video,
.b-c7 .m-portlet {
  background-color: #efefef !important;
}
.b-c7 #agora-local-video > div:not(.alert) > video,
.b-c7 #agora-remote-video > div:not(.alert) > video {
  background: #efefef !important;
}
.b-c7 #agora-local-video ::ng-deep video,
.b-c7 #agora-remote-video ::ng-deep video {
  background: #efefef !important;
}
.b-c7 .shadow,
.b-c7 .m-portlet {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.b-c7 .call,
.b-c7 .local,
.b-c7 .video-container,
.b-c7 .local-video-container,
.b-c7 .alert,
.b-c7 .m-portlet {
  border-radius: 0 !important;
}
.b-c7 .call-started.local-video-container {
  background: transparent !important;
  padding: 0 !important;
}
.b-c7 .local-video-container {
  background: #fff !important;
  padding: 1.5rem !important;
}
.b-c7 #agora-remote-video {
  height: 50vh;
}
.b-c7 .mini-footer {
  background-color: #93C9F6;
  height: 100%;
  padding-bottom: 10px;
}
.b-c7 .mini-footer::before {
  content: "";
  display: block;
  width: 100%;
  margin-bottom: 30px;
  border-top: 3px solid #93C9F6;
  margin-left: auto;
  margin-right: auto;
}
.b-c7 .device-settings > div .la {
  color: #93C9F6 !important;
  font-size: 25px !important;
}
.b-c7 .video-buttons .btn.m-btn--pill i {
  display: none;
}
.b-c7 .call-started.call.col {
  border: 8px solid #efefef;
  background: #fff !important;
}
.b-c7 .col.call-init, .b-c7 .col.call-ended {
  padding: 0 1rem 2rem !important;
}
.b-c7 .col.call-started, .b-c7 .col.call-starting {
  padding: 1rem !important;
}
.b-c7 .m-portlet--primary {
  background-color: #93C9F6 !important;
}
.b-c7 .mf-chat-client {
  background-color: #93C9F6 !important;
}
@media (max-width: 1024px) {
  .b-c7 .m-footer {
    padding: 0rem 0;
  }
}
@media (max-width: 767.98px) {
  .b-c7 h1 {
    font-size: 1.5rem !important;
  }
  .b-c7 #agora-remote-video {
    height: 60vh;
  }
}

@font-face {
  font-family: "DINPro";
  src: url("assets/app/media/fonts/DINPro/DINPro.ttf") format("truetype");
}
.b-c24 body {
  font-family: "DINPro", sans-serif !important;
  font-weight: lighter !important;
  background-image: linear-gradient(to bottom, white 0%, white 100%);
  background-repeat: repeat-x;
  background-image: url("assets/app/media/img/prva-bg.jpg");
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: auto;
}
.b-c24 .title {
  color: #ec180b !important;
}
.b-c24 h1 {
  text-transform: uppercase;
  font-weight: lighter !important;
  text-align: center;
  color: #5b5b5b !important;
}
.b-c24 h5 {
  color: #5b5b5b !important;
}
.b-c24 .telemedicine-large-modal h1 {
  text-transform: inherit !important;
}
.b-c24 .btn.m-btn--pill {
  text-transform: uppercase;
}
.b-c24 .form-control,
.b-c24 .btn.m-btn--pill {
  padding: 0.85rem 0.75rem;
  padding-left: 1rem !important;
  padding-right: 1rem !important;
  border-radius: 0 !important;
}
.b-c24 a {
  color: #ec180b;
  text-decoration: none;
  background-color: transparent;
}
.b-c24 .btn-primary {
  color: #ffffff;
  background-color: #ec180b;
  border-color: #ec180b;
}
.b-c24 .btn-primary:hover,
.b-c24 .btn.m-btn--pill:focus {
  color: #ffffff;
  background-color: #bb1309;
  border-color: #bb1309;
}
.b-c24 .btn-danger {
  color: #ffffff;
  background-color: #c41f1f;
  border-color: #c41f1f;
}
.b-c24 .btn-danger:hover {
  color: #ffffff;
  background-color: #981818;
  border-color: #981818;
}
.b-c24 .btn-primary:focus,
.b-c24 .btn-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(196, 197, 213, 0.5);
}
.b-c24 .btn-primary.disabled,
.b-c24 .btn-primary:disabled {
  color: #212529;
  background-color: #c4c5d5;
  border-color: #c4c5d5;
}
.b-c24 .btn-primary:not(:disabled):not(.disabled):active,
.b-c24 .btn-primary:not(:disabled):not(.disabled).active,
.b-c24 .show > .btn-primary.dropdown-toggle {
  color: #212529;
  background-color: #a6a8c0;
  border-color: #9fa0ba;
}
.b-c24 .btn-primary:not(:disabled):not(.disabled):active:focus,
.b-c24 .btn-primary:not(:disabled):not(.disabled).active:focus,
.b-c24 .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(196, 197, 213, 0.5);
}
.b-c24 .btn-outline-primary {
  color: #ffffff;
  background-color: #ec180b;
  background-image: none;
  border-color: #ec180b;
}
.b-c24 .btn-outline-primary:hover {
  color: #ffffff;
  background-color: #ec180b;
  border-color: #c4c5d5;
}
.b-c24 .btn-outline-primary:focus,
.b-c24 .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(196, 197, 213, 0.5);
}
.b-c24 .btn-outline-primary.disabled,
.b-c24 .btn-outline-primary:disabled {
  color: #c4c5d5;
  background-color: transparent;
}
.b-c24 .btn-outline-primary:not(:disabled):not(.disabled):active,
.b-c24 .btn-outline-primary:not(:disabled):not(.disabled).active,
.b-c24 .show > .btn-outline-primary.dropdown-toggle {
  color: #5b5b5b !important;
  background-color: #ffffff;
  border-color: #5b5b5b;
}
.b-c24 .btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.b-c24 .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.b-c24 .show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(196, 197, 213, 0.5);
}
.b-c24 .btn-outline-primary.dropdown-toggle,
.b-c24 .dropdown-menu {
  color: #5b5b5b;
  background-color: #ffffff;
  border-color: #5b5b5b;
  border-width: 2px;
  border-radius: 0;
}
.b-c24 .btn-outline-primary.dropdown-toggle:hover,
.b-c24 .dropdown-menu:hover {
  color: #5b5b5b !important;
}
.b-c24 telemedicine-case-info .m-portlet .m-portlet__head {
  width: 90%;
  margin: 0 auto;
  padding-left: 0px;
}
.b-c24 telemedicine-case-info .m-portlet .m-portlet__head-text {
  text-transform: uppercase;
}
.b-c24 telemedicine-communication .m-portlet {
  background-color: transparent !important;
  border: 1px #000000 solid !important;
}
.b-c24 telemedicine-communication .m-portlet .m-portlet__head {
  width: 90%;
  margin: 0 auto;
  padding-left: 0px;
}
.b-c24 telemedicine-communication .m-portlet .m-portlet__head-text {
  text-transform: uppercase;
}
.b-c24 telemedicine-wizard a .m-portlet.rounded:not(.m-portlet--primary) {
  background-color: transparent !important;
  border: 1px #ec180b solid !important;
}
.b-c24 .form-control {
  text-align: center;
}
.b-c24 .m-widget1__item .pt-4 {
  padding-top: 0 !important;
}
.b-c24 .call,
.b-c24 #agora-local-video,
.b-c24 #agora-remote-video,
.b-c24 .m-portlet {
  background-color: #f2eee9 !important;
}
.b-c24 #agora-local-video > div:not(.alert) > video,
.b-c24 #agora-remote-video > div:not(.alert) > video {
  background: #f2eee9 !important;
}
.b-c24 #agora-local-video ::ng-deep video,
.b-c24 #agora-remote-video ::ng-deep video {
  background: #f2eee9 !important;
}
.b-c24 .shadow,
.b-c24 .m-portlet {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.b-c24 .call,
.b-c24 .local,
.b-c24 .video-container,
.b-c24 .local-video-container,
.b-c24 .alert,
.b-c24 .m-portlet {
  border-radius: 0 !important;
}
.b-c24 .call-started.local-video-container {
  background: transparent !important;
  padding: 0 !important;
}
.b-c24 .local-video-container {
  background: #fff !important;
  padding: 1.5rem !important;
}
.b-c24 #agora-remote-video {
  height: 50vh;
}
.b-c24 .mini-footer {
  background-color: transparent;
  height: 100%;
  padding-bottom: 20px;
}
.b-c24 .mini-footer::before {
  content: "";
  display: block;
  width: 90%;
  margin-bottom: 30px;
  border-top: 1px solid #ec180b;
  margin-left: auto;
  margin-right: auto;
}
.b-c24 .device-settings > div .la {
  color: #5b5b5b !important;
  font-size: 25px !important;
}
.b-c24 .video-buttons .btn.m-btn--pill i {
  display: none;
}
.b-c24 .call-started.call.col {
  border: 8px solid #f2eee9;
  background: #fff !important;
}
.b-c24 .col.call-init, .b-c24 .col.call-ended {
  padding: 0 1rem 2rem !important;
}
.b-c24 .col.call-started, .b-c24 .col.call-starting {
  padding: 1rem !important;
}
.b-c24 .mf-telemed-wrapper > h1.title {
  color: #5b5b5b !important;
  font-weight: lighter !important;
}
.b-c24 .m-portlet--primary {
  background-color: #ec180b !important;
}
.b-c24 .mf-chat-client {
  background-color: #ec180b !important;
}
@media (max-width: 1024px) {
  .b-c24 .m-footer {
    padding: 0rem 0;
  }
}
@media (max-width: 767.98px) {
  .b-c24 h1 {
    font-size: 1.5rem !important;
  }
  .b-c24 #agora-remote-video {
    height: 60vh;
  }
}

@font-face {
  font-family: "CocoSharp";
  src: url("assets/app/media/fonts/Coco_sharp/Coco-Sharp-Regular.ttf") format("truetype");
}
.b-c23 body {
  font-family: "CocoSharp", sans-serif !important;
  font-weight: lighter !important;
  background-image: linear-gradient(to bottom, white 0%, white 100%);
  background-repeat: repeat-x;
  background-image: url("assets/app/media/img/vita-bg.jpg");
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: auto;
}
.b-c23 ::placeholder {
  color: #b0b6bc;
  opacity: 1;
}
.b-c23 ::-ms-input-placeholder {
  color: #b0b6bc;
}
.b-c23 app-auth form .m-portlet .m-portlet__body {
  background: #ffffff;
  box-shadow: 0px 0px 9px 3px #00000021 !important;
}
.b-c23 app-auth form .m-widget1__item.pb-4 {
  padding-bottom: 0 !important;
}
.b-c23 .title {
  color: #28007D !important;
}
.b-c23 h1 {
  text-transform: uppercase;
  font-weight: lighter !important;
  text-align: center;
  color: #5b5b5b !important;
}
.b-c23 h5 {
  color: #5b5b5b !important;
}
.b-c23 .telemedicine-large-modal h1 {
  text-transform: inherit !important;
}
.b-c23 .btn.m-btn--pill {
  text-transform: uppercase;
}
.b-c23 .form-control,
.b-c23 .btn.m-btn--pill {
  font-weight: normal;
  padding: 0.85rem 0.75rem;
  padding-left: 1rem !important;
  padding-right: 1rem !important;
  border-radius: 0 !important;
}
.b-c23 a {
  color: #28007D;
  text-decoration: none;
  background-color: transparent;
}
.b-c23 .btn-primary {
  color: #ffffff;
  background-color: #28007D;
  border-color: #28007D;
}
.b-c23 .btn-primary:hover,
.b-c23 .btn.m-btn--pill:focus {
  color: #ffffff;
  background-color: #18004a;
  border-color: #18004a;
}
.b-c23 .btn-danger {
  color: #ffffff;
  background-color: #c41f1f;
  border-color: #c41f1f;
}
.b-c23 .btn-danger:hover {
  color: #ffffff;
  background-color: #981818;
  border-color: #981818;
}
.b-c23 .btn-primary:focus,
.b-c23 .btn-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(196, 197, 213, 0.5);
}
.b-c23 .btn-primary.disabled,
.b-c23 .btn-primary:disabled {
  color: #212529;
  background-color: #c4c5d5;
  border-color: #c4c5d5;
}
.b-c23 .btn-primary:not(:disabled):not(.disabled):active,
.b-c23 .btn-primary:not(:disabled):not(.disabled).active,
.b-c23 .show > .btn-primary.dropdown-toggle {
  color: #212529;
  background-color: #a6a8c0;
  border-color: #9fa0ba;
}
.b-c23 .btn-primary:not(:disabled):not(.disabled):active:focus,
.b-c23 .btn-primary:not(:disabled):not(.disabled).active:focus,
.b-c23 .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(196, 197, 213, 0.5);
}
.b-c23 .btn-outline-primary {
  color: #ffffff;
  background-color: #28007D;
  background-image: none;
  border-color: #28007D;
}
.b-c23 .btn-outline-primary:hover {
  color: #ffffff;
  background-color: #28007D;
  border-color: #c4c5d5;
}
.b-c23 .btn-outline-primary:focus,
.b-c23 .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(196, 197, 213, 0.5);
}
.b-c23 .btn-outline-primary.disabled,
.b-c23 .btn-outline-primary:disabled {
  color: #c4c5d5;
  background-color: transparent;
}
.b-c23 .btn-outline-primary:not(:disabled):not(.disabled):active,
.b-c23 .btn-outline-primary:not(:disabled):not(.disabled).active,
.b-c23 .show > .btn-outline-primary.dropdown-toggle {
  color: #5b5b5b !important;
  background-color: #ffffff;
  border-color: #5b5b5b;
}
.b-c23 .btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.b-c23 .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.b-c23 .show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(196, 197, 213, 0.5);
}
.b-c23 .btn-outline-primary.dropdown-toggle,
.b-c23 .dropdown-menu {
  color: #5b5b5b;
  background-color: #ffffff;
  border-color: #5b5b5b;
  border-width: 2px;
  border-radius: 0;
}
.b-c23 .btn-outline-primary.dropdown-toggle:hover,
.b-c23 .dropdown-menu:hover {
  color: #5b5b5b !important;
}
.b-c23 app-telemedicine h1 {
  text-transform: initial !important;
}
.b-c23 telemedicine-case-info .m-portlet {
  background: #ffffff !important;
  box-shadow: 0px 0px 9px 3px #00000021 !important;
}
.b-c23 telemedicine-case-info .m-portlet .m-portlet__head {
  width: 90%;
  margin: 0 auto;
  padding-left: 0px;
  background: #ffffff;
}
.b-c23 telemedicine-case-info .m-portlet .m-portlet__head-text {
  text-transform: uppercase;
}
.b-c23 telemedicine-communication .m-portlet {
  background-color: transparent !important;
  border: 1px #000000 solid !important;
}
.b-c23 telemedicine-communication .m-portlet.openMobile {
  background: #ffffff !important;
}
.b-c23 telemedicine-communication .m-portlet .m-portlet__head {
  background-color: transparent !important;
  width: 90%;
  margin: 0 auto;
  padding-left: 0px;
}
.b-c23 telemedicine-communication .m-portlet .m-portlet__head-text {
  text-transform: uppercase;
}
.b-c23 telemedicine-communication .m-portlet .m-portlet__body {
  background-color: transparent !important;
}
.b-c23 telemedicine-wizard a .m-portlet.rounded:not(.m-portlet--primary) {
  background-color: transparent !important;
  border: 1px #28007D solid !important;
}
.b-c23 telemedicine-wizard a.text-white .m-portlet__body {
  background-color: #28007D !important;
}
.b-c23 .form-control {
  text-align: center;
}
.b-c23 .m-widget1__item .pt-4 {
  padding-top: 0 !important;
}
.b-c23 .shadow,
.b-c23 .m-portlet {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.b-c23 .call,
.b-c23 .local,
.b-c23 .video-container,
.b-c23 .local-video-container,
.b-c23 .alert,
.b-c23 .m-portlet {
  border-radius: 0 !important;
}
.b-c23 .call-started.local-video-container {
  background: transparent !important;
  padding: 0 !important;
}
.b-c23 .local-video-container {
  background: #fff !important;
  padding: 1.5rem !important;
}
.b-c23 #agora-remote-video {
  height: 50vh;
}
.b-c23 .mini-footer {
  background-color: transparent;
  height: 100%;
  padding-bottom: 20px;
}
.b-c23 .mini-footer::before {
  content: "";
  display: block;
  width: 90%;
  margin-bottom: 30px;
  border-top: 1px solid #28007D;
  margin-left: auto;
  margin-right: auto;
}
.b-c23 .device-settings > div .la {
  color: #5b5b5b !important;
  font-size: 25px !important;
}
.b-c23 .video-buttons .btn.m-btn--pill i {
  display: none;
}
.b-c23 .call-started.call.col {
  border: 8px solid #f2eee9;
  background: #fff !important;
}
.b-c23 .col.call-init, .b-c23 .col.call-ended {
  padding: 1rem !important;
}
.b-c23 .col.call-started, .b-c23 .col.call-starting {
  padding: 1rem !important;
}
.b-c23 .mf-telemed-wrapper > h1.title {
  color: #5b5b5b !important;
  font-weight: lighter !important;
}
.b-c23 .m-portlet--primary {
  background-color: #28007D !important;
}
.b-c23 .mf-chat-client {
  background-color: #28007D !important;
}
@media (max-width: 1024px) {
  .b-c23 .m-footer {
    padding: 0rem 0;
  }
}
@media (max-width: 767.98px) {
  .b-c23 h1 {
    font-size: 1.5rem !important;
  }
  .b-c23 #agora-remote-video {
    height: 60vh;
  }
  .b-c23 footer img {
    margin-bottom: 10px;
  }
}

.c-c-Tk5Sm8bhhuOk9UCTyLP1jKA,
.v-c-medical {
  @import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap");
}
.c-c-Tk5Sm8bhhuOk9UCTyLP1jKA body,
.v-c-medical body {
  font-family: "Open Sans", sans-serif;
  background-image: linear-gradient(to bottom, white 0%, white 100%);
  background-repeat: repeat-x;
}
.c-c-Tk5Sm8bhhuOk9UCTyLP1jKA .title,
.v-c-medical .title {
  color: #19b2d0 !important;
}
.c-c-Tk5Sm8bhhuOk9UCTyLP1jKA h1,
.v-c-medical h1 {
  text-align: center;
  color: #19b2d0 !important;
  font-weight: bold !important;
}
.c-c-Tk5Sm8bhhuOk9UCTyLP1jKA h5,
.v-c-medical h5 {
  color: #575962 !important;
}
.c-c-Tk5Sm8bhhuOk9UCTyLP1jKA .telemedicine-large-modal h1,
.v-c-medical .telemedicine-large-modal h1 {
  text-transform: inherit !important;
}
.c-c-Tk5Sm8bhhuOk9UCTyLP1jKA .btn.m-btn--pill,
.v-c-medical .btn.m-btn--pill {
  font-weight: 800 !important;
  text-transform: uppercase;
}
.c-c-Tk5Sm8bhhuOk9UCTyLP1jKA .form-control,
.c-c-Tk5Sm8bhhuOk9UCTyLP1jKA .btn.m-btn--pill,
.v-c-medical .form-control,
.v-c-medical .btn.m-btn--pill {
  padding: 0.85rem 0.75rem;
  padding-left: 1rem !important;
  padding-right: 1rem !important;
  border-radius: 3px !important;
}
.c-c-Tk5Sm8bhhuOk9UCTyLP1jKA a,
.v-c-medical a {
  color: #19b2d0;
  text-decoration: none;
  background-color: transparent;
}
.c-c-Tk5Sm8bhhuOk9UCTyLP1jKA .btn-primary,
.v-c-medical .btn-primary {
  color: #ffffff;
  background-color: #19b2d0;
  border-color: #19b2d0;
}
.c-c-Tk5Sm8bhhuOk9UCTyLP1jKA .btn-primary:hover,
.c-c-Tk5Sm8bhhuOk9UCTyLP1jKA .btn.m-btn--pill:focus,
.v-c-medical .btn-primary:hover,
.v-c-medical .btn.m-btn--pill:focus {
  color: #ffffff;
  background-color: #148ba2;
  border-color: #148ba2;
}
.c-c-Tk5Sm8bhhuOk9UCTyLP1jKA .btn-danger,
.v-c-medical .btn-danger {
  color: #ffffff;
  background-color: #c41f1f;
  border-color: #c41f1f;
}
.c-c-Tk5Sm8bhhuOk9UCTyLP1jKA .btn-danger:hover,
.v-c-medical .btn-danger:hover {
  color: #ffffff;
  background-color: #981818;
  border-color: #981818;
}
.c-c-Tk5Sm8bhhuOk9UCTyLP1jKA .btn-primary:focus,
.c-c-Tk5Sm8bhhuOk9UCTyLP1jKA .btn-primary.focus,
.v-c-medical .btn-primary:focus,
.v-c-medical .btn-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(196, 197, 213, 0.5);
}
.c-c-Tk5Sm8bhhuOk9UCTyLP1jKA .btn-primary.disabled,
.c-c-Tk5Sm8bhhuOk9UCTyLP1jKA .btn-primary:disabled,
.v-c-medical .btn-primary.disabled,
.v-c-medical .btn-primary:disabled {
  color: #212529;
  background-color: #c4c5d5;
  border-color: #c4c5d5;
}
.c-c-Tk5Sm8bhhuOk9UCTyLP1jKA .btn-primary:not(:disabled):not(.disabled):active,
.c-c-Tk5Sm8bhhuOk9UCTyLP1jKA .btn-primary:not(:disabled):not(.disabled).active,
.c-c-Tk5Sm8bhhuOk9UCTyLP1jKA .show > .btn-primary.dropdown-toggle,
.v-c-medical .btn-primary:not(:disabled):not(.disabled):active,
.v-c-medical .btn-primary:not(:disabled):not(.disabled).active,
.v-c-medical .show > .btn-primary.dropdown-toggle {
  color: #212529;
  background-color: #a6a8c0;
  border-color: #9fa0ba;
}
.c-c-Tk5Sm8bhhuOk9UCTyLP1jKA .btn-primary:not(:disabled):not(.disabled):active:focus,
.c-c-Tk5Sm8bhhuOk9UCTyLP1jKA .btn-primary:not(:disabled):not(.disabled).active:focus,
.c-c-Tk5Sm8bhhuOk9UCTyLP1jKA .show > .btn-primary.dropdown-toggle:focus,
.v-c-medical .btn-primary:not(:disabled):not(.disabled):active:focus,
.v-c-medical .btn-primary:not(:disabled):not(.disabled).active:focus,
.v-c-medical .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(196, 197, 213, 0.5);
}
.c-c-Tk5Sm8bhhuOk9UCTyLP1jKA .btn-outline-primary,
.v-c-medical .btn-outline-primary {
  color: #ffffff;
  background-color: #19b2d0;
  background-image: none;
  border-color: #19b2d0;
}
.c-c-Tk5Sm8bhhuOk9UCTyLP1jKA .btn-outline-primary:hover,
.v-c-medical .btn-outline-primary:hover {
  color: #ffffff;
  background-color: #19b2d0;
  border-color: #c4c5d5;
}
.c-c-Tk5Sm8bhhuOk9UCTyLP1jKA .btn-outline-primary:focus,
.c-c-Tk5Sm8bhhuOk9UCTyLP1jKA .btn-outline-primary.focus,
.v-c-medical .btn-outline-primary:focus,
.v-c-medical .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(196, 197, 213, 0.5);
}
.c-c-Tk5Sm8bhhuOk9UCTyLP1jKA .btn-outline-primary.disabled,
.c-c-Tk5Sm8bhhuOk9UCTyLP1jKA .btn-outline-primary:disabled,
.v-c-medical .btn-outline-primary.disabled,
.v-c-medical .btn-outline-primary:disabled {
  color: #c4c5d5;
  background-color: transparent;
}
.c-c-Tk5Sm8bhhuOk9UCTyLP1jKA .btn-outline-primary:not(:disabled):not(.disabled):active,
.c-c-Tk5Sm8bhhuOk9UCTyLP1jKA .btn-outline-primary:not(:disabled):not(.disabled).active,
.c-c-Tk5Sm8bhhuOk9UCTyLP1jKA .show > .btn-outline-primary.dropdown-toggle,
.v-c-medical .btn-outline-primary:not(:disabled):not(.disabled):active,
.v-c-medical .btn-outline-primary:not(:disabled):not(.disabled).active,
.v-c-medical .show > .btn-outline-primary.dropdown-toggle {
  color: #19b2d0 !important;
  background-color: #ffffff;
  border-color: #19b2d0;
}
.c-c-Tk5Sm8bhhuOk9UCTyLP1jKA .btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.c-c-Tk5Sm8bhhuOk9UCTyLP1jKA .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.c-c-Tk5Sm8bhhuOk9UCTyLP1jKA .show > .btn-outline-primary.dropdown-toggle:focus,
.v-c-medical .btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.v-c-medical .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.v-c-medical .show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(196, 197, 213, 0.5);
}
.c-c-Tk5Sm8bhhuOk9UCTyLP1jKA .btn-outline-primary.dropdown-toggle,
.c-c-Tk5Sm8bhhuOk9UCTyLP1jKA .dropdown-menu,
.v-c-medical .btn-outline-primary.dropdown-toggle,
.v-c-medical .dropdown-menu {
  color: #19b2d0;
  background-color: #FFFFFF;
  border-color: #19b2d0;
  border-width: 2px;
  border-radius: 0;
}
.c-c-Tk5Sm8bhhuOk9UCTyLP1jKA .btn-outline-primary.dropdown-toggle:hover,
.c-c-Tk5Sm8bhhuOk9UCTyLP1jKA .dropdown-menu:hover,
.v-c-medical .btn-outline-primary.dropdown-toggle:hover,
.v-c-medical .dropdown-menu:hover {
  color: #19b2d0 !important;
}
.c-c-Tk5Sm8bhhuOk9UCTyLP1jKA .form-control,
.v-c-medical .form-control {
  text-align: center;
}
.c-c-Tk5Sm8bhhuOk9UCTyLP1jKA .m-widget1__item .pt-4,
.v-c-medical .m-widget1__item .pt-4 {
  padding-top: 0 !important;
}
.c-c-Tk5Sm8bhhuOk9UCTyLP1jKA .call,
.c-c-Tk5Sm8bhhuOk9UCTyLP1jKA #agora-local-video,
.c-c-Tk5Sm8bhhuOk9UCTyLP1jKA #agora-remote-video,
.c-c-Tk5Sm8bhhuOk9UCTyLP1jKA .m-portlet,
.v-c-medical .call,
.v-c-medical #agora-local-video,
.v-c-medical #agora-remote-video,
.v-c-medical .m-portlet {
  background-color: #edfaf8 !important;
}
.c-c-Tk5Sm8bhhuOk9UCTyLP1jKA #agora-local-video > div:not(.alert) > video,
.c-c-Tk5Sm8bhhuOk9UCTyLP1jKA #agora-remote-video > div:not(.alert) > video,
.v-c-medical #agora-local-video > div:not(.alert) > video,
.v-c-medical #agora-remote-video > div:not(.alert) > video {
  background: #edfaf8 !important;
}
.c-c-Tk5Sm8bhhuOk9UCTyLP1jKA #agora-local-video ::ng-deep video,
.c-c-Tk5Sm8bhhuOk9UCTyLP1jKA #agora-remote-video ::ng-deep video,
.v-c-medical #agora-local-video ::ng-deep video,
.v-c-medical #agora-remote-video ::ng-deep video {
  background: #edfaf8 !important;
}
.c-c-Tk5Sm8bhhuOk9UCTyLP1jKA .shadow,
.c-c-Tk5Sm8bhhuOk9UCTyLP1jKA .m-portlet,
.v-c-medical .shadow,
.v-c-medical .m-portlet {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.c-c-Tk5Sm8bhhuOk9UCTyLP1jKA .call,
.c-c-Tk5Sm8bhhuOk9UCTyLP1jKA .local,
.c-c-Tk5Sm8bhhuOk9UCTyLP1jKA .video-container,
.c-c-Tk5Sm8bhhuOk9UCTyLP1jKA .local-video-container,
.c-c-Tk5Sm8bhhuOk9UCTyLP1jKA .alert,
.c-c-Tk5Sm8bhhuOk9UCTyLP1jKA .m-portlet,
.v-c-medical .call,
.v-c-medical .local,
.v-c-medical .video-container,
.v-c-medical .local-video-container,
.v-c-medical .alert,
.v-c-medical .m-portlet {
  border-radius: 10px !important;
}
.c-c-Tk5Sm8bhhuOk9UCTyLP1jKA .call-started.local-video-container,
.v-c-medical .call-started.local-video-container {
  background: transparent !important;
  padding: 0 !important;
}
.c-c-Tk5Sm8bhhuOk9UCTyLP1jKA .local-video-container,
.v-c-medical .local-video-container {
  background: #fff !important;
  padding: 1.5rem !important;
}
.c-c-Tk5Sm8bhhuOk9UCTyLP1jKA #agora-remote-video,
.v-c-medical #agora-remote-video {
  height: 50vh;
}
.c-c-Tk5Sm8bhhuOk9UCTyLP1jKA .mini-footer,
.v-c-medical .mini-footer {
  background-color: #fafafa;
  height: 100%;
  padding-bottom: 10px;
}
.c-c-Tk5Sm8bhhuOk9UCTyLP1jKA .mini-footer::before,
.v-c-medical .mini-footer::before {
  content: "";
  display: block;
  width: 100%;
  margin-bottom: 30px;
  border-top: 3px solid #fafafa;
  margin-left: auto;
  margin-right: auto;
}
.c-c-Tk5Sm8bhhuOk9UCTyLP1jKA .device-settings > div .la,
.v-c-medical .device-settings > div .la {
  color: #19b2d0 !important;
  font-size: 25px !important;
}
.c-c-Tk5Sm8bhhuOk9UCTyLP1jKA .video-buttons .btn.m-btn--pill i,
.v-c-medical .video-buttons .btn.m-btn--pill i {
  display: none;
}
.c-c-Tk5Sm8bhhuOk9UCTyLP1jKA .call-started.call.col,
.v-c-medical .call-started.call.col {
  border: 8px solid #edfaf8;
  background: #fff !important;
}
.c-c-Tk5Sm8bhhuOk9UCTyLP1jKA .col.call-init, .c-c-Tk5Sm8bhhuOk9UCTyLP1jKA .col.call-ended,
.v-c-medical .col.call-init,
.v-c-medical .col.call-ended {
  padding: 0 1rem 2rem !important;
}
.c-c-Tk5Sm8bhhuOk9UCTyLP1jKA .col.call-started, .c-c-Tk5Sm8bhhuOk9UCTyLP1jKA .col.call-starting,
.v-c-medical .col.call-started,
.v-c-medical .col.call-starting {
  padding: 1rem !important;
}
.c-c-Tk5Sm8bhhuOk9UCTyLP1jKA .m-portlet--primary,
.v-c-medical .m-portlet--primary {
  background-color: #19b2d0 !important;
}
.c-c-Tk5Sm8bhhuOk9UCTyLP1jKA .mf-chat-client,
.v-c-medical .mf-chat-client {
  background-color: #19b2d0 !important;
}
@media (max-width: 1024px) {
  .c-c-Tk5Sm8bhhuOk9UCTyLP1jKA .m-footer,
.v-c-medical .m-footer {
    padding: 0rem 0;
  }
}
@media (max-width: 767.98px) {
  .c-c-Tk5Sm8bhhuOk9UCTyLP1jKA h1,
.v-c-medical h1 {
    font-size: 1.5rem !important;
  }
  .c-c-Tk5Sm8bhhuOk9UCTyLP1jKA #agora-remote-video,
.v-c-medical #agora-remote-video {
    height: 60vh;
  }
}
.c-c-Tk5Sm8bhhuOk9UCTyLP1jKA footer .text-gray-custom,
.v-c-medical footer .text-gray-custom {
  padding-top: 10px;
  color: #575962 !important;
}

.c-c-qqpuBfjnrtzUPB0Ncio9wwI body {
  background-image: linear-gradient(to bottom, white 0%, white 100%);
  background-repeat: repeat-x;
}
.c-c-qqpuBfjnrtzUPB0Ncio9wwI .title {
  color: #2A384C !important;
}
.c-c-qqpuBfjnrtzUPB0Ncio9wwI .m--font-light {
  color: #2A384C !important;
}
.c-c-qqpuBfjnrtzUPB0Ncio9wwI h1 {
  color: #18797f !important;
  text-align: center;
}
.c-c-qqpuBfjnrtzUPB0Ncio9wwI .mf-chat-time {
  color: #2A384C !important;
}
.c-c-qqpuBfjnrtzUPB0Ncio9wwI .mf-chat-client {
  background-color: #E4AF87 !important;
  color: #2A384C !important;
}
.c-c-qqpuBfjnrtzUPB0Ncio9wwI .mf-chat-client.mf-chat-bubble--hover:hover {
  background-color: #edcbb1 !important;
}
.c-c-qqpuBfjnrtzUPB0Ncio9wwI telemedicine-wizard .m-portlet--primary {
  background-color: #e4af87 !important;
}
.c-c-qqpuBfjnrtzUPB0Ncio9wwI .telemedicine-large-modal h1 {
  text-transform: inherit !important;
}
.c-c-qqpuBfjnrtzUPB0Ncio9wwI .text-gray-custom {
  color: #2A384C !important;
}
.c-c-qqpuBfjnrtzUPB0Ncio9wwI .btn.m-btn--pill {
  border-radius: 5px !important;
}
.c-c-qqpuBfjnrtzUPB0Ncio9wwI .btn-primary {
  color: #ffffff;
  background-color: #E4AF87;
  border-color: #E4AF87;
}
.c-c-qqpuBfjnrtzUPB0Ncio9wwI .btn-primary:hover {
  color: #ffffff;
  background-color: #18202b;
  border-color: #18202b;
}
.c-c-qqpuBfjnrtzUPB0Ncio9wwI .btn-danger {
  color: #ffffff;
  background-color: #c41f1f;
  border-color: #c41f1f;
}
.c-c-qqpuBfjnrtzUPB0Ncio9wwI .btn-danger:hover {
  color: #ffffff;
  background-color: #981818;
  border-color: #981818;
}
.c-c-qqpuBfjnrtzUPB0Ncio9wwI .btn-primary:focus,
.c-c-qqpuBfjnrtzUPB0Ncio9wwI .btn-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(196, 197, 213, 0.5);
}
.c-c-qqpuBfjnrtzUPB0Ncio9wwI .btn-primary.disabled,
.c-c-qqpuBfjnrtzUPB0Ncio9wwI .btn-primary:disabled {
  color: #212529;
  background-color: #c4c5d5;
  border-color: #c4c5d5;
}
.c-c-qqpuBfjnrtzUPB0Ncio9wwI .btn-primary:not(:disabled):not(.disabled):active,
.c-c-qqpuBfjnrtzUPB0Ncio9wwI .btn-primary:not(:disabled):not(.disabled).active,
.c-c-qqpuBfjnrtzUPB0Ncio9wwI .show > .btn-primary.dropdown-toggle {
  color: #212529;
  background-color: #a6a8c0;
  border-color: #9fa0ba;
}
.c-c-qqpuBfjnrtzUPB0Ncio9wwI .btn-primary:not(:disabled):not(.disabled):active:focus,
.c-c-qqpuBfjnrtzUPB0Ncio9wwI .btn-primary:not(:disabled):not(.disabled).active:focus,
.c-c-qqpuBfjnrtzUPB0Ncio9wwI .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(196, 197, 213, 0.5);
}
.c-c-qqpuBfjnrtzUPB0Ncio9wwI .btn-outline-primary {
  color: #ffffff;
  background-color: #E4AF87;
  border-color: #E4AF87;
}
.c-c-qqpuBfjnrtzUPB0Ncio9wwI .btn-outline-primary:hover {
  color: #ffffff;
  background-color: #2A384C;
  border-color: #2A384C;
}
.c-c-qqpuBfjnrtzUPB0Ncio9wwI .btn-outline-primary:focus,
.c-c-qqpuBfjnrtzUPB0Ncio9wwI .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(196, 197, 213, 0.5);
}
.c-c-qqpuBfjnrtzUPB0Ncio9wwI .btn-outline-primary.disabled,
.c-c-qqpuBfjnrtzUPB0Ncio9wwI .btn-outline-primary:disabled {
  color: #c4c5d5;
  background-color: transparent;
}
.c-c-qqpuBfjnrtzUPB0Ncio9wwI .btn-outline-primary:not(:disabled):not(.disabled):active,
.c-c-qqpuBfjnrtzUPB0Ncio9wwI .btn-outline-primary:not(:disabled):not(.disabled).active,
.c-c-qqpuBfjnrtzUPB0Ncio9wwI .show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #414241;
  border-color: #fff;
}
.c-c-qqpuBfjnrtzUPB0Ncio9wwI .btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.c-c-qqpuBfjnrtzUPB0Ncio9wwI .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.c-c-qqpuBfjnrtzUPB0Ncio9wwI .show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(196, 197, 213, 0.5);
  color: #fff !important;
}
.c-c-qqpuBfjnrtzUPB0Ncio9wwI .btn-outline-primary.dropdown-toggle,
.c-c-qqpuBfjnrtzUPB0Ncio9wwI .dropdown-menu {
  color: #414241;
  background-color: #dee9ed;
  border-color: #fff;
  border-width: 2px;
  border-radius: 0;
}
.c-c-qqpuBfjnrtzUPB0Ncio9wwI .btn-outline-primary.dropdown-toggle:hover,
.c-c-qqpuBfjnrtzUPB0Ncio9wwI .dropdown-menu:hover {
  color: #414241;
}
.c-c-qqpuBfjnrtzUPB0Ncio9wwI .form-control {
  text-align: center;
}
.c-c-qqpuBfjnrtzUPB0Ncio9wwI .m-widget1__item .pt-4 {
  padding-top: 0 !important;
}
.c-c-qqpuBfjnrtzUPB0Ncio9wwI .call,
.c-c-qqpuBfjnrtzUPB0Ncio9wwI #agora-local-video,
.c-c-qqpuBfjnrtzUPB0Ncio9wwI #agora-remote-video,
.c-c-qqpuBfjnrtzUPB0Ncio9wwI .m-portlet {
  background-color: #dfeded !important;
}
.c-c-qqpuBfjnrtzUPB0Ncio9wwI #agora-local-video > div:not(.alert) > video,
.c-c-qqpuBfjnrtzUPB0Ncio9wwI #agora-remote-video > div:not(.alert) > video {
  background: #dfeded !important;
}
.c-c-qqpuBfjnrtzUPB0Ncio9wwI #agora-local-video ::ng-deep video,
.c-c-qqpuBfjnrtzUPB0Ncio9wwI #agora-remote-video ::ng-deep video {
  background: #dfeded !important;
}
.c-c-qqpuBfjnrtzUPB0Ncio9wwI .shadow,
.c-c-qqpuBfjnrtzUPB0Ncio9wwI .m-portlet {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.c-c-qqpuBfjnrtzUPB0Ncio9wwI .call,
.c-c-qqpuBfjnrtzUPB0Ncio9wwI .local,
.c-c-qqpuBfjnrtzUPB0Ncio9wwI .video-container,
.c-c-qqpuBfjnrtzUPB0Ncio9wwI .local-video-container,
.c-c-qqpuBfjnrtzUPB0Ncio9wwI .alert,
.c-c-qqpuBfjnrtzUPB0Ncio9wwI .m-portlet {
  border-radius: 0 !important;
}
.c-c-qqpuBfjnrtzUPB0Ncio9wwI .call-started.local-video-container {
  background: transparent !important;
  padding: 0 !important;
}
.c-c-qqpuBfjnrtzUPB0Ncio9wwI .local-video-container {
  background: #dee9ed !important;
  padding: 1.5rem !important;
}
.c-c-qqpuBfjnrtzUPB0Ncio9wwI #agora-remote-video {
  height: 50vh;
}
.c-c-qqpuBfjnrtzUPB0Ncio9wwI .mini-footer {
  background-color: #dfeded;
  height: 100%;
}
.c-c-qqpuBfjnrtzUPB0Ncio9wwI .mini-footer::before {
  content: "";
  display: block;
  width: 100%;
  margin-bottom: 30px;
  border-top: 3px solid #e4af87;
  margin-left: auto;
  margin-right: auto;
}
.c-c-qqpuBfjnrtzUPB0Ncio9wwI .device-settings > div .la {
  color: #2A384C !important;
  font-size: 25px !important;
}
.c-c-qqpuBfjnrtzUPB0Ncio9wwI .dropdown-toggle.btn-outline-primary:hover {
  color: #2A384C !important;
}
.c-c-qqpuBfjnrtzUPB0Ncio9wwI .video-buttons .btn.m-btn--pill i {
  display: none;
}
.c-c-qqpuBfjnrtzUPB0Ncio9wwI .call-started.call.col {
  border: 8px solid #dfeded;
  background: #fff !important;
}
.c-c-qqpuBfjnrtzUPB0Ncio9wwI .col.call-init, .c-c-qqpuBfjnrtzUPB0Ncio9wwI .col.call-ended {
  padding: 0 1rem 2rem !important;
}
.c-c-qqpuBfjnrtzUPB0Ncio9wwI .col.call-started, .c-c-qqpuBfjnrtzUPB0Ncio9wwI .col.call-starting {
  padding: 1rem !important;
}
@media (max-width: 1024px) {
  .c-c-qqpuBfjnrtzUPB0Ncio9wwI .m-footer {
    padding: 0rem 0;
  }
}
@media (max-width: 767.98px) {
  .c-c-qqpuBfjnrtzUPB0Ncio9wwI h1 {
    font-size: 1.5rem !important;
  }
  .c-c-qqpuBfjnrtzUPB0Ncio9wwI #agora-remote-video {
    height: 60vh;
  }
}

/* GENERALI */
.b-c1 html,
.b-c1 body,
.b-c111 html,
.b-c111 body {
  font-family: "Lato", sans-serif;
  line-height: 1.3;
}
.b-c1 body,
.b-c111 body {
  color: #414241;
  background-image: linear-gradient(to bottom, #b7cdd1 0%, #ffffff 100%);
  background-repeat: repeat-x;
}
.b-c1 .m-portlet,
.b-c111 .m-portlet {
  background: #c2d7e1;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.b-c1 .m-portlet.rounded,
.b-c111 .m-portlet.rounded {
  border-radius: 0 !important;
}
.b-c1 .m-portlet .m-portlet__body,
.b-c111 .m-portlet .m-portlet__body {
  padding: 15px;
}
.b-c1 .m--font-light,
.b-c111 .m--font-light {
  color: inherit !important;
}
.b-c1 .title,
.b-c111 .title {
  padding: 0 !important;
}
.b-c1 h1,
.b-c111 h1 {
  font-weight: 300;
  text-align: center;
}
.b-c1 h1.uppercase,
.b-c111 h1.uppercase {
  text-transform: uppercase;
}
.b-c1 h1::after,
.b-c111 h1::after {
  content: "";
  display: block;
  width: 300px;
  margin-top: 20px;
  margin-bottom: 20px;
  border-bottom: 3px solid #962235;
  margin-left: auto;
  margin-right: auto;
}
.b-c1 .telemedicine-large-modal h1,
.b-c111 .telemedicine-large-modal h1 {
  text-transform: inherit !important;
}
.b-c1 .fontFix,
.b-c111 .fontFix {
  text-transform: inherit !important;
}
.b-c1 h5.pb-5,
.b-c111 h5.pb-5 {
  padding-bottom: 20px !important;
}
.b-c1 .m-form__help.t1::before,
.b-c111 .m-form__help.t1::before {
  display: block;
  content: "";
  background-image: url("/assets/app/media/img/mobile-g.svg");
  background-size: 40px 40px;
  height: 40px;
  width: 40px;
  margin: 0 auto 10px auto;
  background-repeat: no-repeat;
}
.b-c1 .m-form__help.t2::before,
.b-c111 .m-form__help.t2::before {
  display: block;
  content: "";
  background-image: url("/assets/app/media/img/lock-g.svg");
  background-size: 50px 30px;
  height: 30px;
  width: 50px;
  margin: 0 auto 10px auto;
  background-repeat: no-repeat;
}
.b-c1 .pp::before,
.b-c111 .pp::before {
  display: block;
  content: "";
  background-image: url("/assets/app/media/img/privacy-g.svg");
  background-size: 40px 40px;
  height: 40px;
  width: 40px;
  margin: 0 auto 20px auto;
  background-repeat: no-repeat;
}
.b-c1 .btn.m-btn--pill,
.b-c1 .form-control,
.b-c111 .btn.m-btn--pill,
.b-c111 .form-control {
  font-weight: 500 !important;
  padding: 0.75rem 2rem !important;
  border-radius: 0 !important;
}
.b-c1 a,
.b-c111 a {
  color: #962235;
  text-decoration: none;
  background-color: transparent;
}
.b-c1 a:hover,
.b-c111 a:hover {
  text-decoration: underline;
}
.b-c1 .btn,
.b-c111 .btn {
  font-weight: 500;
}
.b-c1 .btn-primary,
.b-c1 .btn-danger,
.b-c111 .btn-primary,
.b-c111 .btn-danger {
  color: #ffffff;
  background-color: #962235;
  border-color: #962235;
}
.b-c1 .btn-primary:hover,
.b-c1 .btn-danger:hover,
.b-c111 .btn-primary:hover,
.b-c111 .btn-danger:hover {
  color: #ffffff;
  background-color: #761a28;
  border-color: #761a28;
}
.b-c1 .btn-primary:focus,
.b-c1 .btn-primary.focus,
.b-c111 .btn-primary:focus,
.b-c111 .btn-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(196, 197, 213, 0.5);
}
.b-c1 .btn-primary.disabled,
.b-c1 .btn-primary:disabled,
.b-c111 .btn-primary.disabled,
.b-c111 .btn-primary:disabled {
  color: #212529;
  background-color: #c4c5d5;
  border-color: #c4c5d5;
}
.b-c1 .btn-primary:not(:disabled):not(.disabled):active,
.b-c1 .btn-primary:not(:disabled):not(.disabled).active,
.b-c1 .show > .btn-primary.dropdown-toggle,
.b-c111 .btn-primary:not(:disabled):not(.disabled):active,
.b-c111 .btn-primary:not(:disabled):not(.disabled).active,
.b-c111 .show > .btn-primary.dropdown-toggle {
  color: #212529;
  background-color: #a6a8c0;
  border-color: #9fa0ba;
}
.b-c1 .btn-primary:not(:disabled):not(.disabled):active:focus,
.b-c1 .btn-primary:not(:disabled):not(.disabled).active:focus,
.b-c1 .show > .btn-primary.dropdown-toggle:focus,
.b-c111 .btn-primary:not(:disabled):not(.disabled):active:focus,
.b-c111 .btn-primary:not(:disabled):not(.disabled).active:focus,
.b-c111 .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(196, 197, 213, 0.5);
}
.b-c1 .btn-outline-primary,
.b-c111 .btn-outline-primary {
  color: #ffffff;
  background-color: #962235;
  background-image: none;
  border-color: #c4c5d5;
}
.b-c1 .btn-outline-primary:hover,
.b-c111 .btn-outline-primary:hover {
  color: #ffffff;
  background-color: #761a28;
  border-color: #c4c5d5;
}
.b-c1 .btn-outline-primary:focus,
.b-c1 .btn-outline-primary.focus,
.b-c111 .btn-outline-primary:focus,
.b-c111 .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(196, 197, 213, 0.5);
}
.b-c1 .btn-outline-primary.disabled,
.b-c1 .btn-outline-primary:disabled,
.b-c111 .btn-outline-primary.disabled,
.b-c111 .btn-outline-primary:disabled {
  color: #c4c5d5;
  background-color: transparent;
}
.b-c1 .btn-outline-primary:not(:disabled):not(.disabled):active,
.b-c1 .btn-outline-primary:not(:disabled):not(.disabled).active,
.b-c1 .show > .btn-outline-primary.dropdown-toggle,
.b-c111 .btn-outline-primary:not(:disabled):not(.disabled):active,
.b-c111 .btn-outline-primary:not(:disabled):not(.disabled).active,
.b-c111 .show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #414241;
  border-color: #fff;
}
.b-c1 .btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.b-c1 .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.b-c1 .show > .btn-outline-primary.dropdown-toggle:focus,
.b-c111 .btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.b-c111 .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.b-c111 .show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(196, 197, 213, 0.5);
}
.b-c1 .btn-outline-primary.dropdown-toggle,
.b-c1 .dropdown-menu,
.b-c111 .btn-outline-primary.dropdown-toggle,
.b-c111 .dropdown-menu {
  color: #414241;
  background-color: #dee9ed;
  border-color: #fff;
  border-width: 2px;
  border-radius: 0;
}
.b-c1 .btn-outline-primary.dropdown-toggle:hover,
.b-c1 .dropdown-menu:hover,
.b-c111 .btn-outline-primary.dropdown-toggle:hover,
.b-c111 .dropdown-menu:hover {
  color: #414241;
}
.b-c1 .form-control,
.b-c111 .form-control {
  text-align: center;
}
.b-c1 .m-widget1__item .pt-4,
.b-c111 .m-widget1__item .pt-4 {
  padding-top: 0 !important;
}
@media (min-width: 768px) {
  .b-c1 h5,
.b-c111 h5 {
    font-size: 1.55rem;
  }
}
.b-c1 .call,
.b-c1 #agora-local-video,
.b-c1 #agora-remote-video,
.b-c111 .call,
.b-c111 #agora-local-video,
.b-c111 #agora-remote-video {
  background: transparent !important;
}
.b-c1 #agora-local-video > div:not(.alert) > video,
.b-c1 #agora-remote-video > div:not(.alert) > video,
.b-c111 #agora-local-video > div:not(.alert) > video,
.b-c111 #agora-remote-video > div:not(.alert) > video {
  background: #c2d7e1 !important;
}
.b-c1 #agora-local-video ::ng-deep video,
.b-c1 #agora-remote-video ::ng-deep video,
.b-c111 #agora-local-video ::ng-deep video,
.b-c111 #agora-remote-video ::ng-deep video {
  background: #c2d7e1 !important;
}
.b-c1 .shadow,
.b-c111 .shadow {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.b-c1 .call,
.b-c1 .local,
.b-c1 .video-container,
.b-c1 .local-video-container,
.b-c1 .alert,
.b-c111 .call,
.b-c111 .local,
.b-c111 .video-container,
.b-c111 .local-video-container,
.b-c111 .alert {
  border-radius: 0 !important;
}
.b-c1 .call-started.local-video-container,
.b-c111 .call-started.local-video-container {
  background: transparent !important;
  padding: 0 !important;
}
.b-c1 .local-video-container,
.b-c111 .local-video-container {
  z-index: 5;
  background: #cadcf2 !important;
  padding: 1.5rem !important;
}
.b-c1 #agora-remote-video,
.b-c111 #agora-remote-video {
  height: 50vh;
}
.b-c1 #agora-remote-video.noVideo, .b-c1 #agora-remote-video.privacy-policy,
.b-c111 #agora-remote-video.noVideo,
.b-c111 #agora-remote-video.privacy-policy {
  height: 30vh;
}
.b-c1 .mini-footer,
.b-c111 .mini-footer {
  height: 100%;
  margin-top: 4rem;
}
.b-c1 .mini-footer::before,
.b-c111 .mini-footer::before {
  content: "";
  display: block;
  width: 100%;
  margin-top: 30px;
  margin-bottom: 30px;
  border-bottom: 3px solid #962235;
  margin-left: auto;
  margin-right: auto;
}
.b-c1 .device-settings > div .la,
.b-c111 .device-settings > div .la {
  color: #962235 !important;
  font-size: 25px !important;
}
.b-c1 .video-buttons .btn.m-btn--pill i,
.b-c111 .video-buttons .btn.m-btn--pill i {
  display: none;
}
.b-c1 .call-started.call.col,
.b-c111 .call-started.call.col {
  border: 8px solid #c2d7e1;
  background: #fff !important;
}
.b-c1 .content-wrapper,
.b-c111 .content-wrapper {
  height: 84% !important;
}
.b-c1 .col.call-init, .b-c1 .col.call-ended,
.b-c111 .col.call-init,
.b-c111 .col.call-ended {
  padding: 0 1rem 2rem !important;
}
.b-c1 .col.call-started, .b-c1 .col.call-starting,
.b-c111 .col.call-started,
.b-c111 .col.call-starting {
  padding: 1rem !important;
}
.b-c1 .call.call-started,
.b-c111 .call.call-started {
  background: #c2d7e1 !important;
}

.m-list-search__result-item {
  display: table;
  table-layout: fixed;
  width: 100%;
  padding: 0 0;
  outline: none;
}
.m-list-search__result-item .m-list-search__result-item-icon {
  display: table-cell;
  height: 100%;
  vertical-align: middle;
  text-align: left;
  padding: 1px;
  width: 28px;
  font-size: 1.2rem;
}
.m-list-search__result-item .m-list-search__result-item-text {
  display: table-cell;
  height: 100%;
  width: 100%;
  vertical-align: middle;
  font-size: 1rem;
}

.telemedicine .m-portlet {
  margin-bottom: 15px !important;
}

@media (max-width: 767.98px) {
  .local-video-container.call-started {
    width: 130px !important;
  }

  html,
body {
    font-size: 13px;
  }

  .btn-circle {
    width: 35px;
    height: 35px;
    line-height: 30px;
  }

  .btn-circle-lg {
    width: 45px;
    height: 45px;
    line-height: 45px;
  }

  .device-settings button {
    margin: 2px !important;
  }

  .alert {
    line-height: 1;
  }
}
.file-upload {
  outline-offset: 0 !important;
  padding: 1rem !important;
  outline: #ebedf2 dashed 1px !important;
}
.file-upload.list-visible .upload-input {
  position: relative;
  top: 1rem !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  margin-left: 0 !important;
}
.file-upload .file-info {
  border-bottom: 1px dashed rgba(0, 0, 0, 0.1);
  padding: 5px 0 5px 0;
}
.file-upload .file-info:last-child {
  border: none;
  padding-bottom: 0px;
}
.file-upload .file-info a {
  cursor: pointer;
}

.modal-lg {
  max-width: 90%;
}

.mf-transparent-popup .modal-content {
  background-color: transparent;
  box-shadow: none;
  border: 0;
}
.mf-transparent-popup .modal-content .modal-footer {
  padding: 0px;
  border: none;
}

@media (max-width: 991.98px) {
  .hiddenMobile {
    display: none;
  }

  .openMobile {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    z-index: 10;
  }
}
.mf-content-wrapper .mf-telemed-wrapper {
  height: 100%;
}
/* You can add global styles to this file, and also import other style files */
// @import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;200;300;400;500;600;700;800;900&display=swap&subset=latin,latin-ext,cyrillic');
@import url('https://cdn.medifit.si/fonts/lato/v16/');
@import url('https://cdn.medifit.si/fonts/sourcesanspro/v13/');

$navy9: #00234b;
$red8: #a16065;
$primary: #00234b;
$font-family-sans-serif: 'Source Sans Pro', sans-serif;
$border-radius-xl: 1.2rem;

$theme-colors: (
    'gray-custom': #f2f3f7,
    'tertiary': #3b4752,
);

@import '~ngx-toastr/toastr';

@import '~bootstrap/scss/bootstrap';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/gradients';
@import '~bootstrap/scss/mixins/breakpoints';
@import '~bootstrap/scss/mixins/grid';
@import '~bootstrap/scss/mixins/grid-framework';

html,
body {
    font-size: 14px;
}

body {
    // color: $navy9;
    color: #333;
    letter-spacing: -0.3px;
    overflow-y: scroll;
    overflow-x: hidden;
    // @include gradient-y(#00d5a1, #07bad4);
}

body .m-page-loader {
    background: white !important;
}

.m-page--loading-non-block .m-page-loader.m-page-loader--base.m-page-loader--non-block {
    display: flex !important;
}

.m-page-loader.m-page-loader--base.m-page-loader--non-block {
    //display: flex !important;
    align-items: center !important;
    height: 100% !important;
    width: 100% !important;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0 auto !important;

    .m-blockui {
        margin: 0 auto !important;
    }
}

.small-content-wrapper {
    max-width: 700px;
    margin: 0 auto;
    width: 100%;
}

ul {
    padding-inline-start: 20px;
}

.ucfirst {
    &:first-letter {
        text-transform: uppercase;
    }
}

.btn {
    font-family: $font-family-sans-serif;
    font-weight: bold;
    //text-transform: uppercase;
}

.btn.disabled,
.btn:disabled {
    opacity: 0.15;
}

.btn-lg {
    font-weight: 300;

    strong {
        font-weight: 900;
    }
}

.btn-shadowed {
    box-shadow: 0 2px 4px 0 rgba(0, 35, 75, 0.3) !important;
}

.btn.btn-outline-primary {
    color: #3b4752;
    border-color: #a6b0b6;
}

.btn-end-icon {
    font-size: 2rem;
    float: right;
    position: relative;
    line-height: 2rem !important;
    width: 0px;
    right: 1rem;
}

.btn-start-icon {
    font-size: 2rem;
    float: left;
    position: relative;
    line-height: 2rem !important;
    width: 0px;
    //left: -10px;
}

.m-checkbox {
    color: $primary;
}

.lead-xl {
    font-size: 1.5rem;
    font-weight: normal;
}

//
//.free-float-content {
//    h1,
//    h5 {
//        font-weight: 300;
//    }
//}

.m-portlet {
    box-shadow: 0 2px 4px 0 rgba(0, 35, 75, 0.3);

    &.m-portlet--rounded {
        border-radius: $border-radius-xl;

        .m-portlet__head {
            border-radius: 0px;

            &:first-child {
                border-top-right-radius: $border-radius-xl;
                border-top-left-radius: $border-radius-xl;
            }

            &:last-child {
                border-bottom-right-radius: $border-radius-xl;
                border-bottom-left-radius: $border-radius-xl;
            }
        }
    }

    .m-portlet__head {
        border-bottom-color: #d2d8df;
        //min-height: 5.1rem;
        height: auto;
        padding: $border-radius-xl 15px;

        h6 {
            margin-bottom: 0 !important;
        }

        h5 {
            margin-bottom: 0 !important;
        }
    }

    .m-portlet__body {
        padding: 2rem 15px;
    }

    .m-portlet__head-caption {
        font-size: 1.25rem;

        h6 {
            font-weight: 300;
            margin-bottom: 0 !important;
            font-size: 1.15rem;
        }

        h5 {
            font-weight: normal;
            margin-bottom: 0 !important;
        }

        .text-danger {
            color: $red8 !important;
        }

        .m-portlet__head-text {
            font-family: inherit !important;
        }
    }

    &.m-portlet--primary {
        background: $primary;

        .m-portlet__body {
            color: $white;
        }
    }
}

.time-slots {
    &.time-slots-daily {
        display: block;
    }

    &.time-slots-weekly {
        display: none;
    }

    @include media-breakpoint-up(md) {
        &.time-slots-daily {
            display: none;
        }

        &.time-slots-weekly {
            display: block;
        }
    }

    table {
        width: 100%;

        thead,
        tbody {
            th,
            td {
                &.days-offset--1,
                &.days-offset--2,
                &.days-offset--3,
                &.days-offset--4,
                &.days-offset--5,
                &.days-offset--6,
                &.days-offset--7,
                &.days-offset-3,
                &.days-offset-4,
                &.days-offset-5,
                &.days-offset-6,
                &.days-offset-7 {
                    display: none;
                }

                /*
        &:nth-child(5), &:nth-child(6) {
          display: none;
        }
        */
            }
        }

        thead {
            th {
                color: #3b4752;
                text-align: center;
                padding: 0.5rem 0;
                width: 32%;

                @include media-breakpoint-up(md) {
                    width: 14.3%;
                }

                .arrows {
                    &.disabled {
                        opacity: 0.3;
                    }
                }

                &:first-child,
                &:last-child {
                    width: 1%;
                }

                &:not(.wo-border) {
                    border-top: 1px solid #efeff0;
                    border-bottom: 1px solid #efeff0;
                    padding: 0.5rem 0.25rem;
                }

                .day {
                    text-transform: uppercase;
                    font-size: 16px;
                    font-weight: bold;
                    opacity: 0.6;
                }

                .date {
                    text-transform: uppercase;
                    font-size: 14px;
                    font-weight: 300;
                }

                .la {
                    color: #d2d8df;
                }
            }
        }

        tbody {
            td {
                text-align: center;
                padding: 0.5rem 0.5rem;
                vertical-align: top;

                .m-btn--pill {
                    min-width: 80%;
                    margin-bottom: 0.5rem;
                    font-size: 1.15rem;
                }

                .no-slots {
                    text-transform: uppercase;
                    color: #82939c;
                }
            }
        }
    }
}

.mini-footer {
    font-size: 12px;
    line-height: 19px;

    img {
        vertical-align: top;
    }
}

/*
.n-sticky-footer {
  position: fixed;
  bottom: 5px;
  width: 100%;
  background: #fff;
  border-bottom-left-radius: 1.2rem;
  border-bottom-right-radius: 1.2rem;
  box-shadow: 0 2px 4px 0 rgba(0, 35, 75, 0.3);
  &:before{
    content: "";
    background: #07bad4;
    display: block;
    top: 0;
    bottom: -5px;
    left: 0;
    right: 0;
    position: absolute;
  }
}*/

.btn-circle {
    width: 45px;
    height: 45px;
    line-height: 45px;
    text-align: center;
    padding: 0;
    border-radius: 50%;
}

.btn-circle i {
    position: relative;
    top: -1px;
}

.btn-circle-sm {
    width: 35px;
    height: 35px;
    line-height: 35px;
    font-size: 0.9rem;
}

.btn-circle-lg {
    width: 55px;
    height: 55px;
    line-height: 55px;
    font-size: 1.1rem;
}

.btn-circle-xl {
    width: 70px;
    height: 70px;
    line-height: 70px;
    font-size: 1.3rem;
}

.btn-metal {
    color: #212529;
    background-color: #c4c5d5;
    border-color: #c4c5d5;
}

.btn-metal:hover {
    color: #212529;
    background-color: #aeafc5;
    border-color: #a6a8c0;
}

.btn-metal:focus,
.btn-metal.focus {
    box-shadow: 0 0 0 0.2rem rgba(196, 197, 213, 0.5);
}

.btn-metal.disabled,
.btn-metal:disabled {
    color: #212529;
    background-color: #c4c5d5;
    border-color: #c4c5d5;
}

.btn-metal:not(:disabled):not(.disabled):active,
.btn-metal:not(:disabled):not(.disabled).active,
.show > .btn-metal.dropdown-toggle {
    color: #212529;
    background-color: #a6a8c0;
    border-color: #9fa0ba;
}

.btn-metal:not(:disabled):not(.disabled):active:focus,
.btn-metal:not(:disabled):not(.disabled).active:focus,
.show > .btn-metal.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(196, 197, 213, 0.5);
}

.btn-outline-metal {
    color: #c4c5d5;
    background-color: transparent;
    background-image: none;
    border-color: #c4c5d5;
}

.btn-outline-metal:hover {
    color: #ffffff;
    background-color: #c4c5d5;
    border-color: #c4c5d5;
}

.btn-outline-metal:focus,
.btn-outline-metal.focus {
    box-shadow: 0 0 0 0.2rem rgba(196, 197, 213, 0.5);
}

.btn-outline-metal.disabled,
.btn-outline-metal:disabled {
    color: #c4c5d5;
    background-color: transparent;
}

.btn-outline-metal:not(:disabled):not(.disabled):active,
.btn-outline-metal:not(:disabled):not(.disabled).active,
.show > .btn-outline-metal.dropdown-toggle {
    color: #212529;
    background-color: #c4c5d5;
    border-color: #c4c5d5;
}

.btn-outline-metal:not(:disabled):not(.disabled):active:focus,
.btn-outline-metal:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-metal.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(196, 197, 213, 0.5);
}

.hidden {
    display: none !important;
}

.tooltip,
.bs-tooltip-right,
.bs-tooltip-left {
    .tooltip-inner {
        background: black;
    }

    .arrow::before {
        border-top-color: black;
    }
}

/* VENDORS */
@import './assets/styles/v-c-infonet.scss';
@import './assets/styles/v-c-medifit.scss';
@import './assets/styles/v-c-vizija.scss';
@import './assets/styles/v-c-audax.scss';
@import './assets/styles/c-c-medovernet.scss';
@import './assets/styles/c-c-esos.scss';
@import './assets/styles/b-c-vzajemna.scss';
@import './assets/styles/b-c-prva.scss';
@import './assets/styles/b-c-vita.scss';
@import './assets/styles/v-c-medical.scss';
@import './assets/styles/c-c-juventina-baby.scss';

/* GENERALI */

.b-c1,
.b-c111 {
    html,
    body {
        font-family: 'Lato', sans-serif;
        line-height: 1.3;
    }

    body {
        color: #414241;
        @include gradient-y(#b7cdd1, #ffffff);
    }

    .m-portlet {
        background: #c2d7e1;
        -webkit-box-shadow: none;
        box-shadow: none;

        &.rounded {
            border-radius: 0 !important;
        }

        .m-portlet__body {
            padding: 15px;
        }
    }

    .m--font-light {
        color: inherit !important;
    }

    .title {
        padding: 0 !important;
    }

    h1 {
        &.uppercase {
            text-transform: uppercase;
        }

        font-weight: 300;
        text-align: center;

        //display: block;
        &::after {
            content: '';
            display: block;
            width: 300px;
            margin-top: 20px;
            margin-bottom: 20px;
            border-bottom: 3px solid #962235;
            margin-left: auto;
            margin-right: auto;
        }
    }

    .telemedicine-large-modal {
        h1 {
            text-transform: inherit !important;
        }
    }

    .fontFix {
        text-transform: inherit !important;
    }

    h5 {
        //font-size: 1.35rem;

        &.pb-5 {
            padding-bottom: 20px !important;
        }
    }

    .m-form__help.t1 {
        &::before {
            display: block;
            content: '';
            background-image: url('/assets/app/media/img/mobile-g.svg');
            background-size: 40px 40px;
            height: 40px;
            width: 40px;
            margin: 0 auto 10px auto;
            background-repeat: no-repeat;
            //padding-bottom: 10px;
        }
    }

    .m-form__help.t2 {
        &::before {
            display: block;
            content: '';
            background-image: url('/assets/app/media/img/lock-g.svg');
            background-size: 50px 30px;
            height: 30px;
            width: 50px;
            margin: 0 auto 10px auto;
            background-repeat: no-repeat;
            //padding-bottom: 10px;
        }
    }

    .pp {
        &::before {
            display: block;
            content: '';
            background-image: url('/assets/app/media/img/privacy-g.svg');
            background-size: 40px 40px;
            height: 40px;
            width: 40px;
            margin: 0 auto 20px auto;
            background-repeat: no-repeat;
            //padding-bottom: 10px;
        }
    }

    .btn.m-btn--pill,
    .form-control {
        font-weight: 500 !important;
        padding: 0.75rem 2rem !important;
        border-radius: 0 !important;
    }

    a {
        color: #962235;
        text-decoration: none;
        background-color: transparent;

        &:hover {
            text-decoration: underline;
        }
    }

    .btn {
        font-weight: 500;
    }

    .btn-primary,
    .btn-danger {
        color: #ffffff;
        background-color: #962235;
        border-color: #962235;
    }

    .btn-primary:hover,
    .btn-danger:hover {
        color: #ffffff;
        background-color: #761a28;
        border-color: #761a28;
    }

    .btn-primary:focus,
    .btn-primary.focus {
        box-shadow: 0 0 0 0.2rem rgba(196, 197, 213, 0.5);
    }

    .btn-primary.disabled,
    .btn-primary:disabled {
        color: #212529;
        background-color: #c4c5d5;
        border-color: #c4c5d5;
    }

    .btn-primary:not(:disabled):not(.disabled):active,
    .btn-primary:not(:disabled):not(.disabled).active,
    .show > .btn-primary.dropdown-toggle {
        color: #212529;
        background-color: #a6a8c0;
        border-color: #9fa0ba;
    }

    .btn-primary:not(:disabled):not(.disabled):active:focus,
    .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
        box-shadow: 0 0 0 0.2rem rgba(196, 197, 213, 0.5);
    }

    .btn-outline-primary {
        color: #ffffff;
        background-color: #962235;
        background-image: none;
        border-color: #c4c5d5;
    }

    .btn-outline-primary:hover {
        color: #ffffff;
        background-color: #761a28;
        border-color: #c4c5d5;
    }

    .btn-outline-primary:focus,
    .btn-outline-primary.focus {
        box-shadow: 0 0 0 0.2rem rgba(196, 197, 213, 0.5);
    }

    .btn-outline-primary.disabled,
    .btn-outline-primary:disabled {
        color: #c4c5d5;
        background-color: transparent;
    }

    .btn-outline-primary:not(:disabled):not(.disabled):active,
    .btn-outline-primary:not(:disabled):not(.disabled).active,
    .show > .btn-outline-primary.dropdown-toggle {
        color: #fff;
        background-color: #414241;
        border-color: #fff;
    }

    .btn-outline-primary:not(:disabled):not(.disabled):active:focus,
    .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
        box-shadow: 0 0 0 0.2rem rgba(196, 197, 213, 0.5);
    }

    .btn-outline-primary.dropdown-toggle,
    .dropdown-menu {
        color: #414241;
        background-color: #dee9ed;
        border-color: #fff;
        border-width: 2px;
        border-radius: 0;

        &:hover {
            color: #414241;
        }
    }

    .form-control {
        text-align: center;
    }

    .m-widget1__item {
        .pt-4 {
            padding-top: 0 !important;
        }
    }

    @include media-breakpoint-up(md) {
        h5 {
            font-size: 1.55rem;
        }
    }

    .call,
    #agora-local-video,
    #agora-remote-video {
        //background-color: #c2d7e1 !important;
        background: transparent !important;
    }

    #agora-local-video,
    #agora-remote-video {
        & > div:not(.alert) {
            & > video {
                background: #c2d7e1 !important;
            }
        }

        & ::ng-deep video {
            background: #c2d7e1 !important;
        }
    }

    .shadow {
        -webkit-box-shadow: none !important;
        box-shadow: none !important;
    }

    .call,
    .local,
    .video-container,
    .local-video-container,
    .alert {
        border-radius: 0 !important;
    }

    .call-started {
        &.local-video-container {
            background: transparent !important;
            padding: 0 !important;
        }
    }

    .local-video-container {
        z-index: 5;
        //background: #dee9ed !important;
        background: #cadcf2 !important;
        padding: 1.5rem !important;
    }

    #agora-remote-video {
        //height: 50vh;

        &.noVideo,
        &.privacy-policy {
            height: 30vh;
        }

        height: 50vh;
    }

    .mini-footer {
        height: 100%;
        margin-top: 4rem;

        &::before {
            content: '';
            display: block;
            width: 100%;
            margin-top: 30px;
            margin-bottom: 30px;
            border-bottom: 3px solid #962235;
            margin-left: auto;
            margin-right: auto;
        }
    }

    .device-settings {
        & > div {
            .la {
                color: #962235 !important;
                font-size: 25px !important;
            }
        }
    }

    .video-buttons .btn.m-btn--pill {
        i {
            display: none;
        }
    }

    .call-started {
        &.call.col {
            border: 8px solid #c2d7e1;
            background: #fff !important;
        }
    }

    .content-wrapper {
        height: 84% !important;
    }

    .col {
        &.call-init,
        &.call-ended {
            padding: 0 1rem 2rem !important;
        }

        &.call-started,
        &.call-starting {
            padding: 1rem !important;
        }
    }

    .call {
        &.call-started {
            background: #c2d7e1 !important;
        }
    }
}

.m-list-search__result-item {
    display: table;
    table-layout: fixed;
    width: 100%;
    padding: 0 0;
    outline: none;

    .m-list-search__result-item-icon {
        display: table-cell;
        height: 100%;
        vertical-align: middle;
        text-align: left;
        padding: 1px;
        width: 28px;
        font-size: 1.2rem;
    }

    .m-list-search__result-item-text {
        display: table-cell;
        height: 100%;
        width: 100%;
        vertical-align: middle;
        font-size: 1rem;
    }
}

.telemedicine {
    .m-portlet {
        margin-bottom: 15px !important;
    }
}

@include media-breakpoint-down(sm) {
    .local-video-container.call-started {
        width: 130px !important;
    }

    html,
    body {
        font-size: 13px;
    }

    .btn-circle {
        width: 35px;
        height: 35px;
        line-height: 30px;
    }

    .btn-circle-lg {
        width: 45px;
        height: 45px;
        line-height: 45px;
        //font-size: 1.1rem;
    }

    .device-settings {
        button {
            margin: 2px !important;
        }
    }

    .alert {
        line-height: 1;
    }
}

.file-upload {
    outline-offset: 0 !important;
    padding: 1rem !important;
    outline: #ebedf2 dashed 1px !important;

    &.list-visible {
        .upload-input {
            position: relative;
            top: 1rem !important;
            left: 50% !important;
            transform: translate(-50%, -50%) !important;
            margin-left: 0 !important;
        }
    }

    .file-info {
        border-bottom: 1px dashed rgba(0, 0, 0, 0.1);
        padding: 5px 0 5px 0;

        &:last-child {
            border: none;
            padding-bottom: 0px;
        }

        a {
            cursor: pointer;
        }
    }
}

.modal-lg {
    max-width: 90%;
}

.mf-transparent-popup {
    .modal-content {
        background-color: transparent; // border: 1px solid white;
        box-shadow: none;
        border: 0;
    }

    .modal-content {
        .modal-footer {
            padding: 0px;
            border: none;
        }
    }
}

@include media-breakpoint-down(md) {
    .hiddenMobile {
        display: none;
    }

    .openMobile {
        display: block;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        height: 100%;
        width: 100%;
        z-index: 10;
    }
}


.mf-content-wrapper  .mf-telemed-wrapper{

        height: 100%;
    
}


.v-c-audax {
    $color1: #dfdfdf;
    $color2: #102751;
    $color-danger: #c41f1f;

    //23b9d6
    // html,
    // body {
    //     font-family: 'Lato', sans-serif;
    //     line-height: 1;
    // }

    body {
        // color: #414241;
        @include gradient-y(white, white);
        // background-color: white;
    }

    // .m-portlet {
    //     // background: #c2d7e1;
    //     // -webkit-box-shadow: none;
    //     // box-shadow: none;

    //     // &.rounded {
    //     //     border-radius: 0 !important;
    //     // }

    //     // .m-portlet__body {
    //     //     padding: 15px;
    //     // }
    // }

    // .m--font-light {
    //     color: inherit !important;
    // }

    .title {
        //padding: 0 !important;
        color: $color2 !important;
    }

    h1 {
        // text-transform: uppercase;
        // font-weight: 300;
        text-align: center;

        //display: block;
        // &::after {
        //     content: '';
        //     display: block;
        //     width: 300px;
        //     margin-top: 20px;
        //     margin-bottom: 20px;
        //     border-bottom: 3px solid $color2;
        //     margin-left: auto;
        //     margin-right: auto;
        // }
    }

    .telemedicine-large-modal {
        h1 {
            text-transform: inherit !important;
        }
    }

    // h5 {
    //     //font-size: 1.35rem;

    //     &.pb-5 {
    //         padding-bottom: 20px !important;
    //     }
    // }

    // .m-form__help.t1 {
    //     &::before {
    //         display: block;
    //         content: '';
    //         background-image: url('/assets/app/media/img/mobile-g.svg');
    //         background-size: 40px 40px;
    //         height: 40px;
    //         width: 40px;
    //         margin: 0 auto 10px auto;
    //         background-repeat: no-repeat;
    //         //padding-bottom: 10px;
    //     }
    // }

    // .m-form__help.t2 {
    //     &::before {
    //         display: block;
    //         content: '';
    //         background-image: url('/assets/app/media/img/lock-g.svg');
    //         background-size: 50px 30px;
    //         height: 30px;
    //         width: 50px;
    //         margin: 0 auto 10px auto;
    //         background-repeat: no-repeat;
    //         //padding-bottom: 10px;
    //     }
    // }

    .btn.m-btn--pill,
    // .form-control {
    //     font-weight: 500 !important;
    //     padding: .75rem 2rem !important;
    //     border-radius: 0 !important;
    // }


    a {
        color: $color2;
        text-decoration: none;
        background-color: transparent;

        // &:hover {
        //     text-decoration: underline;
        // }
    }

    // .btn {
    //     font-weight: 500;
    // }

    .btn-primary {
        color: #ffffff;
        background-color: $color2;
        border-color: $color2;
    }

    .btn-primary:hover {
        color: #ffffff;
        background-color: darken($color2, 10%);
        border-color: darken($color2, 10%);
    }

    .btn-danger {
        color: #ffffff;
        background-color: $color-danger;
        border-color: $color-danger;
    }

    .btn-danger:hover {
        color: #ffffff;
        background-color: darken($color-danger, 10%);
        border-color: darken($color-danger, 10%);
    }

    .btn-primary:focus,
    .btn-primary.focus {
        box-shadow: 0 0 0 0.2rem rgba(196, 197, 213, 0.5);
    }

    .btn-primary.disabled,
    .btn-primary:disabled {
        color: #212529;
        background-color: #c4c5d5;
        border-color: #c4c5d5;
    }

    .btn-primary:not(:disabled):not(.disabled):active,
    .btn-primary:not(:disabled):not(.disabled).active,
    .show > .btn-primary.dropdown-toggle {
        color: #212529;
        background-color: #a6a8c0;
        border-color: #9fa0ba;
    }

    .btn-primary:not(:disabled):not(.disabled):active:focus,
    .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
        box-shadow: 0 0 0 0.2rem rgba(196, 197, 213, 0.5);
    }

    .btn-outline-primary {
        color: #ffffff;
        background-color: $color2;
        background-image: none;
        border-color: $color2;
    }

    .btn-outline-primary:hover {
        color: #ffffff;
        background-color: $color2;
        border-color: #c4c5d5;
    }

    .btn-outline-primary:focus,
    .btn-outline-primary.focus {
        box-shadow: 0 0 0 0.2rem rgba(196, 197, 213, 0.5);
    }

    .btn-outline-primary.disabled,
    .btn-outline-primary:disabled {
        color: #c4c5d5;
        background-color: transparent;
    }

    .btn-outline-primary:not(:disabled):not(.disabled):active,
    .btn-outline-primary:not(:disabled):not(.disabled).active,
    .show > .btn-outline-primary.dropdown-toggle {
        color: #fff;
        background-color: #414241;
        border-color: #fff;
    }

    .btn-outline-primary:not(:disabled):not(.disabled):active:focus,
    .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
        box-shadow: 0 0 0 0.2rem rgba(196, 197, 213, 0.5);
    }

    .btn-outline-primary.dropdown-toggle,
    .dropdown-menu {
        color: #414241;
        background-color: #dee9ed;
        border-color: #fff;
        border-width: 2px;
        border-radius: 0;

        &:hover {
            color: #414241;
        }
    }

    .form-control {
        text-align: center;
    }

    .m-widget1__item {
        .pt-4 {
            padding-top: 0 !important;
        }
    }

    @include media-breakpoint-up(md) {
        // h5 {
        //     font-size: 1.55rem;
        // }
    }

    .call,
    #agora-local-video,
    #agora-remote-video,
    .m-portlet {
        background-color: $color1 !important;
    }

    #agora-local-video,
    #agora-remote-video {
        & > div:not(.alert) {
            & > video {
                background: $color1 !important;
            }
        }

        & ::ng-deep video {
            background: $color1 !important;
        }
    }

    .shadow,
    .m-portlet {
        -webkit-box-shadow: none !important;
        box-shadow: none !important;
    }

    .call,
    .local,
    .video-container,
    .local-video-container,
    .alert,
    .m-portlet {
        border-radius: 0 !important;
    }

    .call-started {
        &.local-video-container {
            background: transparent !important;
            padding: 0 !important;
        }
    }

    .local-video-container {
        background: #dee9ed !important;
        padding: 1.5rem !important;
    }

    #agora-remote-video {
        height: 50vh;
    }

    .mini-footer {
        background-color: $color2;
        height: 100%;
        // margin-top: 4rem;

        &::before {
            content: '';
            display: block;
            width: 100%;
            // margin-top: 30px;
            margin-bottom: 30px;
            border-top: 3px solid $color2;
            margin-left: auto;
            margin-right: auto;
        }
    }

    .device-settings {
        & > div {
            .la {
                color: $color2 !important;
                font-size: 25px !important;
            }
        }
    }

    .video-buttons .btn.m-btn--pill {
        i {
            display: none;
        }
    }

    .call-started {
        &.call.col {
            border: 8px solid $color1;
            background: #fff !important;
        }
    }

    // .content-wrapper {
    //     height: 84% !important;
    // }

    .col {
        &.call-init,
        &.call-ended {
            padding: 0 1rem 2rem !important;
        }

        &.call-started,
        &.call-starting {
            padding: 1rem !important;
        }
    }

    @media (max-width: 1024px) {
        .m-footer {
            padding: 0rem 0;
            // height: auto;
        }
    }

    @include media-breakpoint-down(sm) {
        h1 {
            font-size: 1.5rem !important;
        }
        #agora-remote-video {
            height: 60vh;
        }
    }
}

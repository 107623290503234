@font-face {
    font-family: 'CocoSharp';
    src: url('assets/app/media/fonts/Coco_sharp/Coco-Sharp-Regular.ttf') format('truetype');
}

.b-c23 {
    $color1: #f2eee9;
    $color2: #28007D;
    $color3: #fafafa;
    $color4: #5b5b5b;
    $color-danger: #c41f1f;

    body {
        font-family: 'CocoSharp', sans-serif !important;
        font-weight: lighter !important;
        // color: #414241;
        @include gradient-y(white, white);
        background-image: url('assets/app/media/img/vita-bg.jpg');
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        background-repeat: no-repeat;
        background-size: cover;
        overflow: auto;
    }

    ::placeholder {
        color: #b0b6bc;
        opacity: 1;
    }

    ::-ms-input-placeholder {
        color: #b0b6bc;
    }

    app-auth form .m-portlet .m-portlet__body {
        background: #ffffff;
        box-shadow: 0px 0px 9px 3px #00000021 !important;
    }
    
    app-auth form .m-widget1__item.pb-4 {
        padding-bottom: 0 !important;
    }

    
    .title {
        color: $color2 !important;
    }

    h1 {
        text-transform: uppercase;
        font-weight: lighter !important;
        text-align: center;
        color: $color4 !important;
    }
    h5 {
        color: $color4 !important;
    }

    .telemedicine-large-modal {
        h1 {
            text-transform: inherit !important;
        }
    }

    .btn.m-btn--pill {
        //font-weight: 800 !important;
        text-transform: uppercase;
    }

    .form-control,
    .btn.m-btn--pill {
        font-weight: normal;
        padding: 0.85rem 0.75rem;
        padding-left: 1rem !important;
        padding-right: 1rem !important;
        border-radius: 0 !important;
    }

    a {
        color: $color2;
        text-decoration: none;
        background-color: transparent;

    }


    .btn-primary {
        color: #ffffff;
        background-color: $color2;
        border-color: $color2;
    }

    .btn-primary:hover,
    .btn.m-btn--pill:focus {
        color: #ffffff;
        background-color: darken($color2, 10%);
        border-color: darken($color2, 10%);
    }

    .btn-danger {
        color: #ffffff;
        background-color: $color-danger;
        border-color: $color-danger;
    }

    .btn-danger:hover {
        color: #ffffff;
        background-color: darken($color-danger, 10%);
        border-color: darken($color-danger, 10%);
    }

    .btn-primary:focus,
    .btn-primary.focus {
        box-shadow: 0 0 0 0.2rem rgba(196, 197, 213, 0.5);
    }

    .btn-primary.disabled,
    .btn-primary:disabled {
        color: #212529;
        background-color: #c4c5d5;
        border-color: #c4c5d5;
    }

    .btn-primary:not(:disabled):not(.disabled):active,
    .btn-primary:not(:disabled):not(.disabled).active,
    .show > .btn-primary.dropdown-toggle {
        color: #212529;
        background-color: #a6a8c0;
        border-color: #9fa0ba;
    }

    .btn-primary:not(:disabled):not(.disabled):active:focus,
    .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
        box-shadow: 0 0 0 0.2rem rgba(196, 197, 213, 0.5);
    }

    .btn-outline-primary {
        color: #ffffff;
        background-color: $color2;
        background-image: none;
        border-color: $color2;
    }

    .btn-outline-primary:hover {
        color: #ffffff;
        background-color: $color2;
        border-color: #c4c5d5;
    }

    .btn-outline-primary:focus,
    .btn-outline-primary.focus {
        box-shadow: 0 0 0 0.2rem rgba(196, 197, 213, 0.5);
    }

    .btn-outline-primary.disabled,
    .btn-outline-primary:disabled {
        color: #c4c5d5;
        background-color: transparent;
    }

    .btn-outline-primary:not(:disabled):not(.disabled):active,
    .btn-outline-primary:not(:disabled):not(.disabled).active,
    .show > .btn-outline-primary.dropdown-toggle {
        color: $color4 !important;
        background-color: #ffffff;
        border-color: $color4;
    }

    .btn-outline-primary:not(:disabled):not(.disabled):active:focus,
    .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
        box-shadow: 0 0 0 0.2rem rgba(196, 197, 213, 0.5);
    }

    .btn-outline-primary.dropdown-toggle,
    .dropdown-menu {
        color: $color4;
        background-color: #ffffff;
        border-color: $color4;
        border-width: 2px;
        border-radius: 0;

        &:hover {
            color: $color4 !important;
        }
    }

    app-telemedicine h1 {
        text-transform: initial !important;
    }

    telemedicine-case-info .m-portlet {
        background: #ffffff !important;
        box-shadow: 0px 0px 9px 3px #00000021 !important;
        .m-portlet__head {
            width: 90%;
            margin: 0 auto;
            padding-left: 0px;
            background: #ffffff;
        }
        .m-portlet__head-text {
            text-transform: uppercase;
        }
    }

    telemedicine-communication .m-portlet {
        &.openMobile {
            background: #ffffff !important;
        }
        background-color: transparent !important;
        border: 1px #000000 solid !important;
        //.m-portlet__head {
        //    padding-left: 0px;
        //}
        .m-portlet__head {
        background-color: transparent !important;
            width: 90%;
            margin: 0 auto;
            padding-left: 0px;
        }
        .m-portlet__head-text {
            text-transform: uppercase;
        }
        .m-portlet__body {
            background-color: transparent !important;
        }
    }

    telemedicine-wizard a .m-portlet.rounded:not(.m-portlet--primary) {
        background-color: transparent !important;
        border: 1px $color2 solid !important;
    }

    telemedicine-wizard a.text-white .m-portlet__body {
        background-color: $color2 !important;
    }
    
    .form-control {
        text-align: center;
    }

    .m-widget1__item {
        .pt-4 {
            padding-top: 0 !important;
        }
    }

    @include media-breakpoint-up(md) {
        // h5 {
        //     font-size: 1.55rem;
        // }
    }

    .call,
    #agora-local-video,
    #agora-remote-video,
    .m-portlet {
        //background-color: $color1 !important;
    }

    #agora-local-video,
    #agora-remote-video {
        & > div:not(.alert) {
            & > video {
                //background: $color1 !important;
            }
        }

        & ::ng-deep video {
            //background: $color1 !important;
        }
    }

    .shadow,
    .m-portlet {
        -webkit-box-shadow: none !important;
        box-shadow: none !important;
    }

    .call,
    .local,
    .video-container,
    .local-video-container,
    .alert,
    .m-portlet {
        border-radius: 0 !important;
    }

    .call-started {
        &.local-video-container {
            background: transparent !important;
            padding: 0 !important;
        }
    }

    .local-video-container {
        background: #fff !important;
        padding: 1.5rem !important;
    }

    #agora-remote-video {
        height: 50vh;
    }

    .mini-footer {
        background-color: transparent;
        height: 100%;
        padding-bottom: 20px;
        // margin-top: 4rem;

        &::before {
            content: '';
            display: block;
            width: 90%;
            //margin-top: 30px;
            margin-bottom: 30px;
            border-top: 1px solid $color2;
            margin-left: auto;
            margin-right: auto;
        }
    }

    .device-settings {
        & > div {
            .la {
                color: $color4 !important;
                font-size: 25px !important;
            }
        }
    }

    .video-buttons .btn.m-btn--pill {
        i {
            display: none;
        }
    }

    .call-started {
        &.call.col {
            border: 8px solid $color1;
            background: #fff !important;
        }
    }

    // .content-wrapper {
    //     height: 84% !important;
    // }

    .col {
        &.call-init,
        &.call-ended {
            padding: 1rem !important;
        }

        &.call-started,
        &.call-starting {
            padding: 1rem !important;
        }
    }
    .mf-telemed-wrapper > h1.title {
        color: $color4 !important;
        font-weight: lighter !important;
    }

    .m-portlet--primary {
        background-color: $color2 !important;
    }

    .mf-chat-client {
        background-color: $color2 !important;
    }

    @media (max-width: 1024px) {
        .m-footer {
            padding: 0rem 0;
            // height: auto;
        }
    }

    @include media-breakpoint-down(sm) {
        h1 {
            font-size: 1.5rem !important;
        }
        #agora-remote-video {
            height: 60vh;
        }

        footer img {
            margin-bottom: 10px;
        }
    }
}
